import React, { useContext, useEffect, useState } from 'react';
import Item_arrow from './menu-item-arrow';
import { AuthContext } from '../auth/components/context/auth-context';
import { Link, useHistory } from 'react-router-dom';


const MenuSidebarItem = (props: any) => {
    const authContext = useContext(AuthContext);
    const response = authContext.loginInfo.moduleRoles;

    let history = useHistory();

    const [showList, setShowList] = useState<boolean>(false);
    const [moduleRol, setModuleRol] = useState<any>([]);

    const roleValidation = () => {
        let modulos: any[] = [];
        response.map((module: any) => {
            const modulo = module.module;
            // AQUÍ DEBAJO COLOCO LOS ID DE LOS MODULOS QUE NO QUIERO MOSTRAR EN EL SIDEBAR
            modulo.idModule !== 41 && modulos.push(modulo);
            modulos.sort((a:any, b:any) => {
                return a.order - b.order;
            })
        });
        setModuleRol(modulos);
    }

    const handleMenuClick = () => {
        setShowList(!showList);
    }

    const handleHomeClick = () => {
        history.push('/home');
    }

    useEffect(() => {
        roleValidation();
    }, []);

    return (
        <div className={showList ? "is-sidebar-item active" : "is-sidebar-item"}
            style={{
                display: 'flex',
                pointerEvents: (props.collapse ? "none" : "unset"),
                justifyContent: props.title === "INICIO" ? "" : "space-between"
            }}
            onClick={props.title !== "INICIO" ? handleMenuClick : handleHomeClick}
        >
            <div className="left-icon">
                <div id="itemIcon" className="is-sidebar-icon" style={{
                    opacity: props.collapse ? "0" : "1",
                    backgroundImage: `url(${props.icon})`
                }}></div>
                <div id="itemName" className="is-sidebar-name"
                    style={{
                        opacity: (props.collapse ? "0" : "1")
                    }}
                >{props.title}</div>
            </div>
            <div className="right-icon">
                <Item_arrow id={`arrow${props.title}`} show={props.collapse ? false : props.title !== "INICIO"} transform={showList} />
            </div>
            <div className="list-menu-item">
                {
                    moduleRol && moduleRol.map((module: any, key: any) => (
                        module.menu == props.title &&
                        <li key={key} className={!props.collapse ? "list-menu-link open" : "list-menu-link close"}> <Link key={key} to={module.path}>{module.textMenu}</Link> </li>
                    ))
                }
            </div>
        </div>
    )
}

export default MenuSidebarItem;