import React, { useContext, useEffect, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import Calls from "../calls/calls.component";
import Convertions from "../convertions/convertions.component";
import Analytics from "../analytics/analytics.component";
import DataGrid, { Column } from "devextreme-react/data-grid";
import DateBox from 'devextreme-react/date-box';
import { AiOutlineShopping } from "react-icons/ai";
import { FiPercent } from "react-icons/fi";
import { BiTime } from "react-icons/bi";
import { MdEqualizer } from "react-icons/md";
import {
  fetchDataAHT, fetchDataLoginTime, fetchDataRating,
  fetchDataTotalSales, fetchDataComission,
  getSells, fetchDataTotalContact, fetchDataRecuperacion,
  getUsers, getProducts
} from './dashboard.data';
import "devextreme/data/odata/store";
import SelectBox from "devextreme-react/select-box";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import {productDataSource} from '../../shared/dataSources/data-source.data'
import "./dashboard.styles.scss";

const Dashboard = () => {

  const authContext = useContext(AuthContext);
  const userModules = authContext.loginInfo.moduleRoles;
  const currentModule = modules.dashboard.id;

  let roleType = "";
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const [totalSales, setTotalSales] = useState<number>(0);
  const [comission, setComission] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [recuperation, SetRecuperation] = useState<string>("");
  const [totalContact, setTotalContact] = useState<number>(0);
  const [rating, updateRating] = useState<any>(0);
  const [users, setUsers] = useState<any>([]);
  const [products, setProducts] = useState<any>();
  const [gridSource, setGridSource] = useState<any>();
  const [datePrincipio, setDatePrincipio] = useState<any>(new Date().setDate(new Date().getDate() - 7));
  const [dateUltimo, setDateUltimo] = useState<any>(new Date());
  const [productFilter, setProductFilter] = useState<number>(0);
  const [userFilter, setUserFilter] = useState<number>(0);


  //User from authContext
  const usuario = authContext.loginInfo;

  //User Role Validation
  const roleValidation = () => {
    const adminUser = usuario.moduleRoles.filter((res: any) => {
      return res.idModule === currentModule;
    }).map((permissionsAvailable: any) => {
      roleType = permissionsAvailable.role.roleType;
    });
    setIsAdmin(roleType === "admin" || roleType === "leader");
    setCurrentUser(usuario);
  }

  useEffect(() => {
    const response = userModules.filter((res: any) => {
      return res.idModule === currentModule;
    });
    if (response[0]) {
      setAuthorized(true);
    }
  }, []);

  useEffect(() => {
    roleValidation();
  }, [])

  //call Rating
  const callRating = async () => {
    const result = await fetchDataRating(isAdmin, currentUser);
  }

  //fetchData in real time to card info
  useEffect(() => {
    //setUsers
    getUsers(setUsers);
    //setProducts
    getProducts(setProducts);
    callRating();
    fetchDataTotalSales(new Date(datePrincipio), dateUltimo, setTotalSales, userFilter, productFilter);
    fetchDataComission(new Date(datePrincipio), dateUltimo, setComission, userFilter, productFilter);
    getSells(new Date(datePrincipio), dateUltimo, setGridSource);
    fetchDataTotalContact(new Date(datePrincipio), dateUltimo, setTotalContact, userFilter, productFilter);
    fetchDataRecuperacion(new Date(datePrincipio), dateUltimo, SetRecuperation, userFilter, productFilter);

    // fetchDataAgent();
  }, [datePrincipio, dateUltimo, userFilter, productFilter]);

  //Render image from DataSource
  const cellRenderPicture = (data: any) => {
    return <img
      src={`${data.data.Perfil}`}
      className="imgCell"
      alt={`imagePerfil${data.data.nombre}`}
      height={50}
    />
  };

  //Render Rating with cell.rowIndex
  const cellRenderRating = (cell: any) => {
    return cell.rowIndex <= 0 ? (<div className="first"> <span className="position1">{cell.rowIndex + 1}</span> </div>)
      : (<span className="position">{cell.rowIndex + 1}</span>);
  };

  const onChangeDate = (e: any) => {
    if (e.element.id === "datePrincipio") {
      setDatePrincipio(new Date(e.value));
    } else if (e.element.id === "dateUltimo") {
      setDateUltimo(new Date(e.value));
    }
  }

  const changedProduct = ({ value }: any) => {
    if (!value) {
      setProductFilter(0);
    }
    setProductFilter(value);
  }

  const changedUser = ({ value }: any) => {
    console.log(value);
    if (!value) {
      setUserFilter(0);
    }
    setUserFilter(value);
  }

  const displayName = (e: any) => {
    return e && `${e.firstName.split(' ')[0]} ${e.lastName.split(' ')[0]}`;
  }

  return (
    <>
      {
        authorized && authorized ?
            <div className="main-dashboard-container">
              <div className="form">
                <div className="dx-menu">
                  <span>Dashboard</span>
                  <span>Administration Platform</span>
                  <span>{`${usuario.firstName} ${usuario.lastName}`}</span>
                </div>
              </div>
              <div className="form">
                <div className="dx-filters">
                  {isAdmin && isAdmin ? <>
                    <span>
                      <SelectBox
                        id="productsFilter"
                        dataSource={productDataSource}
                        defaultValue={0}
                        displayExpr="name"
                        valueExpr="idProduct"
                        showClearButton={true}
                        placeholder="Selecciona un producto"
                        searchEnabled={true}
                        onValueChanged={changedProduct}
                      />
                    </span>

                    <span>
                      <SelectBox
                        id="usersFilter"
                        dataSource={users}
                        defaultValue={0}
                        displayExpr={displayName}
                        valueExpr="idUser"
                        showClearButton={true}
                        placeholder="Selecciona un usuario"
                        searchEnabled={true}
                        onValueChanged={changedUser}
                      />
                    </span> </> : <><span></span><span></span></>
                  }
                  <span>
                    <div className="dx-field dates_container">
                      <div className="dx-field-label">Fecha Inicial</div>
                      <div className="dx-field-value">
                        <DateBox defaultValue={datePrincipio} onValueChanged={onChangeDate}
                          type="date" id="datePrincipio" width="100px" />
                      </div>
                      <div className="dx-field-label">Fecha Final</div>
                      <div className="dx-field-value">
                        <DateBox defaultValue={dateUltimo} onValueChanged={onChangeDate}
                          type="date" id="dateUltimo" width="100px" />
                      </div>
                    </div>
                  </span>
                </div>
              </div>

              <div className="top-area-indicators">
                <div className="indicators">
                  <div className="item" id="item1">
                    <AiOutlineShopping />
                    <div className="description-indicator">
                      <h1>{comission}</h1>
                      <span>COMISIÓN ACUMULADA</span>
                      <h1>{totalSales}</h1>
                      <span>VENTAS TOTALES</span>
                    </div>
                  </div>
                  <div className="item" id="item2">
                    <BiTime />
                    <div className="description-indicator">
                      <h1>{currentUser ? fetchDataAHT(isAdmin, currentUser) : null}</h1>
                      <span>AHT</span>
                      <h1>{currentUser ? fetchDataLoginTime(isAdmin, currentUser) : null}</h1>
                      <span>LOGIN TIME</span>
                    </div>
                  </div>
                  <div className="item" id="item3">
                    <FiPercent />
                    <div className="description-indicator">
                      <h1>{recuperation}</h1>
                      <span>RECUPERACIÓN</span>
                    </div>
                  </div>
                  <div className="item" id="item4">
                    <MdEqualizer />
                    <div className="description-indicator">
                      <h1>{rating}</h1>
                      <span>RATING</span>
                      <h1>{totalContact}</h1>
                      <span>PERSONAS CONTACTADAS</span>
                    </div>
                  </div>
                </div>
                <div className="table">
                  <div id="grid">
                    <h3 className="tittle">Sales Leader</h3>
                    <DataGrid dataSource={gridSource} showBorders={true} id="tableGrid">
                      <Column alignment="center" caption="Rating" width="10%" fixed={true} cellRender={cellRenderRating} allowSorting={false} />
                      <Column alignment="center" caption="Picture" width="20%" fixed={true} cellRender={cellRenderPicture} allowSorting={false} />
                      <Column dataField="Nombre" caption="Nombre" alignment="center" fixed={true} allowSorting={false} />
                      <Column dataField="Ventas" caption="Ventas Realizadas" alignment="center" fixed={true} allowSorting={false} defaultSortOrder="desc" />
                    </DataGrid>
                  </div>
                </div>
              </div>

              <div className="charts">
                <div className="item">
                  <Calls currentSales={totalSales} isAdmin={isAdmin} />
                </div>
                <div className="item">
                  <Convertions
                    dateIni={new Date(datePrincipio)}
                    dateEnd={dateUltimo}
                    userFilter={userFilter}
                    productFilter={productFilter}
                  />
                </div>
              </div>
              <div className="large-item">
                <Analytics
                  isAdmin={isAdmin} usuarios={users}
                  dateIni={new Date(datePrincipio)}
                  dateEnd={dateUltimo}
                  userFilter={userFilter}
                  productFilter={productFilter}
                />
              </div>
            </div> : <></>
      }
    </>
  );
};

export default Dashboard;
