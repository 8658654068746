import AxiosConfig from "../../axios/axiosConfig";
import CustomStore from "devextreme/data/custom_store";

export const statesSource = {
    store: new CustomStore({
		key: "idStatusAction", 
		loadMode: "raw",
		cacheRawData: true,
		load: () => {
			return AxiosConfig.get("/Values/GetStatusAction/Refund")
				.then((response) => {
					return response.data;
				})
				.catch((e) => {
					console.error(e);
				});
		},
	}),
	sort: "name",
}

export const refundStates = {
	store: new CustomStore({
		key: "idStatusAction", 
		loadMode: "raw",
		cacheRawData: true,
		load: () => {
			return AxiosConfig.get("/Values/GetStatusAction/Refund")
				.then((response) => {
					return response.data;
				})
				.catch((e) => {
					console.error(e);
				});
		},
	}),
	sort: "name",
}