import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import React, { useContext, useEffect, useState } from 'react';
import { Config } from '../../Config';
import { teamsDataSource } from '../../shared/dataSources/data-source.data';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import MasterDetailRol from './master-detail-rol.component';
import './rol-management.styles.scss';

const gridDataSource = {
    store: createStore({
        key: "idRole",
        insertUrl: `${Config.url.api}/rol/createRole`,
        updateUrl: `${Config.url.api}/rol/updateRole`,
        loadUrl: `${Config.url.api}/rol/GetRoles`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
}

const Rol_Management = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.classManagement_bm.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    return (
        <>
            {
                authorized && authorized ?
                    <div className="rol-management-main-container">
                        <h1 className="is-size-5 m-5 center">Administración de roles</h1>

                        <DataGrid
                            dataSource={gridDataSource}
                            showBorders={true}
                            keyExpr="idRole"
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            focusedRowEnabled={true}
                            showRowLines={true}
                            height="88vh"
                            width="95vw"
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize="20" />
                            <Pager showInfo={true} />

                            <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                                <Texts saveRowChanges="Guardar" />
                                <Popup
                                    title="Rol Info"
                                    showTitle="true"
                                    width={"70%"}
                                    height={"auto"}
                                    maxHeight={"95vh"}
                                />
                                <Form>
                                    <Item itemType="group" colCount={2} colSpan={2} >
                                        <Item dataField="role1" />
                                        <Item dataField="idTeam" />
                                        <Item dataField="roleType" />
                                        <Item dataField="description" />
                                        <Item dataField="level" />
                                    </Item>
                                </Form>

                            </Editing>

                            <Column dataField="idRole" caption="idCareer" dataType="number" visible={false} />
                            <Column dataField="role1" caption="Nombre del rol" dataType="string" />
                            <Column dataField="roleType" caption="Tipo de Rol" dataType="string">
                                <Lookup
                                    dataSource={['admin', 'leader', 'user']}
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="description" caption="Descripción" dataType="string" />
                            <Column dataField="level" caption="Nivel en escalamiento" dataType="number">
                                <Lookup
                                    dataSource={['1', '2', '3']}
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="idTeam" caption="Equipo al que pertenece" dataType="number">
                                <Lookup
                                    dataSource={teamsDataSource}
                                    valueExpr='idTeam'
                                    displayExpr='name'
                                    allowClearing={true}
                                />
                            </Column>

                            <MasterDetail
                                enabled={true}
                                component={MasterDetailRol}
                            />
                        </DataGrid>
                    </div> : <></>
            }
        </>
    )
}

export default Rol_Management;