import { createStore } from "devextreme-aspnet-data-nojquery";
import Scrollbar from 'react-scrollbars-custom';
import React, { useContext, useEffect, useState } from 'react';
import './certification-request.styles.scss';
import "devextreme/data/odata/store";
import { Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup, Paging } from "devextreme-react/data-grid";
import { AuthContext } from "../auth/components/context/auth-context";
import { Config } from "../../Config";

const scrollHeight = (window.innerHeight - 100);
const scrollWidth = (window.innerWidth - 65);

let states = [
    {
        'Id': 'pendiente',
        'Name': 'Pendiente'
    },
    {
        'Id': 'aprobada',
        'Name': 'Aprobada'
    },
    {
        'Id': 'rechazada',
        'Name': 'Rechazada'
    },
];

const gridDataSource = {
    store: createStore({
        key: "idCertRequest",
        loadUrl: `${Config.url.api}/Certification/GetCertifications`,
        updateUrl: `${Config.url.api}/Certification/UpdateCertification`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    })
}

const CertificationRequest = () => {

    const authContext = useContext(AuthContext);
    const response = authContext.loginInfo.moduleRoles;

    //Current Module
    let currentModule = 3;

    //Cell Template
    const Link = (data: any) => {
        return <a href={data.value} target='_blank'> Ver evidencia </a>
    }

    return (
        <div className="certification-manager-main-container">
            <Scrollbar style={{ height: scrollHeight, width: scrollWidth }}>
                <h1 className="is-size-5 mb-2">Solicitudes de Certificados</h1>

                <DataGrid
                    dataSource={gridDataSource}
                    showBorders={true}
                    keyExpr="idCertRequest"
                    allowColumnReordering={true}
                    columnAutoWidth={true}
                >
                    {/* <MasterDetail enabled={true} component={MasterDetailGrid} /> */}
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Paging enabled={true} />

                    <Editing
                        mode="cell"
                        allowUpdating={true}
                    />

                    <Column alignment="center" dataField="dateRequest" caption="Fecha" dataType="datetime" allowEditing={false} width="200" />
                    <Column dataField="careerName" caption="Carrera" dataType="string" allowEditing={false} />
                    <Column dataField="currentLevel" caption="Nivel Actual" dataType="string" allowEditing={false} visible={false} />
                    <Column dataField="firstName" caption="Nombre" dataType="string" allowEditing={false} width="200" />
                    <Column dataField="lastName" caption="Apellido" dataType="string" allowEditing={false} width="200" />
                    <Column dataField="email" caption="Email" dataType="string" allowEditing={false} />
                    <Column dataField="phone" caption="Teléfono" dataType="string" allowEditing={false} />
                    <Column alignment="center" dataField="statusRequest" caption="Estado" dataType="string" filterValue={'pendiente'}>
                        <Lookup dataSource={states} valueExpr="Id" displayExpr="Name" />
                    </Column>
                    <Column dataField="urlEvidence" cellRender={Link} caption="Evidencia" dataType="hyperlink" />
                    <Column alignment="center" dataField="dateUpdate" caption="Última actualización" dataType="date" allowEditing={false} />
                    <Column dataField="startDate" caption="Fecha inicio" dataType="date" width={150} />
                </DataGrid>

                <div className="netflix-logo">
                    <span className="internal-bar"></span>
                </div>

            </Scrollbar>
        </div>
    );
};

export default CertificationRequest;