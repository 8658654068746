import React from 'react'
import './funnelsComponent.styles.scss'

const EmailComponent = ({ account, password, userName }: any) =>{
    return(
        <div className='funnels_body'>
            <p className="funnels_title">EMAILTOOL</p>
            <p>Account: {account}</p>
            <p>UserName: {userName}</p>
            <p>Password: {password}</p>
         </div>
        
    )
}

export default EmailComponent