import React, { useContext, useEffect, useState } from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, PatternRule, Popup, RequiredRule, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Config } from '../../Config';
import notify from 'devextreme/ui/notify';
import { AuthContext } from '../auth/components/context/auth-context';
import { modules } from '../../shared/modules';
import MasterDetailUser_comizzion from './master-detail-user.component';

const gridDataSource = {
    store: createStore({
        key: "idUser",
        loadUrl: `${Config.url.api}/comizzion/GetUsers/all`,
        updateUrl: `${Config.url.api}/comizzion/editUser`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
};

const UserManagement_Comizzion = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.userManagement_comizzion.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }if (response[0].role.roleType === "admin") {
            setIsAdmin(true);
        }
    }, []);

    const onRowUpdated = (e: any) => {
        notify(e.data.message);
    }

    return (
        <>
            {
                authorized && authorized ?

                    <div className="user-management-comizzion-main-container">
                        <h1 className="is-size-5 m-5 center">Gestión de usuarios Comizzion</h1>
                        <DataGrid
                            dataSource={gridDataSource}
                            showBorders={true}
                            keyExpr="idUser"
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            focusedRowEnabled={true}
                            onRowUpdated={onRowUpdated}
                            showRowLines={true}
                            height="88vh"
                            width="95vw"
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize="20" />
                            <Pager showInfo={true} />

                            <Editing mode="popup" allowUpdating={true} >
                                <Texts saveRowChanges="Guardar" />
                                <Popup
                                    title="User info"
                                    showTitle="true"
                                    width={"70%"}
                                    height={"auto"}
                                    maxHeight={"95vh"}
                                />
                                <Form>
                                    <Item itemType="group" colCount={2} colSpan={2} >
                                        <Item dataField="firstName" />
                                        <Item dataField="lastName" />
                                        <Item dataField="email" />
                                        <Item dataField="password" >
                                            <PatternRule
                                                message="El Password debe tener una letra mayúscula, 1 número y 8 carácteres mínimo"
                                                pattern={/(?=(?:.*[A-Z]){1})(?=(?:.*\d){1})\S{8,}/}
                                            />
                                        </Item>
                                        <Item dataField="status" />
                                    </Item>
                                </Form>

                            </Editing>

                            <Column type="buttons" caption="Acciones" width={80}>
                                <Button name="edit" text="Editar" />
                            </Column>
                            <Column dataField="idUser" caption="idUser" dataType="number" visible={false} />
                            <Column dataField="firstName" caption="Nombres" dataType="string" allowEditing={isAdmin} />
                            <Column dataField="lastName" caption="Apellidos" dataType="string" allowEditing={isAdmin} />
                            <Column dataField="email" caption="Correo electrónico" dataType="string" allowEditing={isAdmin} />
                            <Column dataField="password" caption="contraseña" dataType="password" width="500" />
                            <Column dataField="status" caption="Estado en plataforma" dataType="number" >
                                <Lookup
                                    dataSource={[{ "idStatus": 2, "estado": "Inactivo" }, { "idStatus": 1, "estado": "Activo" }]}
                                    valueExpr="idStatus"
                                    displayExpr={"estado"}
                                    allowClearing={true}
                                />
                            </Column>

                            <MasterDetail
                                enabled={true}
                                component={MasterDetailUser_comizzion}
                            />
                        </DataGrid>
                    </div> : <></>
            }
        </>
    )
}


export default UserManagement_Comizzion;