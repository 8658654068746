import React, { useContext, useEffect, useState, useRef } from "react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import Scrollbar from "react-scrollbars-custom";
import AxiosConfig from "../../axios/axiosConfig";
import "devextreme/data/odata/store";
import {
	Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup, RequiredRule,
	Paging, Pager, Button, Popup, Form, Texts, Export
} from "devextreme-react/data-grid";
import { Item, Tab } from "devextreme-react/form";
import "devextreme-react/text-area";
import { AuthContext } from "../auth/components/context/auth-context";
import Axios from "axios";
import { states } from "./leads-data";
import { Config } from "../../Config";
import notify from "devextreme/ui/notify";
import { productDataSource, statusDataSource } from "../../shared/dataSources/data-source.data";
import "./leads-to-call.styles.scss";


const scrollHeight = window.innerHeight - 100;
const scrollWidth = window.innerWidth - 65;

const gridDataSource = {
	store: createStore({
		key: "idLeadAction",
		insertUrl: `${Config.url.api}/CallCenter/InsertLeadsToCall`,
		updateUrl: `${Config.url.api}/CallCenter/UpdateLeadsToCall`,
		loadUrl: `${Config.url.api}/CallCenter/GetLeadsToCall/pending`,
		onBeforeSend: (method, ajaxOptions) => {
			ajaxOptions.xhrFields = { withCredentials: false };
			ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
		},
	}),
};

const LeadsToCall = () => {
	const authContext = useContext(AuthContext);
	const response = authContext.loginInfo.moduleRoles;
	let roleType = "";
	const [onCall, setOnCall] = useState<boolean>(false);
	const [leadOnCall, setLeadOnCall] = useState<number>(0);
	const [leadEditing, setLeadEditing] = useState<number>(0);
	let newDate = new Date();
	let phoneWp = "";
	let msgWp = `¡Ey! Nos alegra tenerte por aquí, esperamos que estés pasando un excelente día te saludamos del equipo de soporte de BeMaster. 

	Queremos que sepas qué nos puedes escribir en el momento que lo desees.
	
	¡Estamos aquí!`;

	const gridRef = useRef<any>(null);
	const formRef = useRef<any>(null);

	//Current Module
	let currentModule = 4;

	// getting permissions for current module
	response
		.filter((res: any) => {
			return res.idModule === currentModule;
		})
		.map((permissionsAvailable: any) => {
			roleType = permissionsAvailable.role.roleType;
		});

	//Call action
	const call = (e: any) => {
		const item = { ...e.row.data };
		if (!onCall) {
			setOnCall(true);
			setLeadOnCall(item.idLeadAction);

			Axios.get(
				`http://localhost:80/apiagentbox?action=dial&phone=9005${item.phone}&id_customer=${item.idLead}`
			)
				.then((response) => {
				})
				.catch((e) => {
					console.error(e);
				});

			gridRef.current?.instance.editRow(e.row.rowIndex);
		} else {
			endCall(item.idLeadAction, item.idStatus, item.idProduct, item.comments);
		}
		e.event.preventDefault();
	};

	//End Call
	const endCall = (
		idLeadAction: number,
		idStatus: number,
		idProduct: number,
		comments: string
	) => {
		if (onCall && idLeadAction === leadOnCall) {
			setOnCall(false);
			setLeadOnCall(0);
			Axios.get(
				`http://localhost:80/apiagentbox?action=chur&cod=${idStatus}&cod2=${idProduct}&comm=${comments}`
			)
				.then((response) => {
				})
				.catch((e) => {
					console.error(e);
				});
		}
	};

	//Change email when not admin
	const calculateCellValue = (e: any) => {
		if (roleType === "admin" || roleType === "leader") {
			return e.email;
		} else {
			const nuevoValor = newEmail(e.email);
			return nuevoValor;
		}
	};

	//Email Mask
	const newEmail = (initial: string) => {
		if (initial) {
			const posDominio = initial.indexOf("@");
			let retorno = "";
			for (let i = 0; i < initial.length; i++) {
				if (i > posDominio) {
					retorno = retorno + "X";
					continue;
				}
				retorno = retorno + initial[i];
			}
			return retorno;
		}
	};

	//Send WhatsApp Message
	const sendMessage = async (e: any) => {
		if (msgWp && phoneWp) {
			const result = await AxiosConfig.post('CallCenter/SendMessage', {
				mensaje: msgWp,
				to: phoneWp
			});

			if (result) {
				notify('Se ha enviado el mensaje correctamente');
			}
		}
	};

	//Editor preparing
	const onEditingStart = (e: any) => {
		setLeadEditing(e.key);
	};

	//Editor preparing
	const onEditorPreparing = (e: any) => {
		if (
			e.parentType === "dataRow" &&
			!e.value &&
			(e.dataField === "firstName" ||
				e.dataField === "lastName" ||
				e.dataField === "idProduct")
		) {
			e.editorOptions.readOnly = false;
		} else if (
			e.parentType === "dataRow" &&
			e.value &&
			(e.dataField === "firstName" ||
				e.dataField === "lastName" ||
				e.dataField === "idProduct")
		) {
			e.editorOptions.readOnly = true;
		}

		if (
			e.parentType === "dataRow" &&
			e.dataField === "phone" &&
			roleType !== "admin" &&
			roleType !== "leader" &&
			e.value
		) {
			const res = e.value.replace(/\d{4}$/, "XXXX");
			e.editorOptions.value = res;
		}
	};

	//Row updated
	const onRowUpdating = (e: any) => {
		let { idStatus, idProduct, comments } = e.oldData;
		idStatus = e.newData.idStatus ? e.newData.idStatus : e.oldData.idStatus;
		comments = e.newData.comments ? e.newData.comments : e.oldData.comments;
		endCall(e.key, idStatus, idProduct, comments);
	};

	//Form customize item
	const customizeItem = (item: any) => {
		if (
			item &&
			item.itemType === "simple" &&
			item.caption === "WhatsApp Phone"
		) {
			// Get the index of the row being edited; 0 if it is a new row
			let index = gridRef.current?.instance.getRowIndexByKey(leadEditing) || 0;
			phoneWp = gridRef.current?.instance.cellValue(index, "phone");
		}
	};

	//Change Date
	const changeDate = (e: any) => {
		newDate = e.value;
	};

	return (
		<div className="leads-to-call-main-container">
			<Scrollbar style={{ height: scrollHeight, width: scrollWidth }}>
				<h1 className="is-size-5 mb-2">Leads To Call</h1>

				<DataGrid
					ref={gridRef}
					dataSource={gridDataSource}
					showBorders={true}
					keyExpr="idLeadAction"
					allowColumnReordering={true}
					columnAutoWidth={true}
					focusedRowEnabled={true}
					onEditorPreparing={onEditorPreparing}
					showRowLines={true}
					onRowUpdating={onRowUpdating}
					onEditingStart={onEditingStart}
				>
					<Export enabled={true} />
					<FilterRow visible={true} />
					<HeaderFilter visible={true} />
					<Paging enabled={true} />
					<Pager showInfo={true} />

					<Editing mode="popup" allowUpdating={true} allowAdding={true}>
						<Texts saveRowChanges={onCall ? "Guardar Y Colgar" : "Guardar"} />
						<Popup
							title="Call Info"
							showTitle={false}
							width={700}
							height={525}
						/>
						<Form ref={formRef} customizeItem={customizeItem}>
							<Item itemType="tabbed" colSpan={2} colCount={2}>
								<Tab title="Call Info" icon="user">
									<Item itemType="group" colCount={2} colSpan={2}>
										<Item dataField="firstName" isRequired={true}>
											<RequiredRule message="El nombre es necesario" />
										</Item>
										<Item dataField="lastName" />
										<Item dataField="idProduct" isRequired={true}>
											<RequiredRule message="El producto es necesario" />
										</Item>
										<Item dataField="type" />
										<Item
											dataField="email"
											isRequired={true}
										>
											<RequiredRule message="El email es necesario" />
										</Item>
										<Item dataField="phone" isRequired={true}>
											<RequiredRule message="El teléfono es necesario" />
										</Item>
									</Item>

									<Item
										itemType="group"
										caption="Información Adicional"
										colCount={2}
										colSpan={2}
									>
										<Item dataField="idStatus" isRequired={true}>
											<RequiredRule message="La tipificación es necesaria" />
										</Item>
										<Item
											dataField="nextCall"
											editorOptions={{ onValueChanged: changeDate }}
										/>
										<Item
											dataField="comments"
											editorType="dxTextArea"
											colSpan={2}
											editorOptions={{ height: 100 }}
										/>
									</Item>
								</Tab>
								<Tab title="WhatsApp" icon="fab fa-whatsapp" colCount={2}>
									<Item
										dataField="phone"
										caption="WhatsApp Phone"
										editorOptions={{
											onChange: (e: any) => {
												phoneWp = e.event.target.value;
											},
										}}
									/>
									<Item itemType="hidden"></Item>
									<Item
										itemType="button"
										horizontalAlignment="left"
										buttonOptions={{
											useSubmitBehavior: false,
											text: "Enviar Mensaje",
											onClick: sendMessage,
										}}
									/>
								</Tab>
							</Item>
						</Form>
					</Editing>

					<Column type="buttons" width={110}>
						<Button name="edit" text="Editar" />
						<Button icon="tel" text="Llamar" onClick={call} />
					</Column>
					<Column
						dataField="idLead"
						caption="idLead"
						dataType="int"
						visible={false}
					/>
					<Column
						alignment="center"
						dataField="date"
						caption="Fecha"
						dataType="datetime"
						allowEditing={false}
						format="yyyy-MM-dd"
						sortIndex={0}
						sortOrder={"desc"}
					/>
					<Column
						dataField="type"
						caption="Tipo"
						dataType="string"
						allowEditing={false}
					/>
					<Column
						alignment="center"
						dataField="idProduct"
						caption="Producto"
						allowEditing={true}
					>
						<Lookup
							dataSource={productDataSource}
							valueExpr="idProduct"
							displayExpr="name"
							allowClearing={true}
						/>
					</Column>
					<Column
						dataField="firstName"
						caption="Nombre"
						dataType="string"
						allowEditing={true}
						width="200"
					/>
					<Column
						dataField="lastName"
						caption="Apellidos"
						dataType="string"
						allowEditing={true}
					/>
					<Column
						dataField="email"
						caption="Email"
						dataType="string"
						allowEditing={true}
						calculateCellValue={calculateCellValue}
					/>
					<Column
						dataField="country"
						caption="País"
						dataType="string"
						allowEditing={false}
					/>
					<Column
						dataField="phone"
						caption="Phone"
						dataType="string"
						allowEditing={true}
						visible={roleType !== "user" ? true : false}
					/>
					<Column
						dataField="transaction"
						caption="Transacción"
						dataType="string"
						allowEditing={false}
						visible={false}
					/>
					<Column
						alignment="center"
						dataField="idStatus"
						caption="Tipificación"
					>
						<Lookup
							dataSource={statusDataSource('Calls')}
							valueExpr="idStatusAction"
							displayExpr="name"
							allowClearing={true}
						/>
					</Column>
					<Column
						dataField="comments"
						caption="Comentarios"
						dataType="string"
						allowEditing={true}
						width="500"
					/>
					<Column
						alignment="center"
						dataField="nextCall"
						caption="Próxima Llamada"
						dataType="datetime"
						allowEditing={true}
					/>
					<Column
						alignment="center"
						dataField="lastEdit"
						caption="Última Llamada"
						dataType="datetime"
						allowEditing={false}
					/>
				</DataGrid>
			</Scrollbar>
		</div>
	);
};

export default LeadsToCall;
