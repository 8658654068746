import React, { useEffect } from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup } from 'devextreme-react/data-grid';
import AxiosConfig from '../../axios/axiosConfig';
import { classMissionDataSource } from './user-management-bm.data';
import { Item } from 'devextreme-react/form';
import { Config } from '../../Config';

const UserCareerProgress = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idUserCareer",
            loadUrl: `${Config.url.api}/userBm/GetUserCareerProgressBm/${props.data.data.idUserCareer}`,
            insertUrl: `${Config.url.api}/userBm/InsertUserCareerProgressBm`,
            updateUrl: `${Config.url.api}/userBm/UpdateUserCareerProgressBm`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
            },
        }),
    };

    const urlRender = (data: any) => {
        if (data.value) {
            return <a href={`${data.value}${data.data.idUserCareerProgress}`} target="_blank" rel="noopener noreferrer" > link Workbook </a>;
        } else {
            return <>sin Workbook</>
        }
    }

    return (
        <>
            <DataGrid
                id="dataGrid-user-career-tab"
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idUserCareer"
                allowColumnReordering={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                focusedRowEnabled={true}
                showRowLines={true}
                width="100%"
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize={10} />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={true} allowAdding={true}>
                    <Popup title="userCareerProgress Info" showTitle={true} width="60%" />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="idClassMission" />
                            <Item dataField="completed" />
                            <Item dataField="reviewClass" />
                            <Item dataField="score" />
                            <Item dataField="attemps" />
                            <Item dataField="urlWorkBookCompleted" />
                            <Item dataField="dateCompleted" />
                        </Item>
                    </Form>
                </Editing>

                <Column type="buttons" width={"auto"}>
                    <Button name="edit" text="Editar" visible={true} />
                </Column>
                <Column dataField="idUserCareerProgress" caption="idUserCareerProgress" dataType="int" visible={false} />
                <Column dataField="idUserCareer" caption="idUserCareer" dataType="int" visible={false} />
                <Column dataField="idClassMission" caption="Misión" dataType="int" >
                    <Lookup
                        dataSource={classMissionDataSource}
                        valueExpr="idClassMission"
                        displayExpr="title"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="completed" caption="Estado" dataType="int" >
                    <Lookup
                        dataSource={[{ "id": 0, "result": "Sin Completar" }, { "id": 1, "result": "Completada" }]}
                        valueExpr="id"
                        displayExpr="result"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="reviewClass" caption="Vistas de la clase" width={150} dataType="int" />
                <Column dataField="score" caption="Puntaje" width={100} dataType="int" />
                <Column dataField="attemps" caption="Intentos" width={100} dataType="int" />
                <Column dataField="urlWorkBookCompleted" caption="WorkBook" dataType="string" cellRender={urlRender} />
                <Column dataField="dateCompleted" caption="Fecha de finalización" dataType="datetime" format="yyyy-MM-dd" />
            </DataGrid>
        </>
    )
}

export default UserCareerProgress;