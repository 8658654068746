import React, { useContext, useEffect, useState, useRef } from "react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import "devextreme/data/odata/store";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup,
    Paging, Pager, Button, Popup
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import { confirm } from 'devextreme/ui/dialog';
import notify from "devextreme/ui/notify";
import { AuthContext } from '../auth/components/context/auth-context';
import { productDataSource } from "../../shared/dataSources/data-source.data";
import { statesSource } from "../refunds-to-call/leads-refunds.data";
import AxiosConfig from "../../axios/axiosConfig";
import DataSource from "devextreme/data/data_source";

const gridDataSource = new DataSource({
    store: createStore({
        key: "idLeadAction",
        updateUrl: `${Config.url.api}/CallCenter/UpdateComunityManagement`,
        loadUrl: `${Config.url.api}/CallCenter/GetLeadsToCall/refundsConfirmed`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
});

const ConfirmedRefund = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.confirmedRefund.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);


    const gridRef = useRef<any>(null);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
        if (response[0].role.roleType === "admin") {
            setIsAdmin(true);
        }
    }, []);

    //Email Mask
    const newEmail = (initial: string) => {
        if (initial) {
            const posDominio = initial.indexOf("@");
            let retorno = "";
            for (let i = 0; i < initial.length; i++) {
                if (i > posDominio) {
                    retorno = retorno + "X";
                    continue;
                }
                retorno = retorno + initial[i];
            }
            return retorno;
        }
    };

    //Change email when not admin
    const calculateCellValue = (e: any) => {
        if (isAdmin) {
            return e.email;
        } else {
            const nuevoValor = newEmail(e.email);
            return nuevoValor;
        }
    };

    //Editor preparing
    const onEditorPreparing = (e: any) => {
        if (
            e.parentType === "dataRow" &&
            !e.value &&
            (e.dataField === "firstName" ||
                e.dataField === "lastName" ||
                e.dataField === "idProduct")
        ) {
            e.editorOptions.readOnly = false;
        } else if (
            e.parentType === "dataRow" &&
            e.value &&
            (e.dataField === "firstName" ||
                e.dataField === "lastName" ||
                e.dataField === "idProduct")
        ) {
            e.editorOptions.readOnly = true;
        }
        if (
            e.parentType === "dataRow" &&
            e.dataField === "phone" &&
            !isAdmin &&
            e.value
        ) {
            const res = e.value.replace(/\d{4}$/, "XXXX");
            e.editorOptions.value = res;
        }
    };

    //Row updated
    const onRowUpdating = async (e: any) => {
        const data = e.row.data;
        const res = await confirm(`¿Ya gestionaste los accesos de ${data.firstName}?`, "Cuidado");

        if (res) {
            const result = await AxiosConfig.put(`${Config.url.api}/CallCenter/UpdateComunityManagement`, data);

            if (result) {
                notify(result.data.message, result.data.result);
                gridDataSource.reload();
            }
        }
    };


    return (
        <>
            {/* {
                authorized && authorized ? */}
            <div className="leads-refund-main-container">
                <h1 className="is-size-5 m-5 center">Confirmed Refunds</h1>
                <DataGrid
                    ref={gridRef}
                    dataSource={gridDataSource}
                    showBorders={true}
                    keyExpr="idLeadAction"
                    allowColumnReordering={true}
                    columnAutoWidth={true}
                    focusedRowEnabled={true}
                    onEditorPreparing={onEditorPreparing}
                    showRowLines={true}
                    height="88vh"
                    width="95vw"
                >
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Paging enabled={true} pageSize="20" />
                    <Pager showInfo={true} />

                    <Editing mode="popup" allowUpdating={true} >
                        <Popup
                            title="Refund Info"
                            showTitle="true"
                            width={700}
                            height={525}
                        />
                    </Editing>

                    <Column type="buttons" width={110}>
                        <Button icon="check" text="Editar" onClick={onRowUpdating} />
                    </Column>
                    <Column dataField="idLead" caption="idLead" dataType="int" visible={false} />
                    <Column alignment="center" dataField="date" caption="Fecha" dataType="datetime"
                        allowEditing={false} format="yyyy-MM-dd" sortIndex={0} sortOrder={"desc"} />
                    <Column alignment="center" dataField="idStatus" caption="Tipificación" >
                        <Lookup
                            dataSource={statesSource}
                            valueExpr="idStatusAction"
                            displayExpr="name"
                            allowClearing={true}
                        />
                    </Column>
                    {/* <Column dataField="type" caption="Tipo" dataType="string" allowEditing={false} /> */}
                    <Column alignment="center" dataField="idProduct" caption="Producto" allowEditing={false} >
                        <Lookup
                            dataSource={productDataSource}
                            valueExpr="idProduct"
                            displayExpr="name"
                            allowClearing={true}
                        />
                    </Column>
                    <Column dataField="firstName" caption="Nombre" dataType="string" allowEditing={true} width="200" />
                    <Column dataField="lastName" caption="Apellidos" dataType="string" allowEditing={true} width="200" />
                    <Column dataField="email" caption="Email" dataType="string" allowEditing={true} calculateCellValue={calculateCellValue} />
                    {/* <Column dataField="country" caption="País" dataType="string" allowEditing={false} />
                    <Column dataField="phone" visible={isAdmin} caption="Phone" dataType="string" allowEditing={true} /> */}
                    <Column dataField="affiliateCode" caption="Código de afiliado" allowEditing={false} />
                    <Column dataField="transaction" caption="Transacción" dataType="string" allowEditing={false} visible={false} />
                    <Column alignment="center" dataField="lastEdit" caption="Última Llamada" dataType="datetime" allowEditing={false} />
                    <Column dataField="nameCommunity" caption="Nombre en la comunidad" allowEditing={false} />
                    <Column dataField="linkCommunity" caption="Perfil de la comunidad" allowEditing={false} />
                    <Column dataField="idStatusComunity" caption="estado de la comunidad" allowEditing={false} />
                    <Column dataField="comments" caption="Comentarios" dataType="string" allowEditing={true} width="500" />
                </DataGrid>
            </div>
            {/* : <></>
            } */}
        </>
    )
}

export default ConfirmedRefund;