import React, { useContext, useEffect, useRef, useState } from 'react'
import {
	Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup, RequiredRule,
	Paging, Pager, Button, Popup, Form, Texts, Export} from "devextreme-react/data-grid"
import { Item, Tab } from "devextreme-react/form";
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Config } from '../../Config';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import { BiSearchAlt2 } from 'react-icons/bi';
import { instanceNode } from '../../axios/axiosConfig';
import './AccountsEmailTool.styles.scss'
import { Button as Buton} from 'react-bootstrap';
import './AccountsEmailTool.modules.css';
import notify from 'devextreme/ui/notify';

const AccountsEmailTool = () => {
    
    const searchRef = useRef<HTMLInputElement>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.accountsEmailTool.id;
    

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState("")


    const gridDataSource = {
        store: createStore({
            key: "account",
            insertUrl: `${Config.url.apiNode}EmailTool`,
            updateUrl:`${Config.url.apiNode}EmailTool`,
            loadUrl: `${Config.url.apiNode}EmailTool?search=${searchValue}&page=${currentPage}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
                if (ajaxOptions.method === "PUT") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };   
                if (ajaxOptions.method === "POST") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };   
            }
        })
    }


    const fetchPlans = async () => {
        const respuesta = await instanceNode.get('EmailTool?search=' + searchValue + '&page=' + currentPage) 
        return respuesta;
    }

    const changeSearch = (e: any) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            setSearchValue("");
        }
    }

    const back = (e: any) => {
        e.preventDefault()
        setCurrentPage(prev=> prev - 1)
    }

    const next = (e: any) => {
        e.preventDefault()
        setCurrentPage(prev=> prev +1)
    }

    const searchClick = () => {
        const valor = searchRef.current?.value;
        if (valor === "" || valor === undefined) {
            setSearchValue("");
            setCurrentPage(1)
        } else {
            setSearchValue(valor);
            setCurrentPage(1)
        }
        console.log(searchRef.current?.value);
    }

    useEffect(() => {
        fetchPlans();
    }, []);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    const crearCuenta = {addRow: "Crear Cuenta"}

    const onRowUpdated = (e: any) => {
        if (e.data.result) {
            notify("Actualizado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }

    const onRowInserted = (e: any) => {
        if (e.data.result) {
            notify("Actualizado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }
  
    return (
        <>
            {
                authorized && authorized ?
                    <div className="main-Affiliates-management-container">
                        <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Listado de cuentas EmailTool</h1>
                                </div>
                            </div>
                        </div>

                        <div className="Search">
                            <div className='searchForm'>
                                <label className="searchLabel">Búsqueda:</label>
                                <input type="text" name="search" id="search" placeholder="Nombre de usuario" autoComplete="off" ref={searchRef} onChange={changeSearch} />
                                <div className="searchButton" onClick={searchClick} > <BiSearchAlt2 className="search-icon" /> </div>
                            </div>
                            <div className="paginado">
                                <span>Página N°</span>
                                <Buton disabled= { currentPage<=1 ? true : false } variant="outline-warning" size="sm" onClick={back}>Atrás</Buton>
                                <span>{currentPage}</span>
                                <Buton variant="outline-warning" size="sm" onClick={next}>Siguiente</Buton>
                            </div>
                        </div>
                
                        <DataGrid
                            id="grid-container-affiliations"
                            dataSource={gridDataSource}
                            keyExpr="account"
                            showBorders={true}
                            showRowLines={true}
                            focusedRowEnabled={true}
                            columnAutoWidth={true}
                            height="78vh"
                            width={"90vw"}
                            onRowInserted={onRowInserted}
                            onRowUpdated={onRowUpdated}
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize={20} />
                            <Pager showInfo={true} />

                            <Editing mode="popup" allowUpdating={true} allowAdding={true} texts={crearCuenta} >
                            <Texts saveRowChanges="Crear cuenta" />
                            <Popup
                                title="Cuenta EmailTool"
                                showTitle="true"
                                width={"30%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                                
                            />
                            <Form colCount={1}>
                                <Item dataField="email" isRequired={true} >
                                    <RequiredRule message="El email es necesario" />
                                </Item>      
                                <Item dataField="plans" isRequired={true} >
                                    <RequiredRule message="El plan es necesario" />
                                </Item>
                                <Item dataField="expire" isRequired={true} >
                                    <RequiredRule message="La fecha de expiración es necesaria" />
                                </Item>      
                            </Form>
                        </Editing>

                            <Column type="buttons" width={100} caption="Editar">
						        <Button name="edit" text="Editar Cuenta" />
					        </Column>
                            <Column dataField="client_name" caption="Nombre de usuario" width={200}  alignment="center"/>
                            <Column dataField="account" caption="Cuenta" width={300} allowSearch={true} visible={true} alignment="center"/>
                            <Column dataField="planid" caption="Código del plan" width={100} alignment="center" visible={false}/>
                            <Column dataField="plan_name" caption="Plan Activo" width={200} alignment="center"/>
                            <Column dataField="subscribers" caption="Suscriptores" width={130} alignment="center"/>
                            <Column dataField="emails_sent" caption="Emails Enviados" width={130} alignment="center"/>
                            <Column dataField="expire" caption="Fecha de expiración" width={180} dataType="datetime" format="dd / MM / yy" alignment="center"/>
                            <Column dataField="newExpire" caption="Repetir Fecha de expiración" width={180} dataType="text" format="dd / MM / yy" alignment="center" visible={false}/>
                            <Column dataField="reseller_status" caption="Estado de Suscripción" width={150} alignment="center"/>
                            <Column dataField="plans" caption="Seleccionar nuevo Plan" width={150} alignment="center" dataType="string" visible={false}>
                                <Lookup
                                dataSource={["500 Contactos", "1.000 Contactos", "2.500 Contactos", "5.000 Contactos", "10.000 Contactos", "25.000 Contactos", "50.000 Contactos", "75.000 Contactos", "100.000 Contactos"]}
                                allowClearing={true}
                                />
                            </Column>
                            <Column dataField="email" allowEditing={true} caption="Email del Usuario" width={150} alignment="center" visible={false}/>
                        </DataGrid>
                    </div> : <></>
            }
        </>
    );
}

export default AccountsEmailTool