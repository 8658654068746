import AxiosConfig from "../../axios/axiosConfig";

//formatDate for UX
export const formatDate = (fecha) => {

	const año = fecha.getFullYear();
	const mes = fecha.getMonth() + 1;
	const dia = fecha.getDate();

	const res = `${año}/${mes}/${dia}`;

	return res;
}

//fetchVentas and format the response
export const fetchVentas = async (dateIni, dateEnd, userFilter, productFilter) => {

	//call api response Sells
	const responseSells = await AxiosConfig.post("/Dashboard/GetSellsByUser", {
		dateIni: dateIni,
		dateEnd: dateEnd,
		user: userFilter,
		product: productFilter
	});

	//call api respose Calls
	const responseCalls = await AxiosConfig.post("/Dashboard/GetStatusAction", {
		dateIni: dateIni,
		dateEnd: dateEnd,
		user: userFilter,
		product: productFilter
	});


	//difference of dates / milliseconds -> minutes -> hours -> days
	let dif = (new Date(dateEnd) - new Date(dateIni)) / (1000 * 60 * 60 * 24);

	//initial vars for format Sells
	let fecha = "";
	let date = "";
	let objeto = [];

	if (responseSells.data.result === "success") {

		for (let i = 0; i < dif + 1; i++) {
			let fechaIni = new Date(dateEnd);
			date = formatDate(new Date(fechaIni.setDate(fechaIni.getDate() - i)));
			objeto.push({
				Date: date,
				sells: 0,
				calls: 0
			})
		}

		responseSells.data.data.map((lead) => {
			fecha = formatDate(new Date(lead.date));

			for (let i = 0; i < objeto.length; i++) {
				if (objeto[i].Date === fecha) {
					objeto[i].sells++
				}
			}
		});
	}

	if (responseCalls.data.result === "success") {

		responseCalls.data.data.map((lead) => {
			fecha = formatDate(new Date(lead.date));

			for (let i = 0; i < objeto.length; i++) {
				if (objeto[i].Date === fecha) {
					objeto[i].calls++
				}
			}
		});
	}

	if (objeto.length > 0) {
		objeto.sort((a, b) => {
			return new Date(a.Date) - new Date(b.Date);
		});

	}

	return objeto;
}