import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../auth/components/context/auth-context';
import AxiosConfig from '../../axios/axiosConfig';
import Sticky_note from './sticky-note';
import Slider from '../slider/slider';
import TutorialCard from '../tutorial-cards/tutorial-card.component';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './home.styles.scss';

const loader = 'https://cdn.bemaster.com/media/images/admin/gifs/bemaster_loader.gif'

const meetingColor = "E9C24B"

const initialState = {
    title: '',
    text: '',
    date: new Date(),
    link: ''
}

const Home = () => {

    const authContext = useContext(AuthContext);
    const loginInfo = authContext.loginInfo;
    const role = loginInfo.moduleRoles[0].role.role1;
    const roleType = loginInfo.moduleRoles[0].role.roleType;

    const [notes, setNotes] = useState<any>(null);
    const [disable, setDisable] = useState<boolean>(false);
    const [new_visible, setNew_visible] = useState<boolean>(false);
    const [datosForm, setDatosForm] = useState<any>(initialState);

    const getSticky = async () => {
        const response = await AxiosConfig.get('/sitcky/GetNotes');

        setNotes(response.data);
    }

    const createSticky = async () => {
        const res = await AxiosConfig.post('/sitcky/AddNotes', {
            title: datosForm.title,
            text: datosForm.text,
            link: datosForm.link,
            expirationDate: datosForm.date
        });

        if (res.data.result === 'success') {
            notify(res.data.message);
        } else {
            notify('Ha ocurrido un error en la creación', 'error');
        }
    }

    useEffect(() => {
        getSticky();
    }, []);

    useEffect(() => {
    }, [notes]);

    //hide popUp
    const hideInfo = () => {
        setNew_visible(false);
        setDatosForm(initialState);
    }

    const newNote = () => {
        setNew_visible(true);
    }

    const sendNote = async () => {
        setDisable(true);
        await createSticky();
        setDisable(false);
        hideInfo();
        getSticky();
    }

    const handleChange = (e: any) => {
        const element = e.target ? e.target : e.element;
        const value = e.target ? e.target.value : e.value;
        setDatosForm({ ...datosForm, [`${element.id}`]: value });
    }


    const renderPopUp = () => {
        return (
            <>
                <div className="containerAddRequest">
                    <div className="addRowForm">
                        <form action="">
                            <div className="rowForm">
                                <div className="itemForm">
                                    <span className="labelForm">Título</span>
                                    <input type="text" list='titulos' autoComplete="off" value={datosForm.title} id="title" className="inputForm" onChange={handleChange} />
                                    <datalist id="titulos">
                                        <option value="Reunión" />
                                    </datalist>
                                </div>
                            </div>
                            <div className="rowForm">
                                <div className="itemForm">
                                    <span className="labelForm">Link</span>
                                    <input type="text" autoComplete="off" value={datosForm.link} id="link" className="inputForm" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="rowForm">
                                <div className="itemForm">
                                    <span className="labelForm">Fecha de expiración</span>
                                    <DateBox defaultValue={datosForm.date} onValueChanged={handleChange} type="datetime" id="date" width="300px" />
                                </div>
                            </div>
                            <div className="rowForm">
                                <div className="itemForm">
                                    <span className="labelForm">Texto</span>
                                    <textarea value={datosForm.text} autoComplete="off" id="text" className="textAreaForm" onChange={handleChange} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="charging" style={{ display: disable ? "flex" : "none" }}>
                    <img src={loader} alt="is Charging" />
                </div>
            </>
        )
    }

    return (
        <>
            < Popup
                visible={new_visible}
                onHiding={hideInfo}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Nueva nota"
                container=".dx-viewport"
                width={410}
                height={'80vh'}
                position="center"
                contentRender={renderPopUp}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="center"
                    options={{ text: 'Save', onClick: sendNote }}
                    disabled={disable}
                    visible={!disable}
                />

            </Popup>
            <div className="main-home-container">
                <section id="banner">
                    <div className="containerTittle">
                        <div className="containerText">
                            <h3>Plataforma Administrativa <span className="Be">Be</span><span className="Master">Master</span></h3>
                        </div>
                    </div>
                    {/* <div className="mouse_scroll">
                        <div>
                            <span className="m_scroll_arrow unu"></span>
                            <span className="m_scroll_arrow doi"></span>
                            <span className="m_scroll_arrow trei"></span>
                        </div>
                    </div> */}
                </section>
                {/* <section id="notices">
                    <h3>Noticias y clasificados</h3>
                    <div className="notices-main">
                        <div className="notice-container">
                            <ul>
                                {notes && notes.map((note: any, key: number) => {
                                    return <Sticky_note tittle={note.title} text={note.text} background={note.title === 'Reunión' ? meetingColor : ''} key={key} />
                                })}
                            </ul>
                            <div className="new-button" onClick={newNote}>+</div>
                        </div>
                        <div className="companyContainer">
                            <Slider />
                        </div>
                    </div>
                </section>
                <section id="tutorials">
                    <h3>Tutoriales</h3>
                    <div className="wrapperTutorials">
                        <TutorialCard
                            img="https://picsum.photos/900/400?random=1"
                            tittle="Crear un usuario"
                            description="En este tutorial se explica detalladamente como crear un nuevo usuario para la plataforma"
                            video="https://www.youtube.com/embed/aNi_KtCNpNI"
                        />
                        <TutorialCard
                            img="https://picsum.photos/900/400?random=2"
                            tittle="Crear un usuario"
                            description="En este tutorial se explica detalladamente como crear un nuevo usuario para la plataforma"
                        />
                        <TutorialCard
                            img="https://picsum.photos/900/400?random=3"
                            tittle="Crear un usuario"
                            description="En este tutorial se explica detalladamente como crear un nuevo usuario para la plataforma"
                        />
                        <TutorialCard
                            img="https://picsum.photos/900/400?random=4"
                            tittle="Crear un usuario"
                            description="En este tutorial se explica detalladamente como crear un nuevo usuario para la plataforma"
                        />
                    </div>
                </section> */}
            </div>
        </>
    );
};

export default Home;