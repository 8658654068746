import React from 'react'
import ContentTemplate from './powerclassTemplate.component';


const MasterDetail_content = (props: any) => {
    return(
        <ContentTemplate props={props} />
    )
}

export default MasterDetail_content;