import React, { useCallback, useRef, useState } from 'react'
import { DataGrid, Column, Button, Editing, Lookup, FilterRow, HeaderFilter, Paging, Pager, Texts, Form, Popup } from 'devextreme-react/data-grid';
import { Config } from '../../Config';
import { careersDataSource } from '../ads-management/ads-management.data';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { contentSource, moduleClassSource } from './powerclass-management-bm.data';
import { Item } from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';
import notify from 'devextreme/ui/notify';

const ContentTemplate = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idContent",
            loadUrl: `${Config.url.api}/ContentBm/GetContent/${props.props.data.data.idContentCategory}`,
            updateUrl: `${Config.url.api}/ContentBm/UpdateContent`,
            insertUrl: `${Config.url.api}/ContentBm/InsertContent`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idContentCategory: props.props.data.data.idContentCategory };
            },
        }),
    };

    const gridRef = useRef<DataGrid>(null);

    const [uploadURL, setUploadURL] = useState<string>("");

    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
            console.error("error subiendo archivo", e.error.responseText);
            notify("error subiendo archivo", e.error.responseText);
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
            console.error("error en la conexión", e.error.responseText);
            notify('error en la conexión', e.error.responseText);
        }
    }, []);

    const uploadedFile = (e: any) => {
        let xhttp = e.request;
        notify('uploaded successfully');
    }

    //card Clase 513 x 261
    const onValueChanged = (e: any) => {
        var _URL = window.URL || window.webkitURL;
        var file, img;
        if ((file = e.value[0])) {
            img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            img.onload = (image: any) => {
                if ((image.path[0].width < 513 && image.path[0].width > 515) || (image.path[0].height < 261 && image.path[0].height > 263)) {
                    setUploadURL("");
                    notify('¡Cuidado! Las dimensiones de la imagen no son las correctas (w=513 x h=261)', "error");
                } else {
                    setUploadURL(`${Config.url.api}/ContentBm/uploadContentImage`);
                    notify('Imagen perfecta para ser subida', 'success');
                }
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        }
    }

    const onRowInserted = (e: any) => {
        notify(e.data.message, e.data.result);
    }

    const editCellRender = (cellInfo: any) => {
        return (
            <>
                <FileUploader
                    accept="image/png"
                    multiple={false}
                    uploadMode="useButtons"
                    uploadUrl={uploadURL}
                    invalidFileExtensionMessage="Only PNG files is accepted"
                    invalidMaxFileSizeMessage="Max file size is 250 KB"
                    labelText="Select your PNG file"
                    maxFileSize={1000000}
                    uploadHeaders={
                        {
                            'Authorization': localStorage.getItem('token'),
                            'keyRequest': cellInfo.data.idContent
                        }
                    }
                    onUploadError={onUploadError}
                    onUploaded={uploadedFile}
                    onValueChanged={onValueChanged}
                />
            </>
        )
    }

    const videoSource = () => {
        return contentSource({ data: props.props.data.data.idContentCategory });
    }

    return (
        <>
            <DataGrid
                ref={gridRef}
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idContent"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
                onRowInserted={onRowInserted}
            >

                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Class Info"
                        showTitle="true"
                        width={"70%"}
                        height={"auto"}
                        maxHeight={"95vh"}
                    />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2} >
                            <Item dataField="type" />
                            <Item dataField="order" />
                            <Item dataField="name" />
                            <Item dataField="value" />
                            <Item dataField="category" />
                            <Item dataField="url" />
                            <Item dataField="idCareer" />
                            <Item dataField="idModuleClass" />
                            <Item dataField="idNextVideo" />
                        </Item>
                        <Item itemType="group" colCount={1} colSpan={2}>
                            <Item dataField="classImage" />
                        </Item>
                        <Item itemType="group" colCount={1} colSpan={2}>
                            <Item
                                dataField="description"
                                editorType="dxTextArea"
                                colSpan={2}
                                editorOptions={{ height: 100, maxLength: 1000 }}
                            />
                        </Item>
                    </Form>
                </Editing>

                <Column type="buttons" width={"auto"}>
                    <Button name="edit" text="Editar" />
                </Column>
                <Column dataField="idContent" caption="idContent" dataType="int" visible={false} sortIndex={0} sortOrder="asc" />
                <Column dataField="type" caption="tipo" dataType="string" visible={false} >
                    <Lookup
                        dataSource={['Mentortalk', 'Powerclass']}
                    />
                </Column>
                <Column dataField="order" width={"auto"} caption="Orden" dataType="number" />
                <Column dataField="name" width={"auto"} caption="Nombre" dataType="string" />
                <Column dataField="idNicho" caption="Nicho" dataType="string" visible={false} />
                <Column dataField="value" caption="Valor" dataType="number" visible={false} />
                <Column dataField="category" caption="Categoria" dataType="string" visible={false} />
                <Column dataField="url" caption="Url Video" width="250" dataType="string" />
                <Column dataField="idContentParent" caption="ContentParent" dataType="int" visible={false} />
                <Column dataField="idCareer" caption="Carrera" dataType="int" visible={false} >
                    <Lookup
                        dataSource={careersDataSource}
                        valueExpr="idCareer"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField="categoryOrder" caption="Orden de Categoría" dataType="int" visible={false} />
                <Column dataField="tags" caption="Etiquetas" dataType="string" visible={false} />
                <Column dataField="videoPlayer" caption="Tipo de Video Player" dataType="string" visible={false} />
                <Column dataField="idContentCategory" caption="Categoría de contenido" dataType="int" visible={false} />
                <Column dataField="description" caption="Descripción" width="40%" dataType="string" />
                <Column dataField="classImage" caption="Imagen de la clase" visible={false} editCellRender={editCellRender} />
                <Column dataField="idModuleClass" width={"auto"} caption="Modulo de clase" dataType="int" >
                    <Lookup
                        dataSource={moduleClassSource}
                        valueExpr="idModuleClass"
                        displayExpr="class"
                    />
                </Column>
                <Column dataField="idNextVideo" width={"auto"} caption="Próximo video" dataType="int" >
                    <Lookup
                        dataSource={videoSource}
                        valueExpr="idContent"
                        displayExpr="name"
                    />
                </Column>
            </DataGrid>
        </>
    )
}

export default ContentTemplate;