import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import AxiosConfig from '../../axios/axiosConfig';
import { contenidos, createTask, formatDate, getTeams, getTask, validateContent, channelsDataSource, formatDataSource } from './content-data';
import {
    Column, DataGrid, FilterRow, HeaderFilter, Editing,
    Lookup, RequiredRule, Paging, Pager, Button, Form, Texts, Export,
} from "devextreme-react/data-grid";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { Item } from 'devextreme-react/form';
import { Config } from "../../Config";
import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import 'devextreme-react/text-area';
import "devextreme/data/odata/store";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import { productDataSource, usersDataSource } from '../../shared/dataSources/data-source.data';
import './content-request.styles.scss';
import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView from 'devextreme-react/tree-view';
import TextBox from 'devextreme-react/text-box'

const loader = 'https://cdn.bemaster.com/media/images/admin/gifs/bemaster_loader.gif';

const gridDataSource = {
    store: createStore({
        key: "idContentRequest",
        insertUrl: `${Config.url.api}/Content/addContentRequests`,
        updateUrl: `${Config.url.api}/Content/UpdateContentRequest`,
        loadUrl: `${Config.url.api}/Content/GetContentRequests`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    })
}

const ContentRequest = () => {

    //use a ref with dataGrid
    const gridRef = useRef<any>(null);
    const descriptionRef = useRef<any>(null);
    const treeViewSuppliesRef = useRef<any>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.contentRequest.id;

    //initial states
    const now = new Date();
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
    const [popUpData, setPopUpData] = useState<any>();
    const [due_on, setDue_on] = useState<Date>();
    const [teamsData, setTeamsData] = useState();
    const [teamGid, setTeamGid] = useState(null);
    const [disable, setDisable] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [gidProject, setGidProject] = useState<string>("");

    const user = authContext.loginInfo;
    let roleType = "";
    let role = "";

    // getting permissions for current module
    user.moduleRoles
        .filter((res: any) => {
            return res.idModule === currentModule;
        })
        .map((permissionsAvailable: any) => {
            role = permissionsAvailable.role.role1;
            roleType = permissionsAvailable.role.roleType;
            return true;
        });

    const setStateValue = (rowData: any, value: any) => {
        rowData.idChannel = value;
    }

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    //Create a task on AsannaApp
    //tambien actualiza la info de la base de datos con los datos de la tarea creada
    const sendRequest = async () => {
        setDisable(true);
        if (due_on && teamGid !== null) {
            let data = popUpData;
            data.deliveryDate = formatDate(due_on);
            data.teamGid = teamGid;
            data.user = `${user.firstName} ${user.lastName}`;
            const result = await createTask(data);
            if (result?.result === "success") {
                const linkTaskStr = `https://app.asana.com/0/${result.data.projects[0].gid}/${result.data.gid}`;
                const update = await AxiosConfig.put('/Content/UpdateAsanaRequest', {
                    idContentRequest: data.idContentRequest,
                    idTask: result.data.gid,
                    linkTask: linkTaskStr,
                    deliveryDate: result.data.due_on,
                    startTaskDate: new Date()
                });
                if (update.data.result === "success") {
                    gridRef.current?.instance.refresh();
                    setDisable(false);
                    notify("Se ha enviado correctamente la solicitud");
                    setPopUpVisible(!popUpVisible);
                }
            }
        } else {
            setDisable(false);
            notify("Debes seleccionar una Fecha y un Equipo", 'info');
        }
    }

    //hide popUp
    const hideInfo = () => {
        setPopUpVisible(false);
    }

    const solicitudButtonOptions = {
        text: 'Send',
        onClick: sendRequest
    }

    const onChangeDate = (e: any) => {
        setDue_on(e.value);
    }

    //llama los equipos del projecto seleccionado

    const onChangedTeam = (e: any) => {
        setTeamGid(e.value);
    }

    useEffect(() => {

        const fetchTeams = async () => {
            if (gidProject !== "") {
                const result = await getTeams(gidProject);
                setTeamsData(result);
            }
        }

        fetchTeams();
    }, [gidProject]);

    //activa la solicitud de tarea
    const requestContent = async (e: any) => {
        if (!e.row.data.idTask) {
            const content = await validateContent(e.row.data, setGidProject);
            setPopUpData(content);
            setPopUpVisible(true);
        }
    }

    //activa el edit del datagrid
    const editClick = (e: any) => {
        if (!e.row.data.idTask) {
            gridRef.current?.instance.editRow(e.row.rowIndex);
        }
    }

    //actualiza la base de datos con la info de la tarea y refresca el datagrid
    const refreshClick = async (e: any) => {

        setRefresh(true);
        const response = await getTask(e.row.data);

        if (response?.result === "success") {
            gridRef.current?.instance.refresh();
            setRefresh(false);
        } else {
            setRefresh(false);
            notify("No se ha encontrado la tarea en Asana", 'error');
        }
    }

    //Allow Edit and Refresh
    const allowEdit = (e: any) => {
        return !e.row.data.idTask;
    }
    const allowRefresh = (e: any) => {
        return e.row.data.idTask;
    }

    const Link = (data: any) => {
        return data.value ? <a href={data.value} target='_blank' rel="noopener noreferrer"> Ver Tarea </a> : <>No hay Link</>
    }

    const treeView_itemSelectionChanged = (e: any, cellInfo: any) => {
        cellInfo.setValue(e.component.getSelectedNodeKeys().toString());
    }

    const treeViewSuppliesReady = (e: any) => {
        let treeView = (e.component.selectItem && e.component) || (treeViewSuppliesRef && treeViewSuppliesRef.current?.instance);

        if (treeView) {
            if (!e.value) {
                treeView.unselectAll();
            } else {
                let values = e.value;
                values && values.forEach((value: any) => {
                    treeView.selectItem(value);
                });
            }
        }
    }

    const treeViewSuppliesRender = (e: any, cellInfo: any) => {
        return (
            <TreeView
                dataSource={[{ value: 'Gráfico' }, { value: 'Audiovisual' }, { value: 'Copy' }]}
                ref={treeViewSuppliesRef}
                dataStructure="plain"
                keyExpr="value"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                selectNodesRecursive={false}
                displayExpr="value"
                selectByClick={true}
                onContentReady={treeViewSuppliesReady}
                onItemSelectionChanged={el => treeView_itemSelectionChanged(el, cellInfo)}
                id="supplies"
            />
        );
    }

    const editCellRender = (cellInfo: any) => {
        return (
            <>
                <DropDownBox
                    valueExpr="value"
                    displayExpr="value"
                    placeholder="Seleccionar un insumo si es necesario"
                    showClearButton={true}
                    dataSource={[{ value: 'Gráfico' }, { value: 'Audiovisual' }, { value: 'Copy' }]}
                    onValueChanged={treeViewSuppliesReady}
                    contentRender={e => treeViewSuppliesRender(e, cellInfo)}
                    width="300px"
                    id="supplies"
                />
            </>
        )
    }
    const editCellRender2 = () => {
        return (
            <>
                <TextBox
                    placeholder="Dar acceso a contenido@bemaster.com"
                />
            </>
        )
    }

    const nameRender = (user: any) => {
        const firstName: string = user.firstName.split(' ')[0];
        const lastName: string = user.lastName.split(' ')[0];
        return `${firstName} ${lastName}`
    }

    return (
        <>
            {
                authorized && authorized ?
                    <div className="content-request-main-container">
                        <div className="chargingPage" style={{ display: refresh ? "flex" : "none" }}>
                            <img src={loader} alt="is Charging" />
                        </div>
                        < Popup
                            onHiding={hideInfo}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showCloseButton={false}
                            showTitle={true}
                            title="Confirmar Solicitud de Contenido"
                            container=".dx-viewport"
                            width={400}
                            height={300}
                            position="center"
                            visible={popUpVisible}
                        >
                            <ToolbarItem
                                widget="dxButton"
                                toolbar="bottom"
                                location="center"
                                options={solicitudButtonOptions}
                                disabled={disable}
                                visible={!disable}
                            />
                            <div className="containerRequest">
                                <div className="fecha">
                                    <h5>Fecha de entrega</h5>
                                    <DateBox defaultValue={now} onValueChanged={onChangeDate}
                                        type="date" id="datePrincipio" width="100px" />
                                </div>
                                <div className="equipo">
                                    <h5>Equipo que asigna: </h5>
                                    <SelectBox
                                        id="teamsFilter"
                                        dataSource={teamsData}
                                        defaultValue={0}
                                        displayExpr="name"
                                        valueExpr="gid"
                                        showClearButton={true}
                                        placeholder="Selecciona un Equipo"
                                        searchEnabled={true}
                                        onValueChanged={onChangedTeam}
                                        width="160px"
                                    />
                                </div>
                            </div>
                            <div id="charging" style={{ display: disable ? "flex" : "none" }}>
                                <img src={loader} alt="is Charging" />
                            </div>
                        </Popup>

                        <div className="banner">
                            <div className="banner-overlaySC">
                                <div className="banner-img">
                                    <h1>Solicitudes de Contenido</h1>
                                </div>
                            </div>
                        </div>

                        <DataGrid
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            dataSource={gridDataSource}
                            focusedRowEnabled={true}
                            id="dataGrid"
                            keyExpr="idContentRequest"
                            ref={gridRef}
                            showBorders={true}
                            showRowLines={true}
                            height="76vh"
                            width="95vw"
                        >
                            <Export enabled={(roleType === "admin" || roleType === "leader") && (role === "Studios" || role === "Plataformas")} fileName="Solicitudes de contenido" />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} defaultPageSize={15} />
                            <Pager showInfo={true} />

                            <Editing
                                mode="popup"
                                allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}
                            >
                                <Texts saveRowChanges="Save Changes" />
                                <Popup title="Content Info" showTitle={true} width={700} height={525} />
                                <Form>
                                    <Item itemType="group" caption="Request Info" colCount={2} colSpan={2}>
                                        <Item dataField="name" isRequired={true}>
                                            <RequiredRule message="El nombre es requerido" />
                                        </Item>
                                        <Item dataField="idProduct" isRequired={true}>
                                            <RequiredRule message="La carrera es requerida" />
                                        </Item>
                                        <Item dataField="typeContent" isRequired={true}>
                                            <RequiredRule message="El tipo de contenido es requerido" />
                                        </Item>
                                        <Item dataField="idChannel" isRequired={true}>
                                            <RequiredRule message="El medio de distribución es requerido" />
                                        </Item>
                                        <Item dataField="idFormat" isRequired={true}>
                                            <RequiredRule message="El formato es requerido" />
                                        </Item>
                                    </Item>

                                    {/* <Item itemType="group" caption="Insumos" colCount={2} colSpan={2} > */}
                                        {/* <Item dataField='supplies' /> */}
                                        {/* <Item dataField='linkSupplies' /> */}
                                    {/* </Item> */}

                                    <Item itemType="group" caption="Información Adicional" colCount={2} colSpan={2}>
                                        <Item
                                            dataField="description"
                                            editorType="dxTextArea"
                                            colSpan={2}
                                            editorOptions={{ height: 150, maxLength: 2000 }}
                                            isRequired={true}
                                            ref={descriptionRef}
                                        >
                                            <RequiredRule message="La descripción es necesaria" />
                                        </Item>
                                    </Item>
                                </Form>
                            </Editing>

                            <Column type="buttons" width={110}>
                                <Button name="edit" text="Editar" onClick={editClick} visible={allowEdit} />
                                <Button icon="event" text="Agendar tarea en Asana" onClick={requestContent} visible={allowEdit} />
                                <Button icon="refresh" text="Refrescar información" onClick={refreshClick} visible={allowRefresh} />
                            </Column>
                            <Column dataField="idContentRequest" caption="idContent" dataType="number" visible={false} sortOrder={"desc"} />
                            <Column dataField="idUser" caption="Solicitante" dataType="number" >
                            <Lookup
                                    dataSource={usersDataSource}
                                    valueExpr="idUser"
                                    displayExpr={nameRender}
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="idProduct" caption="Producto" dataType="string" allowEditing={true}>
                                <Lookup
                                    dataSource={productDataSource}
                                    valueExpr="idProduct"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="status" caption="Estado" dataType="string" allowEditing={false} />
                            <Column dataField="name" caption="Nombre de la solicitud" dataType="string" allowEditing={true} width="200" />
                            <Column dataField="typeContent" caption="Tipo de Solicitud" allowEditing={true}>
                                <Lookup
                                    dataSource={contenidos}
                                    valueExpr="tipoContenido"
                                    displayExpr="tipoContenido"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="idChannel" caption="Medio de distribución" setCellValue={setStateValue} dataType="string" allowEditing={true}>
                                <Lookup
                                    dataSource={channelsDataSource}
                                    valueExpr="idChannel"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="idFormat" caption="Formato" dataType="string" allowEditing={true}>
                                <Lookup
                                    dataSource={formatDataSource}
                                    valueExpr="idFormat"
                                    displayExpr="description"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="supplies" caption="Insumos" dataType="string" editCellRender={editCellRender} />
                            <Column dataField="linkSupplies" caption="Link de insumo" alignment={"center"} editCellRender={editCellRender2} cellRender={Link} dataType="hyperlink" />
                            <Column dataField="linkTask" caption="Link Asana" cellRender={Link} dataType="hyperlink" allowEditing={false} />
                            <Column dataField="description" caption="Descripción" dataType="string" allowEditing={true} width="600" />
                            <Column alignment="center" dataField="createDate" caption="Fecha creación" dataType="datetime" allowEditing={false}
                                format="yyyy-MM-dd" />
                            <Column alignment="center" dataField="startTaskDate" caption="Fecha solicitud" dataType="datetime" allowEditing={false}
                                format="yyyy-MM-dd" />
                            <Column alignment="center" dataField="deliveryDate" caption="Fecha estimada entrega" dataType="datetime" allowEditing={false}
                                format="yyyy-MM-dd" />
                            <Column alignment="center" dataField="endTaskDate" caption="Fecha finalización" dataType="datetime" allowEditing={false}
                                format="yyyy-MM-dd" />
                        </DataGrid>
                    </div> : <></>
            }
        </>
    )
}

export default ContentRequest;