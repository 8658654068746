import React from 'react'
import DataGrid, { Button, Column, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Config } from '../../Config';


const PendingRewards = ({ addReward, renderSells, menor, mayor, idReward }: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idLeadAction",
            loadUrl: `${Config.url.api}/Affiliates/GetBillingAffiliates/${menor}/${mayor}/${idReward}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
            }
        }),
        reshapeOnPush: true
    };

    return (
        <>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idLeadAction"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
                repaintChangesOnly={true}
                height="350px"
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} />
                <Pager showInfo={true} />

                <Column type="buttons" caption="Registrar Entrega" width={110}>
                    <Button icon="check" text="Registrar entrega" onClick={addReward} />
                </Column>
                <Column dataField="idLead" caption="idLead" dataType="string" allowEditing={false} visible={false} />
                <Column dataField="leadName" caption="Afiliado" dataType="string" allowEditing={false} />
                <Column dataField="idLeadAction" dataType="int" allowEditing={false} visible={false} />
                <Column dataField="affiliateCode" caption="Código" dataType="string" allowEditing={false} />
                <Column dataField="leadEmail" caption="Email" dataType="string" allowEditing={false} />
                <Column dataField="billing" caption="Facturación total" dataType="int" cellRender={renderSells} allowEditing={false} sortOrder={"desc"} />
            </DataGrid>
        </>
    )
}

export default PendingRewards;