import AxiosConfig from "../../axios/axiosConfig";

//Modificación de número a USD
const optionsUSD = { style: "currency", currency: "COP" };
export const formatCOP = new Intl.NumberFormat("es-CO", optionsUSD);

export const formatDate = (date) => {

    const months = [
        "Enero", "Febrero", "Marzo",
        "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre",
        "Octubre", "Noviembre", "Diciembre"
    ]

    const dia = date.getDate();
    const mes = months[date.getMonth()];
    const year = date.getFullYear();

    const response = `${dia} de ${mes} de ${year}`;

    return response;
}

export const userData = async (idUser, setUserInfo) => {
    const response = await AxiosConfig.get("/UserManagement/getUser");

    if (response.data.result === "success") {
        response.data.data.map(e => {
            if (e.idUser === idUser) {
                fetchData(setUserInfo, e);
            }
            return true;
        });
    } else {
        console.log("no se encuentra el usuario " + idUser);
    }
}

export const fetchData = async (setUserInfo, info) => {

    const typeContract = await AxiosConfig.get("/UserManagement/GetTypeContract");
    const employment = await AxiosConfig.get("/UserManagement/GetEmployment")

    if (typeContract.data.result === "success") {
        typeContract.data.data.map(e => {
            if (e.idTypeContract === info.idTypeContract) {
                info.TypeContract = e.name;
            }
            return true;
        });
    }

    if (employment.data.result === "success") {
        employment.data.data.map(e => {
            if (e.idEmployment === info.idEmployment) {
                info.Employment = e.name;
            }
            return true;
        });
    }

    info.salary = formatCOP.format(info.salary);
    info.sinceDateContract = formatDate(new Date(info.sinceDateContract));

    setUserInfo(info);
}


