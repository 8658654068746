import React, { useCallback, useEffect, useRef, useState } from 'react'
import LeadsCareerTemplate from './leads-management-careers.component'

const loader = 'https://cdn.bemaster.com/media/images/admin/gifs/bemaster_loader.gif';

const CareersTemplate = (props: any) => {

    return (
        <LeadsCareerTemplate props={props} />
    );

}

export default CareersTemplate;