import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup, RequiredRule,
    Paging, Pager, Button, Popup, Form, Texts
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/form";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import notify from "devextreme/ui/notify";
import { AuthContext } from '../auth/components/context/auth-context';
import { statusDataSource } from '../../shared/dataSources/data-source.data';



const CancelationsMastertools = () => {

    const gridRef = useRef<DataGrid>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.cancelationMastertools.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [statusForm, setStatusForm] = useState("");

    const gridDataSource = {
        store: createStore({
            key: "idLeadAction",
            updateUrl: `${Config.url.api}/mastertoolsCancelations/updateCancelation`,
            loadUrl: `${Config.url.api}/mastertoolsCancelations/GetCancelations`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem('token') };
            },
        }),
    }

    const onRowUpdated = (e: any) => {
        if (e.data.result === "success") {
            notify(e.data.message);
        } else {
            notify(e.data.message, e.data.result);
        }
    }

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);


    return (
        <>
            {
                authorized && authorized ? <div className="cancelation-mastertools-main-container">

                    <h1 className="is-size-5 m-5 center">Gestión de cancelaciones MasterTools</h1>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idLeadAction"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        showRowLines={true}
                        onRowUpdated={onRowUpdated}
                        height="87vh"
                        width="94.5vw"
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="20" />
                        <Pager showInfo={true} />

                        <Editing mode="popup" allowUpdating={true}>
                            <Texts saveRowChanges="Guardar" />
                            <Popup
                                title="Cancelation Info"
                                showTitle="true"
                                width={"70%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                            />
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2} visible={statusForm !== "checking"} >
                                    <Item dataField="idStatus" />
                                </Item>
                            </Form>
                        </Editing>

                        <Column type="buttons" caption="Acciones" width={80}>
                            <Button name="edit" text="Editar" />
                        </Column>
                        <Column dataField="idLeadAction" caption="idLeadAction" dataType="number" visible={false} />
                        <Column dataField="type" caption="Tipo" dataType="string" />
                        <Column dataField="date" caption="Fecha de Solicitud" dataType="datetime" format="yy-MM-dd hh:mm:ss" />
                        <Column dataField="idStatus" caption="Estado de solicitud" dataType="string">
                            <Lookup
                                dataSource={statusDataSource("MtoolsCancel")}
                                valueExpr="idStatusAction"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="leadName" caption="Nombre Cliente" dataType="string" />
                        <Column dataField="leadEmail" caption="Email Cliente" dataType="string" />
                        <Column dataField="idProduct" caption="Producto" dataType="string">
                            <Lookup
                                dataSource={[{ idProduct: 12, name: 'MasterTools' }]}
                                valueExpr="idProduct"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="transaction" caption="Transacción de Compra" dataType="string" />
                        <Column dataField="affiliateCode" caption="Código de afiliado" dataType="string" />
                        <Column dataField="whyCancel" caption="Motivos de cancelación" dataType="string" />
                        <Column dataField="migration" caption="Migración a otra plataforma" dataType="boolean" />
                        <Column dataField="reEntry" caption="Opciones de reingreso" dataType="number" />
                        <Column dataField="userObservations" width="700" caption="Comentarios de cancelación" dataType="string" />
                    </DataGrid>
                </div> : <></>
            }
        </>
    )
}

export default CancelationsMastertools;