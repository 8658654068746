import AxiosConfig from "../../axios/axiosConfig";
import CustomStore from "devextreme/data/custom_store";

export const careersDataSource = {
	store: new CustomStore({
		key: "idCareer",
		loadMode: "raw",
		cacheRawData: true,
		load: () => {
			return AxiosConfig.get("/ad/GetCareers")
				.then((response) => {
					return response.data;
				})
				.catch((e) => {
					console.error(e);
				});
		},
	}),
	sort: "name",
};

//on change fileUpdater
export const onValueChanged = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.value[0]); // convert to base64 string
};