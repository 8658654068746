import React from 'react'
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import AwardClassMissionTab from './award-tab.component';
import ClassMissionTab from './missions-tab.component';


const MasterDetailAwardMission = (props: any) => {



    const renderAwardTab = () => {
        return <AwardClassMissionTab props={props} />;
    }

    const renderClassMissionTab = () => {
        return <ClassMissionTab props={props}/>;
    }

    // const renderUserAwardsTab = () => {
    //     return <UserAwards data={props.data.data} isAdmin={isAdmin} />
    // }

    return (
        <TabPanel>
            <Item title="Recompensa" render={renderAwardTab} />
            <Item title="Misiones" render={renderClassMissionTab} />
        </TabPanel>
    )
}

export default MasterDetailAwardMission;