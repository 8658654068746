import React from 'react';
import { CircularGauge, Scale, Label, RangeContainer, Range, Export, Title, Font } from 'devextreme-react/circular-gauge';

import './calls.styles.scss';

interface props {
    currentSales?: number
}

const Calls = (props: any) => {

    // const [currentSales, setCurrentSales] = useState(180);

    const label = 'Ventas Realizadas';
    const sales = props.currentSales;
    const endValue = props.isAdmin ? sales + 200 : 240;

    return (
        <CircularGauge
            id="gauge"
            value={sales}
        >
            <Scale startValue={0} endValue={endValue} tickInterval={20}>
                <Label customizeText={label} />
            </Scale>
            <RangeContainer>
                <Range startValue={0} endValue={endValue/5} color="#CE2029" />
                <Range startValue={endValue/5} endValue={endValue/3} color="#FFD700" />
                <Range startValue={endValue/3} endValue={endValue/2} color="#bbff00" />
                <Range startValue={endValue/2} endValue={endValue} color="#228B22" />
            </RangeContainer>
            <Export enabled={true} />
            <Title text={label}>
                <Font size={20} />
            </Title>
        </CircularGauge>
    );
};

export default Calls;