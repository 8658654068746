import React, { useRef } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup,
    Paging, Pager, Popup, Texts
} from "devextreme-react/data-grid";
import DataSource from 'devextreme/data/data_source';
import "devextreme-react/text-area";
import { Config } from "../../Config";
import notify from "devextreme/ui/notify";
import AxiosConfig from '../../axios/axiosConfig';
import { leadDataSource, productDataSource, statusDataSource, usersDataSource } from '../../shared/dataSources/data-source.data';


const gridDataSource = new DataSource({
    store: createStore({
        key: "idLogLeadAction",
        loadUrl: `${Config.url.api}/commissionRecovery/GetDataSent`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    })
});

const Commission_recovery_sent = () => {

    const gridRef = useRef<DataGrid>(null);

    const onRowUpdated = (e: any) => {
        notify(e.data.message);
    }

    const displayName = (e: any) => {
        return `${e.firstName.split(' ')[0]} ${e.lastName.split(' ')[0]}`
    }

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: () => { gridDataSource.reload() }
            }
        });
    }

    return (
        <>
            <h1 className="is-size-5 m-5 center">Recuperación de comisiones Enviadas</h1>

            <DataGrid
                ref={gridRef}
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idEscalation"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                onRowUpdated={onRowUpdated}
                showRowLines={true}
                onToolbarPreparing={onToolbarPreparing}
                height="87vh"
                width="94vw"
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />

                <Editing mode="popup">
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Commission Recovery Info"
                        showTitle="true"
                        maxWidth={"70%"}
                        height={"auto"}
                        maxHeight={"95vh"}
                    />
                </Editing>

                <Column dataField="idLogLeadAction" dataType="number" visible={false} />
                <Column dataField="idLeadAction" dataType="number" visible={false} />
                <Column dataField="idStatus" dataType="string" caption="Estado">
                    <Lookup
                        dataSource={statusDataSource('commissionRecovery')}
                        valueExpr="idStatusAction"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="date" dataType="datetime" caption="Fecha de entrega" format="yyyy-MM-dd" sortOrder={"desc"} />
                <Column dataField="idUser" dataType="string" caption="Usuario que registra entrega" >
                    <Lookup
                        dataSource={usersDataSource}
                        valueExpr="idUser"
                        displayExpr={displayName}
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="leadAction.idLead" name={'email'} dataType="string" caption="Email del lead" >
                    <Lookup
                        dataSource={leadDataSource}
                        valueExpr="idLead"
                        displayExpr="email"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="leadAction.idProduct" dataType="string" caption="Producto" width="120" >
                    <Lookup
                        dataSource={productDataSource}
                        valueExpr="idProduct"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="leadAction.affiliateCode" dataType="string" caption="Código de afiliación" />
                <Column dataField="leadAction.transaction" dataType="string" caption="Código de transacción" />
                <Column dataField="observation" dataType="string" caption="Comentarios" width="700" />
            </DataGrid>
        </>
    )
}

export default Commission_recovery_sent;