import {
    Chart, Series, CommonSeriesSettings, Label, Format,
    Legend, Export, Tooltip, Title, Subtitle
} from 'devextreme-react/chart';
import React, { useEffect, useState } from 'react';
import { fetchVentas } from './analytics.data';


const Analytics = (props: any) => {

    //initial states
    const [dataSource, setDataSource] = useState<any>();

    const sourceDataFetch = async () => {
        const objetoSells = await fetchVentas(props.dateIni, props.dateEnd, props.userFilter, props.productFilter);
        setDataSource(objetoSells);
    }

    //useEffect for inline updates
    useEffect(() => {
        //Call of fetchVentas on analytics.data file
        sourceDataFetch();
    }, [props]);

    return (
        <Chart id="chart"
            dataSource={dataSource}
        >
            <CommonSeriesSettings
                argumentField="state"
                type="spline"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
            >
                <Label visible={true}>
                    <Format type="fixedPoint" precision={0} />
                </Label>
            </CommonSeriesSettings>

            <Series
                argumentField="Date"
                valueField="sells"
                name="Sells"
            />
            <Series
                argumentField="Date"
                valueField="calls"
                name="Calls"
            />
            <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
            />
            <Title text="Resumen de ventas por día">
                <Subtitle text="(Número de ventas, Fecha)" />
            </Title>
            <Export enabled={true} />
            <Tooltip enabled={true} />
        </Chart>
    );
};

export default Analytics;