import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Lookup, Paging, Pager, Editing, Texts, SearchPanel, Form, RequiredRule, Popup, Button
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './userManagmentMastertools.styles.scss'
import { Item, Tab } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { getUserTools, getUser } from '../../features/Mastertools/mastertoolsSlice';
import { useAppDispatch } from '../../app/hooks'
import { instanceNode } from '../../axios/axiosConfig';




const UserManagmentMastertools = () => {

    const gridRef = useRef<DataGrid>(null);

    const dispatch = useAppDispatch()

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.userManagement_mastertools.id;


    const [authorized, setAuthorized] = useState<boolean>(false);

    let [visibleOffer, setVisibleOffer] = useState<boolean>(false)

    const Suscriptions = [
        {idSuscription: 1,
        subscription_name: "Basic"},

        {idSuscription: 2,
        subscription_name: "Plus"},

        {idSuscription: 3,
        subscription_name: "Trial"},

        {idSuscription:4,
        subscription_name: "Master"},

        {idSuscription: 5,
        subscription_name: "Lite"},
    ]

    const States = [
        {state: 0,
        state_name: "inactive"},

        {state: 1,
        state_name: "active"},

        {state: 2,
        state_name: "canceled"}
    ]

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    const history = useHistory()

    let goUserInformationMT = (e: any) =>{
        // console.log(e.row.data.email)
        dispatch(getUser(e.row.data.email))
        dispatch(getUserTools(e.row.data.email))
        history.push('/userInformationMastertools')
    }

    let goMasterTools = async(e:any) => {
        const request = await instanceNode.post('mastertools/bypassuser?email=' + e.row.data.email) 
        let win = window.open(`https://app.mastertools.com/login/${request.data.data}`, '_blank')
        win?.focus()
    }

    const gridDataSource = {
        store: createStore({
            key: "idUser",
            loadUrl: `${Config.url.apiNode}mastertools/users`,
            insertUrl: `${Config.url.apiNode}mastertools/users`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
                if (ajaxOptions.method === "PUT") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };
                if (ajaxOptions.method === "POST") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };
            },
        }),
    }

    const onRowUpdated = (e: any) => {
        if (e.data.result) {
            notify("Actualizado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }

    const onRowInserted = (e: any) => {
        if(!e.data.data.idUser){
            return notify(e.data.data.message, 'error');
        }
        if (e.data.result) {
            notify("Creado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }

    let onVisibleChange = (e:any) =>{
        try {
            if(e.value[0].data.gateway_payment === "PayTool"){
                setVisibleOffer(true)
            }
            else{
                setVisibleOffer(false)
            }
        } catch (error) {
        }
    }

    return (
        <>
            {
                authorized && authorized ? <div className="main-Affiliates-management-container">

                    <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Gestión de usuarios MasterTools</h1>
                                </div>
                            </div>
                        </div>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        keyExpr="idUser"
                        showBorders={true}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        onRowUpdated={onRowUpdated}
                        onRowInserted = {onRowInserted}
                        showRowLines={true}
                        height="85vh"
                        width="90vw"
                        onOptionChanged={onVisibleChange}
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="15" />
                        <Pager showInfo={true} />

                        <SearchPanel visible={"true"} placeholder={"Búsqueda"} searchVisibleColumnsOnly={"true"} highlightSearchText={"true"} width={"15vw"}/>

                        <Editing
                            mode="popup"
                            allowAdding={true}
                            
                        >
                            <Texts saveRowChanges="Crear Cuenta" cancelRowChanges="Cancelar"/>
                            <Popup title="Creación de cuentas MasterTools" showTitle={true} width={800} height={650}/>
                                <Form colCount={2}>
                                    <Item dataField="gateway_payment" caption="Procesador" isRequired={true} >
                                        <RequiredRule message="El procesador de pago es requerido" />
                                    </Item>
                                    <Item dataField="email" isRequired={true}>
                                        <RequiredRule message="El email es requerido" />
                                    </Item>
                                    <Item dataField="subscription_name" isRequired={true}>
                                        <RequiredRule message="El nombre del plan es requerido" />
                                    </Item>
                                    <Item visible={visibleOffer} dataField="offerPrice" helpText={"Solo en caso de querer cambiar el precio del plan"} isRequired={false} />
                                    <Item dataField="name" isRequired={true}>
                                        <RequiredRule message="El nombre es requerido" />
                                    </Item>
                                    <Item dataField="lastName" isRequired={true}>
                                        <RequiredRule message="El apellido es requerido" />
                                    </Item>
                                    <Item dataField="cellphone" isRequired={true}>
                                        <RequiredRule message="El Teléfono es requerido" />
                                    </Item>
                                    <Item dataField="typeSuscription" isRequired={true}>
                                        <RequiredRule message="La frecuencia es requerida" />
                                    </Item>
                                    <Item dataField="expired_time" isRequired={true}>
                                        <RequiredRule message="La fecha de expiración es requerida" />
                                    </Item>
                                    <Item dataField="transaction_code" isRequired={true}>
                                        <RequiredRule message="El código de transacción es requerido" />
                                    </Item>
                                    <Item dataField="sck" helpText="Solo en caso de que sea un estudiante" isRequired={false}/>
                                </Form>
                        </Editing>

                        <Column type="buttons" caption="Consultar" width={80}>
                            <Button text="Ir al Panel de Usuario" icon="info" onClick={goUserInformationMT}/>
                            <Button text="Ir al MasterTools" icon="arrowright" onClick={goMasterTools}/>
                        </Column>
                        <Column dataField="idUser" caption="Código de Suscripcion" dataType="number" alignment="center" visible={false}/>
                        <Column dataField="completeName" caption="Nombre Completo" dataType="string" alignment="center" width={"auto"}/>
                        <Column dataField="name" caption="Nombre" dataType="string" alignment="center" width={"auto"} visible={false}/>
                        <Column dataField="lastName" caption="Apellido" dataType="string" alignment="center" width={"auto"} visible={false}/>
                        <Column dataField="email" caption="Correo Electrónico" dataType="string" alignment="center"/>
                        <Column dataField="cellphone" caption="Teléfono" dataType="string" alignment="center" visible={false} />
                        <Column dataField="state" caption="Código de estado" dataType="number" alignment="center" visible={false}/>
                        <Column dataField="subscription_name" caption="Plan" dataType="string" alignment="center" >
                        <Lookup
                                    dataSource={Suscriptions}
                                    valueExpr="subscription_name"
                                    displayExpr="subscription_name"
                                    allowClearing={true}
                                />
                        </Column>
                        <Column dataField="typeSuscription" caption="Frecuencia" dataType="string" alignment="center">
                        <Lookup
                                    dataSource={["Mensual","Anual"]}
                                    allowClearing={true}
                                />
                        </Column>
                        <Column dataField="state_name" caption="Estado" dataType="string" alignment="center">
                        <Lookup
                                    dataSource={States}
                                    valueExpr="state_name"
                                    displayExpr="state_name"
                                    allowClearing={true}
                                />
                        </Column>
                        <Column dataField="expired_time" caption="Fecha de expiración" dataType="date" format="dd/MM/yy  hh:mm:ss" alignment="center"/>
                        <Column dataField="idSuscription" caption="Código de Suscripción" visible={false} dataType="number" alignment="center"/>
                        <Column dataField="idUserSuscription" caption="IdUserSuscription" dataType="number" alignment="center"/>
                        <Column dataField="careerUser" caption="Carrera" dataType="string" alignment="center"/>
                        <Column dataField="gateway_payment" caption="Procesador" dataType="string" alignment="center" visible={false}>
                        <Lookup
                                    dataSource={["Hotmart","PayTool"]}
                                    allowClearing={true}
                                />
                        </Column>
                        <Column dataField="offerPrice" caption="Precio" dataType="number" alignment="center" visible={false}/>
                        <Column dataField="transaction_code" caption="Código de transacción" dataType="string" alignment="center" visible={false}/>
                        <Column dataField="sck" caption="Tipo de Usuario" dataType="string" alignment="center" visible={false}>
                        <Lookup
                                    dataSource={["FORMULA DE LANZAMIENTO","AFILIADO MASTER"]}
                                    allowClearing={true}
                                />
                        </Column>
                    </DataGrid>
                </div> : <></>
            }
        </>
    )
}

export default UserManagmentMastertools