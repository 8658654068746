import React, { useContext, useEffect, useRef, useState } from 'react';
import "devextreme-react/text-area";
import { modules } from '../../shared/modules';
import notify from "devextreme/ui/notify";
import { AuthContext } from '../auth/components/context/auth-context';
import ComissionContainer from './comission-recovery-containers.component';
import './comission-recovery.styles.scss'

const Commission_recovery = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.commissionRecovery.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    const onRowUpdated = (e: any) => {
        notify(e.data.message);
    }

    return (
        <>
            {
                authorized && authorized ? <div className="commission-recovery-main-container">
                    <div className="grids-container">
                        <ComissionContainer />
                    </div>
                </div> : <></>
            }
        </>
    )
}

export default Commission_recovery;