import React, { useState } from 'react';
// import SideBar from '../../shared/components/sidebar/side-bar.component';
import Sidebar from '../sidebar/sidebar.component';
// import NavbarClassroom from '../navbar-classroom/navbar-classroom.component';
import Navbar from '../navbar/navbar.component';
import './main-layout.styles.scss';

type props = {
    children: React.ReactNode,
    path: string,
    navbarComponent: React.ReactNode,
    backgroundClass: string,
    contentMargin?: Boolean
}

const MainLayout = ({ children, path, navbarComponent, backgroundClass = "has-background-image-login is-back-layer", contentMargin = true }: props) => {
    let frontLayerClass = '', mainSectionClass = '';

    if (backgroundClass === 'has-custom-background') {
        frontLayerClass = "hero";
    } else {
        frontLayerClass = "is-front-layer hero";
    }

    if (contentMargin) {
        mainSectionClass = 'is-content-section';
    } else {
        mainSectionClass = 'is-class-content-section';
    }

    const [sidebarState, setSideBarState] = useState(true);

    const sideBarCollapse = () => {
        setSideBarState(!sidebarState);
    }

    const handlecollapse = (state: boolean) => {
        setSideBarState(state);
    }

    return (
        <div className={`${backgroundClass}`}>
            <div className={`${frontLayerClass} ${backgroundClass === 'is-support-layer' ? 'support-layer' : ''} `}>
                <Sidebar collapse={sidebarState} handlecollapse={handlecollapse} />
                <div className="is-home-container">
                    <Navbar sideBarCollapse={sideBarCollapse} visible={path === '/home' || path === '/powerclass'
                        || path === '/mentortalks' || path === '/shopping' || path === '/affiliationRequest' || path === '/certificationRequest'
                        || path === '/community' || path === '/career' || path === '/firesShop' || path === '/leadsToCall'
                        || path === '/callManagement' || path === '/salesAffiliates' || path === '/userManagement' || path === '/userRequest'
                        || path === '/certificationsGH' || path === '/affiliatesRewards'}
                        collapse={sidebarState} />
                    <div className={`${mainSectionClass} ${path.toLowerCase() === '/community' ? 'community-custom-class' : ''} ${path.toLowerCase() === '/powerclass' ? 'is-pclass-content-section' : ''}
                        ${path.toLowerCase() === '/mentortalks' ? 'is-mentor-content-section' : ''}
                        ${path.toLowerCase() === '/profile' ? 'is-profile-content-section' : ''} ${path.toLowerCase() === '/shopping' ? 'is-shopping-content-section' : ''}
                        ${path.toLowerCase() === '/support' ? 'is-support-content-section' : ''} ${path.toLowerCase() === '/firesshop' ? 'is-fire-store-content-section' : ''}
                        ${path.toLowerCase() === '/viralizationrequest' ? 'is-user-management-section' : ''} ${path.toLowerCase() === '/contentrequest' ? 'is-content-request-section' : ''}
                        ${path.toLowerCase() === '/affiliationRequest' ? 'is-affiliation-request-section' : ''} ${path.toLowerCase() === '/certificationRequest' ? 'is-certification-request-section' : ''}
                        ${path === '/userManagement' ? 'is-user-management-section' : ''} ${path.toLowerCase() === '/affiliatesmanagement' ? 'is-clients-management-section' : ''} ${path.toLowerCase() === '/userrequest' ? 'is-user-management-section' : ''}
                        ${path.toLowerCase() === '/certificationsgh' ? 'is-user-management-section' : ''} ${path.toLowerCase() === '/clientsmanagement' ? 'is-clients-management-section' : ''}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainLayout;