import React, { useContext, useEffect, useRef, useState } from 'react'
import DataGrid,
{
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging
} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Config } from '../../Config';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import { BiFontSize, BiSearchAlt2 } from 'react-icons/bi';
import { instanceNode } from '../../axios/axiosConfig';
import './paytool-transactions-detail.styles.scss'
import { Button, Modal } from 'react-bootstrap';
import './PaytoolTransactionDetail.modules.css'
import { useHistory } from 'react-router-dom';
import { Popup as PopUp } from 'devextreme-react/popup';
import { current } from 'devextreme/ui/themes';


const PaytoolTransactionsDetail = () => {
    
    const searchRef = useRef<HTMLInputElement>(null);
    const searchEmailRef = useRef<HTMLInputElement>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.transactionsDetailPaytool.id;
    
    const codigoTransaccion = sessionStorage.getItem("IDTransaction")
    
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string | null>(codigoTransaccion);
    const [searchEmail, setSearchEmail] = useState<string | null>("");

    let [currentUser, setCurrentUser] = useState<any>()
    const [popUpVisible, setPopUpVisible] = useState(false)
    const showPopUp = () =>{
    setPopUpVisible(true)
    }
    const hidePopUp = () =>{
    setPopUpVisible(false)
    }

    const history = useHistory()

    const goTransactions = (e: any)=>{
        e.preventDefault();
        history.push('/transactionsPaytool')
    }

    const goScheduledPayments = (e: any)=>{
        e.preventDefault();
        history.push('/scheduledPayments')
    }
    
    const gridDataSource = {
        store: createStore({
            key: "idTransaction",
            loadUrl: `${Config.url.apiNode}paytool/transaction-log?idTransaction=${searchValue}&email=${searchEmail}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
            },
        })
    }

  
    const fetchAffiliates = async () => {
        const respuesta = await instanceNode.get('paytool/transaction-log?idTransaction=' + codigoTransaccion + '&email=' + searchEmail) 
        return respuesta;
    }

    const changeSearch = (e: any) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            setSearchValue("");
            setSearchEmail("");
        }
    }

    const searchClick = () => {
        const valor = searchRef.current?.value;
        if (valor === "" || valor === undefined) {
            setSearchValue("");
            setSearchEmail("");
        } else {
            setSearchEmail("")
            setSearchValue(valor);
        }
        console.log(searchRef.current?.value);
    }

    const changeEmailSearch = (e: any) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            setSearchValue("");
            setSearchEmail("")
        }
    }

    const searchEmailClick = () => {
        const valor = searchEmailRef.current?.value;
        if (valor === "" || valor === undefined) {
            setSearchValue("");
            setSearchEmail("");
        } else {
            setSearchValue("")
            setSearchEmail(valor);
        }
        console.log(searchEmailRef.current?.value);
    }

    const Link = (data: any) => {
        return data.value ? 
        <>
         <Button variant="outline-warning" size="sm" onClick={()=>{
            console.log(typeof(data.value))
            let usuario = `${data.value}`
            setCurrentUser(usuario)
            showPopUp() 
        }}>
        {data.value ? "Detalle" : null}
        </Button>
      </>
      : <> Sin Detalle </>   
    }

    useEffect(() => {
        fetchAffiliates();
    }, []);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    return (
        <>
            {
                authorized && authorized ?
                    <div className="main-Affiliates-management-container">
                        <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Detalle de Transacciones de Paytool</h1>
                                </div>
                            </div>
                        </div>

                        

                        <div className="search">
                            <Button className = "buttonVolver" variant="outline-warning" size="sm" onClick={goTransactions}>Ir a transacciones</Button>
                            <Button className = "buttonVolver" variant="outline-warning" size="sm" onClick={goScheduledPayments}>Ir a Pagos Programados</Button>
                            <label className="searchLabel">Búsqueda por Email:</label>
                            <input type="text" name="search" placeholder="Email" autoComplete="off" ref={searchEmailRef} onChange={changeEmailSearch} />
                            <div className="searchButton" onClick={searchEmailClick} > <BiSearchAlt2 className="search-icon" /> </div>
                            <label className="searchLabel">Búsqueda por ID:</label>
                            <input type="text" name="search" id="search" placeholder="Codigo de transacción" autoComplete="off" ref={searchRef} onChange={changeSearch} />
                            <div className="searchButton" onClick={searchClick} > <BiSearchAlt2 className="search-icon" /> </div>
                        </div>

                        <PopUp
                        onShown={()=>console.log(currentUser)}
                        visible={popUpVisible}
                        onHiding={hidePopUp}
                        showTitle={true}
                        title="Respuesta"
                        width={1200}
                        height={500}
                        >
                         
                         <p style={{fontSize:"0.9rem"}}>{currentUser}</p>
                        
                        </PopUp>


                        <DataGrid
                            id="grid-container-affiliations"
                            dataSource={gridDataSource}
                            keyExpr="idTransaction"
                            showBorders={true}
                            showRowLines={true}
                            focusedRowEnabled={true}
                            columnAutoWidth={true}
                            height="74vh"
                            width="95vw"
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize={9} />
                            <Pager showInfo={true} />

                            <Column dataField="idTransaction" caption="Código de transacción" allowSearch={true} visible={true} alignment="center"/>
                            <Column dataField="email" caption="Correo Electrónico" alignment="center"/>
                            <Column dataField="actionType" caption="Acción realizada" alignment="center"/>
                            <Column dataField="objectRe" caption="ObjectRe" cellRender={Link} alignment="center"/>
                            <Column dataField="objectResp" caption="ObjectResp" cellRender={Link} alignment="center"/>
                            <Column dataField="source" caption="Origen" alignment="center"/>
                            <Column dataField="time" caption="Tiempo de transacción" alignment="center"/>
                        </DataGrid>
                    </div> : <></>
            }
        </>
    );
}

export default PaytoolTransactionsDetail