import React from 'react';
import { MdClose } from 'react-icons/md';
import { GoPrimitiveDot } from "react-icons/go";

import './noti-card.styles.scss';

type props = {
    nData?: any,
    deleteHandleClick: React.MouseEventHandler
}

const NotiCard = ({ nData, deleteHandleClick }: props) => {

    const handleNotificationClick = () => {
        if (nData.url !== null && nData.url !== "#" && nData.url !== "") {
            window.open(nData.url, nData.targetUrl);
        }
    }

    return (
        <div
            className="is-noti-card is-size-7 pl-2 has-text-black is-flex"
        >
            <p key={nData} onClick={handleNotificationClick} className="is-noti-text" >{nData.text}</p>
            <div className="buttons-section is-flex mr-3" >
                <div className="close-icon" onClick={deleteHandleClick}>
                    <MdClose />
                </div>
                <div className="notification-state" style={{ display: nData.viewed === 0 ? '' : 'none' }}>
                    <GoPrimitiveDot />
                </div>
                <div className="fill-space"></div>
            </div>
        </div>
    );
};


export default NotiCard;