import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup, RequiredRule, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import React, { useContext, useEffect, useState } from 'react'
import { Config } from '../../Config';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import MasterDetailCareer from './master-detail-career-bm.component';

const gridDataSource = {
    store: createStore({
        key: "idCareer",
        // insertUrl: `${Config.url.api}/ad/getCareers/`,
        // updateUrl: `${Config.url.api}/escalation/UpdateTicket/Mastertools`,
        loadUrl: `${Config.url.api}/ad/getCareers`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
}

const ClassManagement_bm = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.classManagement_bm.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    return (
        <>
            {
                authorized && authorized ?
                    <div className="class-management-bm-main-container">
                        <h1 className="is-size-5 m-5 center">Gestión de clases</h1>

                        <DataGrid
                            dataSource={gridDataSource}
                            showBorders={true}
                            keyExpr="idCareer"
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            focusedRowEnabled={true}
                            showRowLines={true}
                            height="88vh"
                            width="95vw"
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize="20" />
                            <Pager showInfo={true} />

                            <Column dataField="idCareer" caption="idCareer" dataType="number" visible={false} />
                            <Column dataField="name" caption="Nombre de la carrera" dataType="string" />
                            <Column dataField="enabled" caption="Estado" dataType="string" filterValue={1} >
                                <Lookup
                                    dataSource={[{ id: 0, estado: "Inactivo" }, { id: 1, estado: "Activo" }]}
                                    valueExpr="id"
                                    displayExpr="estado"
                                    allowClearing={true}
                                />
                            </Column>

                            <MasterDetail
                                enabled={true}
                                component={MasterDetailCareer}
                            />
                        </DataGrid>
                    </div> : <></>
            }
        </>
    )
}

export default ClassManagement_bm;