import AxiosConfig from '../../axios/axiosConfig';
import CustomStore from "devextreme/data/custom_store";

export const states = [
    {
        value: "pendiente",
        name: "Pendiente"
    },
    {
        value: "en proceso",
        name: "En proceso"
    },
    {
        value: "escalamiento",
        name: "Escalamiento"
    },
    {
        value: "solucionado",
        name: "Solucionado"
    },
]

export const products_bemaster = {
    store: new CustomStore({
        key: "idProduct",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get('/Values/GetProducts/enabled')
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    }),
    sort: "name",
    filter: ['escalationTable', '=', "BeMaster"]
}