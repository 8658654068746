import React from 'react';
import { Switch } from 'react-router-dom';
import { AuthProvider } from '../components/auth/components/context/auth-context';
import Begining from '../components/begining/begining.component';
import Home from '../components/home/home.component';
import Navbar from '../components/navbar/navbar.component';
import AffiliationRequest from '../components/affiliation-request/affiliation-request.component';
import AppAuth from './AppAuth';
import CertificationRequest from '../components/certification-request/certification-request.component';
import LeadsToCall from '../components/leads-to-call/leads-to-call.component';
import CallManagement from '../components/call-management/call-management.component';
import DashboardCE from '../components/dashboard/dashboard.component';
import contentRequest from '../components/content-request/content-request.component';
import Login from '../components/auth/components/login/login.component';
import SalesAffiliates from '../components/sales-affiliates/sales-affiliates.component';
// import viralizationRequest from '../components/viralization-request/viralization-request.component';
import userManagement from '../components/user-management/user-management.component';
import userRequest from '../components/user-Request/user-Request.component';
import AffiliatesManagement from '../components/affiliates-management/affiliates-management.component';
import userCertification from '../components/user-certifications/user-certifications.component';
import AffiliatesRewards from '../components/affiliates-rewards/Affiliates-rewards.component';
import Leads_management from '../components/leadsManagement/leads-management.component';
import LeadsRefund from '../components/refunds-to-call/leads-refunds.component';
import AdsManagement from '../components/ads-management/ads-management.component';
import contentManagement from '../components/powerclass-management-bm/powerclass-management-bm.component';
import UserManagement_BM from '../components/user-management-bm/user-management-bm.component';
import DelayedToCall from '../components/delayed-to-call/delayed-to-call.component';
import EscalationManagement_mastertools from '../components/escalation-management/mastertools/escalation-management-mastertools.component';
import EscalationManagement_comizzion from '../components/escalation-management/comizzion/escalation-management-comizzion.component';
import certificateDnsComponent from '../components/certificate-dns-management/certificate-dns.component';
import EscalationManagement_bemaster from '../components/escalation-management/bemaster/escalation-management-bemaster.component';
import Commission_recovery from '../components/commission-recovery/commission-recovery.component';
import Notification_bm_management from '../components/notification-bm-management/notification-bm-management.component';
import ClassManagement_bm from '../components/class-management-bm/class-management-bm.component';
import UserManagement_Comizzion from '../components/user-management-comizzion/user-management-comizzion.component';
import UserManagement_Mastertools from '../components/user-management-mastertools/user-management-mastertools.component';
import AlternativePayment from '../components/alternative-payment/alternative-payment.component';
import Rol_Management from '../components/rol-management/rol-management.component';
import ProductComizzion_Management from '../components/product-management-comizzion/product-managment-comizzion.component';
import ConfirmedRefund from '../components/confirmed-refund/confirmed-refund.component';
import CancelationsMastertools from '../components/cancelation-mastertools/cancelation-mastertools';
import TransactionsHotmart from '../components/transactions/transactions.component';
import PaytoolTransactions from '../components/paytool/PaytoolTransactions';
import PaytoolTransactionsDetail from '../components/paytool/PaytoolTransactionsDetail';
import ScheduledPayments from '../components/paytool/ScheduledPayments';
import AccountsEmailTool from '../components/emailtool/AccountsEmailTool';
import Subscriptions from '../components/comizzion-subscriptions/Subscriptions';
import UserInformationMT from '../components/user-information-mastertools/UserInformationMT';
import Questions from '../components/copytool/Questions';
import Triggers from '../components/copytool/Triggers'
import Templates from '../components/copytool/Templates';
import HighTicket from '../components/highTicket/HighTicket';
import UpdateSubscription from '../components/updateSubscription/UpdateSubscription';

const AppRouter = () => {
    return (
        <AuthProvider>
            <Switch>
                <AppAuth path='/login' Comp={Login} hasLayout={false} NavbarComponent={Navbar} backgroundClass={'has-background-image'} />
                <AppAuth path='/begin' Comp={Begining} hasLayout={false} NavbarComponent={Navbar} backgroundClass={'has-background-image'} />
                <AppAuth path='/home' Comp={Home} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-background-image-home'}
                    breadcrumb={"INICIO"} />
                <AppAuth path='/affiliationRequest' Comp={AffiliationRequest} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Afiliaciones"} />
                <AppAuth path='/certificationRequest' Comp={CertificationRequest} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Certificados"} />
                <AppAuth path='/leadsToCall' Comp={LeadsToCall} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Leads To Call"} />
                <AppAuth path='/callManagement' Comp={CallManagement} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Llamadas"} />
                <AppAuth path='/contentRequest' Comp={contentRequest} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Solicitud de contenido"} />
                {/* <AppAuth path='/viralizationRequest' Comp={viralizationRequest} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Solicitud de viralización"} /> */}
                <AppAuth path='/userManagement' Comp={userManagement} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de usuarios"} />
                <AppAuth path='/userRequest' Comp={userRequest} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Solicitudes del usuario"} />
                <AppAuth path='/certificationsGH' Comp={userCertification} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Solicitudes del usuario"} />
                <AppAuth path='/dashboardCE' Comp={DashboardCE} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"DASHBOARD"} />
                <AppAuth path='/salesAffiliates' Comp={SalesAffiliates} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Ventas Afiliados"} />
                <AppAuth path='/affiliatesManagement' Comp={AffiliatesManagement} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Afiliados"} />
                <AppAuth path='/affiliatesRewards' Comp={AffiliatesRewards} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Recompensas Afiliados"} />
                <AppAuth path='/clientsManagement' Comp={Leads_management} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de estudiantes"} />
                <AppAuth path='/delayedToCall' Comp={DelayedToCall} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Pagos Atrasados"} />
                <AppAuth path='/refundsToCall' Comp={LeadsRefund} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Reembolsos"} />
                <AppAuth path='/adManagement_bm' Comp={AdsManagement} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Anuncios BeMaster"} />
                <AppAuth path='/powerclassManagement_bm' Comp={contentManagement} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Contenido BeMaster"} />
                <AppAuth path='/userManagement_bm' Comp={UserManagement_BM} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Usuarios BeMaster"} />
                <AppAuth path='/escalationMastertools' Comp={EscalationManagement_mastertools} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Escalamiento MasterTools"} />
                <AppAuth path='/escalationComizzion' Comp={EscalationManagement_comizzion} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Escalamiento Comizzion"} />
                <AppAuth path='/escalationBeMaster' Comp={EscalationManagement_bemaster} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de Escalamiento Comizzion"} />
                <AppAuth path='/certificationDomain' Comp={certificateDnsComponent} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de certificados de seguridad para Pixels de Comizzion"} />
                <AppAuth path='/commissionRecovery' Comp={Commission_recovery} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Módulo de recuperación de comisiones de afiliados"} />
                <AppAuth path='/notificationBM' Comp={Notification_bm_management} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"creación masiva de notificaciones BeMaster"} />
                <AppAuth path='/classesManagement_bm' Comp={ClassManagement_bm} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"creación masiva de notificaciones BeMaster"} />
                <AppAuth path='/userManagement_comizzion' Comp={UserManagement_Comizzion} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de usuarios plataforma Comizzion"} />
                <AppAuth path='/userManagement_mastertools' Comp={UserManagement_Mastertools} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de usuarios plataforma Comizzion"} />
                <AppAuth path='/alternativePayment' Comp={AlternativePayment} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Registro de pagos alternativos"} />
                <AppAuth path='/moduleRolManagement' Comp={Rol_Management} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Administración de permisos por rol"} />
                <AppAuth path='/productComizzion' Comp={ProductComizzion_Management} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de productos para Comizzion"} />
                <AppAuth path='/confirmedRefund' Comp={ConfirmedRefund} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de comunidades para reembolsos confirmados"} />
                <AppAuth path='/cancelationsMastertools' Comp={CancelationsMastertools} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de comunidades para reembolsos confirmados"} />
                <AppAuth path='/transactionsHotmart' Comp={TransactionsHotmart} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestión de comunidades para reembolsos confirmados"} />
                <AppAuth path='/transactionsPaytool' Comp={PaytoolTransactions} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestion de transacciones Paytool"} />
                <AppAuth path='/transactionsDetailPaytool' Comp={PaytoolTransactionsDetail} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestion de detalle de transacciones Paytool"} />
                <AppAuth path='/scheduledPayments' Comp={ScheduledPayments} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Gestion de detalle de transacciones Paytool"} />
                <AppAuth path='/accountsEmailTool' Comp={AccountsEmailTool} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Listado de cuentas EmailTool"} />
                <AppAuth path='/subscriptions' Comp={Subscriptions} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Suscripciones Comizzion"} />
                <AppAuth path='/userInformationMastertools' Comp={UserInformationMT} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Información de usuario Mastertools"} />
                <AppAuth path='/questionsCopyTool' Comp={Questions} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Preguntas de Copytool"} />
                <AppAuth path='/triggersCopyTool' Comp={Triggers} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Triggers de Copytool"} />
                <AppAuth path='/templatesCopyTool' Comp={Templates} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Templates de Copytool"} />n
                <AppAuth path='/hightickets' Comp={HighTicket} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"High Tickets MasterShop"} />
                <AppAuth path='/updateSubscription' Comp={UpdateSubscription} hasLayout={true} NavbarComponent={Navbar} backgroundClass={'has-custom-background'}
                    breadcrumb={"Actualización de Subscripciones"} />
                <AppAuth path='/' Comp={Login} hasLayout={false} NavbarComponent={Navbar} backgroundClass={'has-background-image'} />
            </Switch>
        </AuthProvider>
    );
};

export default AppRouter;