import React from 'react'
import UserAffiliationTemplate from './user-affiliation-template.component';


const MasterDetailUser_comizzion = (props: any) => {
    return(
        <UserAffiliationTemplate props={props} />
    )
}

export default MasterDetailUser_comizzion;