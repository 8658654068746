import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import notify from "devextreme/ui/notify";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Editing,
    Lookup, Paging, Pager, Button, RequiredRule, Form, Popup, Export
} from "devextreme-react/data-grid";
import { fetchTypeContracts, fetchRoles, fetchEmployment } from './user-management.data';
import { Config } from "../../Config";
import { Item } from 'devextreme-react/form';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './user-management.styles.scss';

const gridDataSource = {
    store: createStore({
        key: "idUser",
        insertUrl: `${Config.url.api}/UserManagement/addUser`,
        updateUrl: `${Config.url.api}/UserManagement/UpdateUser`,
        loadUrl: `${Config.url.api}/UserManagement/GetUser`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    })
}

const UserManagement = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.userManagement.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const gridRef = useRef<DataGrid>(null);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    const displayRoles = (e: any) => {
        const element = `${e.roleType} ${e.role1}`
        return element
    }

    const updatedRow = (e: any) => {
        if (e.data.result === "error") {
            const innerEx = e.data.data.InnerException.InnerException.Message;
            if (innerEx.includes("Duplicate")) {
                notify('El correo electrónico ya se encuentra registrado', 'error');
            } else {
                notify('Ha ocurrido un error mientras actualizabas el usuario', 'error');
            }
        } else {
            notify(e.data.message ? e.data.message : "Actualizado con éxito");
        }
    }

    const insertedRow = (e: any) => {
        if (e.data.result === "error") {
            const innerEx = e.data.data.InnerException.InnerException.Message;
            if (innerEx.includes("Duplicate")) {
                notify('El correo electrónico ya se encuentra registrado', 'error');
            } else {
                notify('Ha ocurrido un error mientras actualizabas el usuario', 'error');
            }
        } else {
            notify(e.data.message ? e.data.message : "Actualizado con éxito");
        }
    }

    return (
        <>
            {
                authorized && authorized ?

                    <div className="main-user-management-container">

                        <div className="banner">
                            <div className="banner-overlayUM">
                                <div className="banner-img">
                                    <h1>Gestión de Usuarios</h1>
                                </div>
                            </div>
                        </div>

                        <DataGrid
                            id="dataGrid"
                            ref={gridRef}
                            dataSource={gridDataSource}
                            showBorders={true}
                            keyExpr="idUser"
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            focusedRowEnabled={true}
                            onRowInserted={insertedRow}
                            onRowUpdated={updatedRow}
                            showRowLines={true}
                            height="75vh"
                            width="94.9vw"
                        >
                            <Export enabled={true} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} defaultPageSize={20} />
                            <Pager showInfo={true} />

                            <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                                <Popup title="User Info" showTitle={true} width="80%" height='95%' />
                                <Form>
                                    <Item itemType="group" colCount={3} colSpan={2}>
                                        <Item dataField="dni" isRequired={true} />
                                        <Item dataField="firstName" isRequired={true} />
                                        <Item dataField="lastName" isRequired={true} />
                                        <Item dataField="email" isRequired={true} />
                                        <Item dataField="addres" />
                                        <Item dataField="phone" />
                                        <Item dataField="country" />
                                        <Item dataField="city" />
                                        <Item dataField="gender" />
                                        <Item dataField="birthday" isRequired={true} />
                                        <Item dataField="status" isRequired={true} />
                                    </Item>
                                    <Item itemType="group" caption="Contract Info" colCount={3} colSpan={2}>
                                        <Item dataField="idRole" isRequired={true} />
                                        <Item dataField="idTypeContract" isRequired={true} />
                                        <Item dataField="sinceDateContract" isRequired={true} />
                                        <Item dataField="idEmployment" isRequired={true} />
                                        <Item dataField="salary" isRequired={true} />
                                        <Item dataField="endDateContract" />
                                    </Item>
                                </Form>
                            </Editing>

                            <Column type="buttons" width={"auto"}>
                                <Button name="edit" text="Editar" visible={true} />
                            </Column>
                            <Column dataField="idUser" caption="idUser" dataType="int" visible={false} />
                            <Column dataField="dni" caption="Número de identificación" dataType="string" visible={true} >
                            </Column>
                            <Column dataField="firstName" caption="Nombres" dataType="string" allowEditing={true} defaultSortOrder="asc" width="200" >
                            </Column>
                            <Column dataField="lastName" caption="Apellidos" dataType="string" allowEditing={true} width="200" >
                            </Column>
                            <Column dataField="status" caption="Estado" allowEditing={true} >
                                <Lookup
                                    dataSource={[{ "status": 0, "name": "Inactivo" }, { "status": 1, "name": "Activo" }]}
                                    valueExpr="status"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="email" caption="Correo Electronico" dataType="string" allowEditing={true} >
                            </Column>
                            <Column dataField="addres" caption="Dirección" allowEditing={true} />
                            <Column dataField="phone" caption="Teléfono" dataType="string" allowEditing={true} />
                            <Column dataField="country" caption="País" dataType="string" allowEditing={true} />
                            <Column dataField="city" caption="Ciudad" dataType="string" allowEditing={true} />
                            <Column dataField="gender" caption="Género" dataType="string" allowEditing={true} />
                            <Column alignment="center" dataField="birthday" caption="Cumpleaños" dataType="datetime" allowEditing={true}
                                format="yyyy-MM-dd" >
                            </Column>
                            <Column dataField="idRole" caption="Rol de Usuario" allowEditing={true} >
                                <Lookup
                                    dataSource={fetchRoles}
                                    valueExpr="idRole"
                                    displayExpr={displayRoles}
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="idTypeContract" caption="Tipo de Contrato" allowEditing={true} >
                                <Lookup
                                    dataSource={fetchTypeContracts}
                                    valueExpr="idTypeContract"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column alignment="center" dataField="sinceDateContract" caption="Inicio del contrato" dataType="datetime" allowEditing={true}
                                format="yyyy-MM-dd" >
                            </Column>
                            <Column alignment="center" dataField="endDateContract" caption="fin del contrato" dataType="datetime" allowEditing={true}
                                format="yyyy-MM-dd" />
                            <Column dataField="idEmployment" caption="Cargo" dataType="number" width="250px" allowEditing={true} >
                                <Lookup
                                    dataSource={fetchEmployment}
                                    valueExpr="idEmployment"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="salary" caption="Salario" dataType="number" allowEditing={true} >
                            </Column>
                        </DataGrid>
                    </div> : <></>
            }
        </>
    )
};

export default UserManagement;