import React from 'react';
import AxiosConfig from "../../axios/axiosConfig";
import CustomStore from 'devextreme/data/custom_store';

export const contentSource = (e) => {
    return {
        store: new CustomStore({
            key: "idContent",
            loadMode: "raw",
            load: () => {
                return AxiosConfig.get('/ContentBm/GetContent/0')
                    .then(response => {
                        return response.data;
                    }).catch(e => {
                        console.error(e);
                    });
            }
        }),
        sort: "idContent",
        filter: e.data ? ['idContentCategory', '=', e.data] : null
    }
}

export const moduleClassSource = {
    store: new CustomStore({
        key: "idModuleClass",
        loadMode: "raw",
        load: () => {
            return AxiosConfig.get('/ContentBm/GetModuleClass')
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                });
        }
    })
}

export const addContentBm = async (data) => {

    const result = await AxiosConfig.post('/ContentBm/InsertContent', {
        type: data.type,
        order: data.order,
        name: data.name,
        idNicho: null,
        value: data.value,
        category: data.category,
        url: data.url,
        idContentParent: null,
        idCareer: data.idCareer,
        categoryOrder: data.categoryOrder,
        idContentCategory: data.idContentCategory,
        description: data.description,
        idModuleClass: data.idModuleClass,
        idNextVideo: data.idNextVideo > 0 && data.idNextVideo !== '' ? data.idNextVideo : null
    });

    return result;
}

export const editContentBm = async (data) => {

    const result = await AxiosConfig.put('/ContentBm/UpdateContent', {
        idContent: data.idContent,
        type: data.type,
        order: data.order,
        name: data.name,
        value: data.value,
        category: data.category,
        url: data.url,
        idCareer: data.idCareer,
        categoryOrder: data.categoryOrder,
        idContentCategory: data.idContentCategory,
        description: data.description,
        idModuleClass: data.idModuleClass,
        idNextVideo: data.idNextVideo
    });

    return result;
}

export const renderNextVideo = (data) => {

    return (

        <div style={{ display: 'inline-block' }}>{data.name} {data.category}</div>

    );

}