import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Config } from '../../Config';
import Scrollbar from 'react-scrollbars-custom';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, Pager, Paging, Popup, RequiredRule, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { careersDataSource, onValueChanged } from './ads-management.data';
import FileUploader from 'devextreme-react/file-uploader';
import notify from 'devextreme/ui/notify';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';

const scrollHeight = window.innerHeight - 20;
const scrollWidth = window.innerWidth - 65;

const gridDataSource = {
    store: createStore({
        key: "idAd",
        loadUrl: `${Config.url.api}/ad/GetAds`,
        insertUrl: `${Config.url.api}/ad/InsertAd`,
        updateUrl: `${Config.url.api}/ad/updateAd`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
};

const AdsManagement = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.adManagementBm.id;

    const gridRef = useRef<DataGrid>(null);

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [stateData, setStateData] = useState<string>("");
    const [uploadChanges, setUploadChanges] = useState<any>({

    });

    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
            // console.error("error subiendo archivo", e.error.responseText);
            notify("error subiendo archivo", e.error.responseText);
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
            console.error("error en la conexión", e.error.responseText);
            notify('error en la conexión', e.error.responseText);
        }
    }, []);

    const uploadedFile = (e: any) => {
        let xhttp = e.request;
        notify('uploaded successfully');
    }

    const onEditingStart = (e: any) => {
        setUploadChanges(e.data)
        setStateData("editing");
    }

    const onNewRow = () => {
        setStateData("adding");
    }

    const renderURL = (data: any) => {
        if (data.value) {
            return <a href={data.value} target="_blank" rel="noopener noreferrer" > Ir a link </a>;
        } else {
            return <>sin Link</>
        }
    }

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);


    return (
        <>
            {
                authorized && authorized ?
                    <div className="ads-management-main-container">
                        
                        <h1 className="is-size-5 m-5 center tittle-delayed">Gestión de Noticias, eventos y clasificados</h1>

                        <div className="ads-management-container-grid">

                            <DataGrid
                                ref={gridRef}
                                dataSource={gridDataSource}
                                showBorders={true}
                                keyExpr="idAd"
                                allowColumnReordering={true}
                                columnAutoWidth={true}
                                focusedRowEnabled={true}
                                showRowLines={true}
                                height="90vh"
                                width="95vw"
                                onEditingStart={onEditingStart}
                                onInitNewRow={onNewRow}
                            >
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                <Paging enabled={true} pageSize="20" />
                                <Pager showInfo={true} />

                                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                                    <Texts saveRowChanges="Guardar" />
                                    <Popup
                                        title="Noticias, eventos y clasificados"
                                        showTitle="true"
                                        width={700}
                                        height={525}
                                    />
                                    <Form>
                                        <Item itemType="group" colCount={2} colSpan={2}>
                                            <Item dataField="idCareer" />
                                            <Item dataField="location" isRequired={true}>
                                                <RequiredRule message="debes indicar que tipo de publicación es" />
                                            </Item>
                                            <Item dataField="url" />
                                            <Item dataField="publishDate" isRequired={true} >
                                                <RequiredRule message="Debes indicar una fecha de publicación" />
                                            </Item>
                                            <Item dataField="expiryDate" isRequired={true} >
                                                <RequiredRule message="Debes indicar una fecha de Expiración" />
                                            </Item>
                                            <Item dataField="targetUrl" />
                                            <Item dataField="date" isRequired={true} >
                                                <RequiredRule message="Debes indicar una fecha para el evento" />
                                            </Item>
                                            <Item dataField="accessType" />
                                        </Item>
                                        <Item
                                            itemType="group"
                                            colCount={2}
                                            colSpan={2}
                                        >
                                            <Item dataField="description" editorType="dxTextArea" colSpan={2}
                                                editorOptions={{ height: 100 }} isRequired={true}>
                                                <RequiredRule message="Debes agregar una descripción" />
                                            </Item>
                                        </Item>
                                        <Item
                                            itemType="group"
                                            colCount={2}
                                            colSpan={2}
                                            caption="Imagen de escritorio"
                                            visible={stateData === "editing" ? true : false}
                                        >
                                            <FileUploader
                                                accept="image/png"
                                                multiple={false}
                                                uploadMode="useButtons"
                                                uploadUrl={
                                                    `${Config.url.api}/ad/uploadAdImage`
                                                }
                                                invalidFileExtensionMessage="Only PNG files is accepted"
                                                invalidMaxFileSizeMessage="File is too large"
                                                labelText="Select your PNG file"
                                                maxFileSize={602000}
                                                uploadHeaders={
                                                    {
                                                        'Authorization': localStorage.getItem('token'),
                                                        'keyRequest': uploadChanges.idAd,
                                                        'location': uploadChanges.location,
                                                        'format': 'desktop'
                                                    }
                                                }
                                                onValueChanged={onValueChanged}
                                                onUploadError={onUploadError}
                                                onUploaded={uploadedFile}
                                            />
                                        </Item>
                                        <Item
                                            itemType="group"
                                            colCount={2}
                                            colSpan={2}
                                            caption="Imagen de móvil"
                                            visible={stateData === "editing" ? uploadChanges.location === "News" || uploadChanges.location === "Events" : false}
                                        >
                                            <FileUploader
                                                accept="image/png"
                                                multiple={false}
                                                uploadMode="useButtons"
                                                uploadUrl={
                                                    `${Config.url.api}/ad/uploadAdImage`
                                                }
                                                invalidFileExtensionMessage="Only PNG files is accepted"
                                                invalidMaxFileSizeMessage="File is too large"
                                                labelText="Select your PNG file"
                                                maxFileSize={652000}
                                                uploadHeaders={
                                                    {
                                                        'Authorization': localStorage.getItem('token'),
                                                        'keyRequest': uploadChanges.idAd,
                                                        'location': uploadChanges.location,
                                                        'format': 'mobile'
                                                    }
                                                }
                                                onValueChanged={onValueChanged}
                                                onUploadError={onUploadError}
                                                onUploaded={uploadedFile}
                                            />
                                        </Item>
                                    </Form>
                                </Editing>

                                <Column type="buttons" caption="Options" >
                                    <Button name="edit" text="Editar" />
                                </Column>
                                <Column dataField="idAd" caption="idAd" dataType="int" visible={false} />
                                <Column dataField="idCareer" caption="Producto" dataType="string" >
                                    <Lookup
                                        dataSource={careersDataSource}
                                        valueExpr="idCareer"
                                        displayExpr="name"
                                        allowClearing={true}
                                    />
                                </Column>
                                <Column dataField="description" width="250" caption="Descripción" dataType="string" />
                                <Column dataField="location" caption="Ubicación" dataType="string" >
                                    <Lookup
                                        dataSource={[{ "valor": "News", "text": "Noticias" }, { "valor": "Events", "text": "Eventos" }, { "valor": "Classified", "text": "Clasificados" }]}
                                        valueExpr="valor"
                                        displayExpr="text"
                                    />
                                </Column>
                                <Column dataField="url" width="250" caption="Url destino" dataType="string" cellRender={renderURL} />
                                <Column alignment="center" dataField="publishDate" caption="Fecha de publicación" dataType="datetime"
                                    format="yyyy-MM-dd" sortIndex={0} sortOrder={"desc"} />
                                <Column alignment="center" dataField="expiryDate" caption="Fecha de expiración" dataType="datetime"
                                    format="yyyy-MM-dd" />
                                <Column dataField="targetUrl" caption="Target URL" dataType="string" >
                                    <Lookup
                                        dataSource={[{ "valor": "_self", "text": "Pestaña actual" }, { "valor": "_blank", "text": "Pestaña nueva" }]}
                                        valueExpr="valor"
                                        displayExpr="text"
                                    />
                                </Column>
                                <Column alignment="center" dataField="date" caption="Fecha del evento" dataType="datetime"
                                    format="yyyy-MM-dd" />
                                <Column dataField="accessType" caption="Tipo de Acceso" dataType="string" >
                                    <Lookup
                                        dataSource={[{ "valor": "estudiantes|masters", "text": "Estudiantes y maestros" }]}
                                        valueExpr="valor"
                                        displayExpr="text"
                                    />
                                </Column>
                            </DataGrid>
                        </div>
                    </div> : <></>
            }
        </>
    );
}

export default AdsManagement;