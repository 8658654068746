import AxiosConfig from "../../axios/axiosConfig";
import CustomStore from "devextreme/data/custom_store";

export const classMissionDataSource = {
	store: new CustomStore({
		key: "idClassMission",
		loadMode: "raw",
		cacheRawData: true,
		load: () => {
			return AxiosConfig.get("/userBm/GetClassMissionBm")
				.then((response) => {
					return response.data;
				})
				.catch((e) => {
					console.error(e);
				});
		},
	}),
	sort: "idClassMission",
};

export const awardsDataSource = {
	store: new CustomStore({
		key: "idAward",
		loadMode: "raw",
		cacheRawData: true,
		load: () => {
			return AxiosConfig.get("/userBm/GetAwardBm")
				.then((response) => {
					return response.data;
				})
				.catch((e) => {
					console.error(e);
				});
		},
	}),
	sort: "name",
};