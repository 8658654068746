import AxiosConfig from '../../axios/axiosConfig';
import CustomStore from "devextreme/data/custom_store";

export const formatDataSource = (e) => {
    return {
        store: new CustomStore({
            key: "idFormat",
            loadMode: "raw",
            cacheRawData: true,
            load: () => {
                return AxiosConfig.get('/Content/GetFormats')
                    .then(response => {
                        return response.data.data;
                    }).catch(e => {
                        console.error(e);
                    });
            }
        }),
        sort: "idChannel",
        filter: e.data ? ['idChannel', '=', e.data.idChannel] : null
    }
}

export const channelsDataSource = {
    store: new CustomStore({
        key: "idChannel",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get('/Content/GetChannels')
                .then(response => {
                    return response.data.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    }),
    sort: "idChannel"
}

export const contenidos = [
    {
        id: 1,
        tipoContenido: 'Audiovisual'
    },
    {
        id: 2,
        tipoContenido: 'Gráfico'
    },
    {
        id: 3,
        tipoContenido: 'Reserva de Estudio'
    }
]

//Token para generar Solicitudes
const tokenAsana = "1/1167147957078056:0283a5569e1c0f17e0047f1c07225428";
//id Usuario Creacion de Contenido
const idContentUser = "1200513974569154";
//id WorkSpace privilegeteam.com
const idWorkSpace = "1167148865781243";


const asana = require('asana');
const client = asana.Client.create().useAccessToken(tokenAsana);

export const formatDate = (fecha) => {

    const año = fecha.getFullYear();

    const mes = fecha.getMonth() + 1 < 10 ? `0${fecha.getMonth() + 1}` : fecha.getMonth() + 1;

    const dia = fecha.getDate() < 10 ? `0${fecha.getDate()}` : fecha.getDate();

    const date = `${año}-${mes}-${dia}`;

    return date;
};

export const validateContent = async (data, setGidProject) => {

    switch (data.typeContent) {
        case "Audiovisual":
            data.gids = {
                project: "1200500087655811",
                customField_estado: "1200501907015543",
                // customField_prioridad: "1200488290969450",
                customField_team: "1200502659180083",
                estado: "1200501907015615",
                prioridad: "1200488290970488",
                team: data.teamGid
            }
            break;
        case "Gráfico":
            data.gids = {
                project: "1200661840219129",
                customField_estado: "1200673917405220",
                // customField_prioridad: "1200488290969450",
                customField_team: "1200502659180083",
                estado: "1200673917405226",
                prioridad: "1200488290970488",
                team: data.teamGid
            }
            break;
        case "Reserva de Estudio":
            data.gids = {
                project: "1186133811083947",
                customField_estado: "1200533501581458",
                // customField_prioridad: "1200488290969450",
                customField_team: "1200502659180083",
                estado: "1200533501581459",
                prioridad: "1200488290970488",
                team: data.teamGid
            }
            break;
        default:
            break;
    }

    await setGidProject(data.gids.project);
    return data;
}

export const getTeams = async (gidProject) => {

    let equipos;

    const result = await client.customFieldSettings.getCustomFieldSettingsForProject(gidProject, { opt_pretty: true });
    if (result.data) {

        result.data.map((customField) => {
            if (customField.custom_field.name === "Equipo") {
                    equipos = customField.custom_field;
            }
        })
        
        return equipos.enum_options.filter(element=> element.enabled === true);
    }
}


export const createTask = async (data) => {
    const channels = await AxiosConfig.get('/Content/GetChannels');
    const formats = await AxiosConfig.get('/Content/GetFormats');
    const carreras = await AxiosConfig.get('/Values/GetProducts/all');

    let canal = "";
    let formato = "";
    let carrera = "";

    channels.data.data.map(channel => {
        if (channel.idChannel === data.idChannel) {
            canal = channel.name;
            return;
        }
    });

    formats.data.data.map(format => {
        if (format.idFormat === data.idFormat) {
            formato = format.description
            return;
        }
    });

    carreras.data.map(product => {
        if (product.idProduct === data.idProduct) {
            carrera = product.name
            return;
        }
    })

    let custom_fields_obj = {};
    custom_fields_obj[data.gids.customField_estado] = data.gids.estado;
    // custom_fields_obj[data.gids.customField_prioridad] = data.gids.prioridad;
    custom_fields_obj[data.gids.customField_team] = data.teamGid;

    const notes = data.linkSupplies ?
        `${data.description}\n\nCarrera: ${carrera}\nTipo de contenido: ${data.typeContent}\nCanal: ${canal}\nFormato: ${formato}\nInsumos: ${data.supplies}\nLink: ${data.linkSupplies}\n\nUsuario: ${data.user}` :
        `${data.description}\n\nCarrera: ${carrera}\nTipo de contenido: ${data.typeContent}\nCanal: ${canal}\nFormato: ${formato}\n\nUsuario: ${data.user}`

    const bodyParameter = {
        "data": {
            "approval_status": "pending",
            "assignee": idContentUser,
            "assignee_status": "upcoming",
            "completed": false,
            "custom_fields": custom_fields_obj,
            "due_on": data.deliveryDate,
            "followers": [
                "1167147957078056"
            ],
            "liked": true,
            "name": data.name,
            "notes": notes,
            "projects": [data.gids.project],
            "resource_subtype": "approval",
        }
    };
    // const getUsers = await client.users.getUsers({ workspace: idWorkSpace, opt_pretty: true });
    console.log(idWorkSpace, bodyParameter.data)
    const newTask = await client.tasks.createInWorkspace(idWorkSpace, bodyParameter.data);

    if (newTask) return ({ result: "success", data: newTask });
}

export const getTask = async (rowData) => {

    try {
        const obtainTask = await client.tasks.getTask(rowData.idTask, { workspace: idWorkSpace, opt_pretty: true });
        if (obtainTask) {
            const estado = obtainTask.custom_fields[0].display_value
            const update = await AxiosConfig.put('/Content/UpdateAsanaRequest', {
                idContentRequest: rowData.idContentRequest,
                status: estado,
                endTaskDate: obtainTask.completed ? obtainTask.completed_at : null
            });
            return { result: update.data.result, message: "Se ha actualizado correctamente la información" };
        }
    } catch (e) {
        console.log("error", e.value.errors[0].message);
        return { result: "error", message: "" }
    }
}
