import React from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, Pager, Paging, Popup, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Config } from '../../Config';
import { productAffDataSource } from '../../shared/dataSources/data-source.data';

const ProductLinks = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idProductLink",
            loadUrl: `${Config.url.api}/productLinks/getProductLink/${props.props.data.data.idProduct}`,
            insertUrl: `${Config.url.api}/productLinks/insertProductLink`,
            updateUrl: `${Config.url.api}/productLinks/updateProductLink`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idLead: props.props.data.data.idLead };
            },
        }),
    };

    return (
        <>
            <h1 className="is-size-6 m-1 center">Afiliaciones del usuario</h1>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idUser"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Hotlink Info"
                        showTitle="true"
                        width={"70%"}
                        height={"auto"}
                        maxHeight={"95vh"}
                    />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2} >
                            <Item dataField="idProduct" isRequired={true} />
                            <Item dataField="nameLink" isRequired={true} />
                            <Item dataField="urlDomain" isRequired={true} />
                            <Item dataField="urlParam" isRequired={true} />
                            <Item dataField="urlDest" isRequired={true} />
                            <Item dataField="order" isRequired={true} />
                            <Item dataField="urlProtect"  />
                            <Item dataField="isEnable"  />
                        </Item>
                    </Form>

                </Editing>

                <Column type="buttons" width={110}>
                    <Button name="edit" text="Editar" />
                </Column>
                <Column dataField='idProductLink' caption='idProductLink' dataType='number' visible={false} />
                <Column dataField="idProduct" caption="Producto" dataType="number" >
                    <Lookup
                        dataSource={productAffDataSource}
                        valueExpr="idProduct"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField='nameLink' caption='Nombre del Link' dataType='string' />
                <Column dataField='urlDomain' caption='Url dominio' dataType='string' />
                <Column dataField='urlParam' caption='Parámetro de Url' dataType='string' />
                <Column dataField='urlDest' caption='Url destino' dataType='string' />
                <Column dataField='order' caption='Orden del link' dataType='number' />
                <Column dataField='urlProtect' caption='Url protegida' dataType='boolean' />
                <Column dataField='isEnable' caption='Esta habilitado' dataType='boolean' />

            </DataGrid>
        </>
    )
}

export default ProductLinks;