import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import './settings-container.styles.scss';

type props = {
    showContent: Boolean
}

const SettingsContainer = ({ showContent }: props) => {

    let history = useHistory();

    const handleClick = () => {        
        localStorage.clear();                
        history.push("/");
    }

    const supportLink = () => {
        history.push("/support");
    }

    const accountLink = () => {
        history.push("/profile");
    }

    return (
        <div className="settings-container p-3" 
        style={{ opacity: showContent ? '1' : '0', display: !showContent ? 'none' : '' }}>
            <div className="links-section">
                <div className="account-link mb-2">
                    <span className="profile-link is-size-6" onClick={accountLink}>
                        <p>Mi cuenta</p>
                    </span> 
                </div>
                <span className="help-link" onClick={supportLink}>
                    <p>Ayuda BeMaster</p>
                </span>
                <span className="logout-link" onClick={handleClick}>
                    <p>Cerrar Sesión</p>
                </span>
            </div>
        </div>
    );
};

export default SettingsContainer;