import React from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup, RequiredRule, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Config } from '../../Config';
import MasterDetailModuleClass from './master-detail-module-class.component';

const accessTypeSource = [
    { value: "estudiantes", nameList: "Estudiantes" },
    { value: "masters", nameList: "Masters" },
    { value: "estudiantes|masters", nameList: "Estudiantes y Masters" }
]

const levels = [
    { name: "Junior", value: "Junior" }
]


const CareerModuleTemplate = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idCareerModule",
            loadUrl: `${Config.url.api}/bemaster/GetCareerModule/${props.props.data.data.idCareer}`,
            insertUrl: `${Config.url.api}/bemaster/insertCareerModule`,
            updateUrl: `${Config.url.api}/bemaster/updateCareerModule`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idCareer: props.props.data.data.idCareer };
            },
        }),
    };

    return (
        <>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idCareerModule"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Escalation Info"
                        showTitle="true"
                        width={"70%"}
                        height={"auto"}
                        maxHeight={"95vh"}
                    />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2} >
                            <Item dataField="level" />
                            <Item dataField="order" />
                            <Item dataField="module" />
                            <Item dataField="description" />
                            <Item dataField="accessType" />
                        </Item>
                    </Form>

                </Editing>

                <Column type="buttons" caption="Acciones" width={80}>
                    <Button name="edit" text="Editar" />
                </Column>
                <Column dataField="idCareerModule" caption="idCareerModule" dataType="number" visible={false} />
                <Column dataField="idCareer" caption="idCareer" dataType="number" visible={false} />
                <Column dataField="level" caption="Nivel de estudiante" dataType="string" >
                    <Lookup
                        dataSource={levels}
                        valueExpr="value"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="order" caption="Orden" dataType="number" sortOrder={"asc"} alignment="left" />
                <Column dataField="module" caption="Nombre del módulo" dataType="string" />
                <Column dataField="description" caption="Título del módulo" dataType="string" />
                <Column dataField="accessType" caption="Tipo de acceso" dataType="string" >
                    <Lookup
                        dataSource={accessTypeSource}
                        valueExpr="value"
                        displayExpr="nameList"
                        allowClearing={true}
                    />
                </Column>

                <MasterDetail
                    enabled={true}
                    component={MasterDetailModuleClass}
                />
            </DataGrid>
        </>
    )
}

export default CareerModuleTemplate;