import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Lookup, Paging, Pager, Button, Editing, Texts, SearchPanel, Form, RequiredRule, Popup
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './paytool-transactions-detail.styles.scss'
import './PaytoolTransaction.modules.css'
import { BiSearchAlt2 } from 'react-icons/bi';
import { Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';



const Subscriptions = () => {

    const gridRef = useRef<DataGrid>(null);
    const searchEmailRef = useRef<HTMLInputElement>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.subscriptions.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    const [searchEmail, setSearchEmail] = useState<string | null>("");

    const [fechaInicio, setFechaInicio] = useState<string>("")
    const [fechaFinal, setFechaFinal] = useState<string>("")

    let cambioFechaInicio = (e: any)=>{
        setSearchEmail("")
        setFechaInicio(e.target.value)
    }

    let cambioFechaFinal = (e: any)=>{
        setSearchEmail("")
        setFechaFinal(e.target.value)
    }

    const changeEmailSearch = (e: any) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            setSearchEmail("")
        }
    }

    const searchEmailClick = () => {
        const valor = searchEmailRef.current?.value;
        if (valor === "" || valor === undefined) {
            setSearchEmail("");
        } else {
            setSearchEmail(valor);
        }
    }

    useEffect(() => {
        sessionStorage.setItem("IDTransaction", "")
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);


    const gridDataSource = {
        store: createStore({
            key: "idSuscription",
            updateUrl:`${Config.url.apiNode}mastershop/subscriptions`,
            loadUrl: `${Config.url.apiNode}mastershop/subscriptions?fechaInicio=${fechaInicio}&fechaFinal=${fechaFinal}&email=${searchEmail}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
                if (ajaxOptions.method === "PUT") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };      
            },
        }),
    }

    const onRowUpdated = (e: any) => {
        if (e.data.result) {
            notify("Actualizado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }

    return (
        <>
            {
                authorized && authorized ? <div className="main-Affiliates-management-container">

                    <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Suscripciones MasterShop</h1>
                                </div>
                            </div>
                        </div>

                    <form className="search">
                        <label className="searchLabel">Búsqueda por Email:</label>
                        <input type="text" name="search" placeholder="Email" autoComplete="off" ref={searchEmailRef} onChange={changeEmailSearch} />
                        <div className="searchButton" onClick={searchEmailClick} > <BiSearchAlt2 className="search-icon" /> </div>
                        <label className="searchLabel">Fin de Suscripcióne entre:</label>
                        <input type="date" name="fechaInicio" id="search" className='searchInput' placeholder="Fecha Inicial" autoComplete="off" value = {fechaInicio} onChange = {cambioFechaInicio}/>
                        <label className="searchLabel">y :</label>
                        <input type="date" name="fechaFinal" id="search" className='searchInput' placeholder="Fecha Final" autoComplete="off" value = {fechaFinal} onChange = {cambioFechaFinal}/>
                    </form>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idSuscription"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        onRowUpdated={onRowUpdated}
                        showRowLines={true}
                        height="75vh"
                        width="90vw"
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="15" />
                        <Pager showInfo={true} />

                        <Editing mode="popup" allowUpdating={true} allowAdding={false} >
                            <Texts saveRowChanges="Guardar" />
                            <Popup
                                title="Editar Suscripcion"
                                showTitle="true"
                                width={"30%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                            />
                            <Form colCount={1}>
                                <Item dataField="email" isRequired={true} disabled={true} >
                                    <RequiredRule message="El email del cliente es necesario" />
                                </Item>
                                <Item dataField="startDate" isRequired={true} >
                                    <RequiredRule message="La fecha inicial de suscripción es necesaria" />
                                </Item>
                                <Item dataField="finalDate" isRequired={true} >
                                    <RequiredRule message="La fecha final de suscripción es necesaria" />
                                </Item>
                                <Item dataField="state" isRequired={true} >
                                    <RequiredRule message="El estado es necesario" />
                                </Item>
                            </Form>
                        </Editing>

                        <Column type="buttons" caption="Acciones" width={80}>
                            <Button name="edit" text="Editar" />
                        </Column>
                        <Column dataField="idSuscription" caption="Código de Suscripcion" dataType="string" alignment="center"/>
                        <Column dataField="email" allowEditing={false} caption="Correo Electrónico" dataType="string" alignment="center"/>
                        <Column dataField="idProduct" caption="Código de Producto" dataType="string" alignment="center"/>
                        <Column dataField="idState" visible = {false} caption="Código de Estado" dataType="string" alignment="center" />
                        <Column dataField="state" caption="Estado" dataType="string" alignment="center">
                            <Lookup
                            dataSource={["Activo", "Inactivo", "Por Aprobación", "Rechazado", "Renovación Rechazada", "Cancelado", "Intento de Renovación", "Cancelado por Productor", "Cancelado por Cliente", "Cancelado por Admin", "Programación de Cancelación"]}
                            allowClearing={true}
                            />
                        </Column>
                        <Column dataField="name" caption="Plan" dataType="string" alignment="center"/>
                        <Column dataField="startDate" caption="Inicio de Suscripción" dataType="date" format="dd/MM/yy  hh:mm:ss" alignment="center"/>
                        <Column dataField="finalDate" caption="Fin de Suscripción" dataType="date" defaultSortOrder={"asc"} format="dd/MM/yy  hh:mm:ss"  alignment="center"/>
                    </DataGrid>

                
                </div> : <></>
            }
        </>
    )
}

export default Subscriptions