import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup, RequiredRule,
    Paging, Pager, Button, Popup, Form, Texts
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/form";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import notify from "devextreme/ui/notify";
import { AuthContext } from '../auth/components/context/auth-context';
import { productDataSource, statusDataSource } from '../../shared/dataSources/data-source.data';


const gridDataSource = {
    store: createStore({
        key: "idUserTrackingCode",
        // insertUrl: `${Config.url.api}/escalation/AddTicket/12/Mastertools`,
        updateUrl: `${Config.url.api}/cert_affiliates/update_cert_affiliates`,
        loadUrl: `${Config.url.api}/cert_affiliates/get_cert_affiliates`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
}

const CertificateDns = () => {

    const gridRef = useRef<DataGrid>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.certificate_dns.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    const onRowUpdated = (e: any) => {
        notify(e.data.message);
    }

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    return (
        <>
            {
                authorized && authorized ? <div className="escalation-mastertools-main-container">

                    <h1 className="is-size-5 m-5 center">Verificación SSL Pixels</h1>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idEscalation"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        showRowLines={true}
                        onRowUpdated={onRowUpdated}
                        height="88vh"
                        width="95vw"
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="20" />
                        <Pager showInfo={true} />

                        <Editing mode="popup" allowUpdating={true} >
                            <Texts saveRowChanges="Guardar" />
                            <Popup
                                title="Verification Info"
                                showTitle="true"
                                width={"70%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                            />
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2} >
                                    <Item dataField="userName" />
                                    <Item dataField="userEmail" />
                                    <Item dataField="platform" />
                                    <Item dataField="domain" />
                                    <Item dataField="domainVerified" />
                                    <Item dataField="certVerified" isRequired={true} />
                                    <Item dataField="idProduct" />
                                </Item>
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item
                                        dataField="certObservations"
                                        editorType="dxTextArea"
                                        colSpan={2}
                                        editorOptions={{ height: 100, maxLength: 250 }}
                                    />
                                </Item>
                            </Form>
                        </Editing>

                        <Column type="buttons" width={50}>
                            <Button name="edit" text="Editar" />
                        </Column>
                        <Column dataField="idUserTrackingCode" caption="id del registro" dataType="number" visible={false} allowEditing={false} />
                        <Column dataField="idUserAffiliation" caption="idUserAffiliation" dataType="string" visible={false} allowEditing={false} />
                        <Column dataField="platform" caption="Plataforma" dataType="string" allowEditing={false} />
                        <Column dataField="trackingCode" caption="Tracking code" dataType="string" visible={false} allowEditing={false} />
                        <Column dataField="domain" caption="Dominio" dataType="string" allowEditing={false} />
                        <Column dataField="domainVerified" caption="Verificación del dominio" dataType="string" allowEditing={false} >
                            <Lookup
                                dataSource={[{ "id": 0, "name": "Sin Verificar" }, { "id": 1, "name": "Verificado" }]}
                                valueExpr="id"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="certVerified" caption="Verificación del certificado" dataType="string" filterValue="0" >
                            <Lookup
                                dataSource={statusDataSource("certAffiliates")}
                                valueExpr="statusPortal"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="idUser" caption="id User Aff" dataType="number" visible={false} allowEditing={false} />
                        <Column dataField="idProduct" caption="Producto" dataType="string" allowEditing={false} >
                            <Lookup
                                dataSource={productDataSource}
                                valueExpr="idProduct"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="affiliateCode" caption="Código de afiliación" dataType="string" allowEditing={false} />
                        <Column dataField="userName" caption="Nombre del afiliado" dataType="string" allowEditing={false} />
                        <Column dataField="userEmail" caption="Correo electrónico" dataType="string" allowEditing={false} />
                        <Column dataField="certObservations" caption="Observaciones de verificación" dataType="string" width="500" />
                    </DataGrid>

                </div> : <></>
            }
        </>
    )
}

export default CertificateDns;