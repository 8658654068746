//Obtain date_ini for the Wolkvox API URL
export const fechaIni = () => {
    let dateIni = new Date();
    dateIni.setDate(dateIni.getDate() - 7);

    const añoIni = dateIni.getFullYear();

    const mesIni = dateIni.getMonth() + 1 < 10 ? `0${dateIni.getMonth() + 1}` : dateIni.getMonth() + 1;

    const diaIni = dateIni.getDate() < 10 ? `0${dateIni.getDate()}` : dateIni.getDate();

    const date = `${añoIni}${mesIni}${diaIni}`;

    return date;
};

//Obtain date_ini for the Wolkvox API URL
export const formatDate = (fecha) => {

    const año = fecha.getFullYear();

    const mes = fecha.getMonth() + 1 < 10 ? `0${fecha.getMonth() + 1}` : fecha.getMonth() + 1;

    const dia = fecha.getDate() < 10 ? `0${fecha.getDate()}` : fecha.getDate();

    const date = `${dia}-${mes}-${año}`;

    return date;
};


//Obtain date_End for the Wolkvox API URL
export const fechaEnd = () => {

    let dateEnd = new Date();

    const añoEnd = dateEnd.getFullYear();

    const mesEnd = dateEnd.getMonth() + 1 < 10 ? `0${dateEnd.getMonth() + 1}` : dateEnd.getMonth() + 1;

    const diaEnd = dateEnd.getDate() < 10 ? `0${dateEnd.getDate()}` : dateEnd.getDate();

    const date = `${añoEnd}${mesEnd}${diaEnd}`

    return date;
};

//Function to format HH/MM/SS
export const formatAHT = (horas, minutos, segundos) => {
    const rHoras = horas < 10 ? `0${horas}` : `${horas}`;
    const rMinutos = minutos < 10 ? `0${minutos}` : `${minutos}`;
    const rSegundos = segundos < 10 ? `0${segundos}` : `${segundos}`;

    const format = `${rHoras}:${rMinutos}:${rSegundos}`

    return format;
};

//formatLoginTime
export const formatLoginTime = (horas, minutos, segundos) => {
    //s=segundos m=minutos h=horas
    //Modulo de 60 son los valores finales
    let sFinal = segundos % 60;

    //Si los segundos ingresados son mayores a 60 se divide el valor por 60 con un Math.floor para traer los minutos sobrantes
    let sSobrantes = segundos > 60 ? Math.floor(segundos / 60) : 0;

    let mFinal = (minutos + sSobrantes) % 60;

    let mSobrantes = minutos + sSobrantes > 60 ? Math.floor((minutos + sSobrantes) / 60) : 0;

    let hFinal = horas + mSobrantes;

    const rHoras = hFinal < 10 ? `0${hFinal}` : `${hFinal}`;
    const rMinutos = mFinal < 10 ? `0${mFinal}` : `${mFinal}`;
    const rSegundos = sFinal < 10 ? `0${sFinal}` : `${sFinal}`;

    const result = `${rHoras}:${rMinutos}:${rSegundos}`

    return result;
};

//Modificación de número a USD
const optionsUSD = { style: "currency", currency: "USD" };
export const formatUSD = new Intl.NumberFormat("en-US", optionsUSD);

//Modificación de número a %
const optionsPercent = { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 };
export const formatPercent = new Intl.NumberFormat("en-US", optionsPercent);