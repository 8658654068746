import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import React, { useContext, useEffect, useState } from 'react';
import { Config } from '../../Config';
import { productOwnerDataSource } from '../../shared/dataSources/data-source-comizzion.data';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import ProductHotLinks from './product-productlinks.component';

const gridDataSource = {
    store: createStore({
        key: "idProduct",
        insertUrl: `${Config.url.api}/comizzion/insertProducts`,
        updateUrl: `${Config.url.api}/comizzion/updateProducts`,
        loadUrl: `${Config.url.api}/comizzion/getProducts`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
}

const ProductComizzion_Management = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.classManagement_bm.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    const notifyChanges = (e: any) => {
        if (e.data.result === "success") {
            notify(e.data.message);
        } else {
            notify(e.data.message, e.data.result);
        }
    }

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    return (
        <>
            {/* {
                authorized && authorized ? */}
            <div className="product-comizzion-management-main-container">
                <h1 className="is-size-5 m-5 center">Gestión de productos para Comizzion</h1>

                <DataGrid
                    dataSource={gridDataSource}
                    showBorders={true}
                    keyExpr="idProduct"
                    allowColumnReordering={true}
                    columnAutoWidth={true}
                    focusedRowEnabled={true}
                    showRowLines={true}
                    onRowInserted={notifyChanges}
                    onRowUpdated={notifyChanges}
                    height="88vh"
                    width="95vw"
                >
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Paging enabled={true} pageSize="20" />
                    <Pager showInfo={true} />

                    <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                        <Texts saveRowChanges="Guardar" />
                        <Popup
                            title="Product Info"
                            showTitle="true"
                            width={"70%"}
                            height={"auto"}
                            maxHeight={"95vh"}
                        />
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2} >
                                <Item dataField="name" isRequired={true} />
                                <Item dataField="domain" isRequired={true} />
                                <Item dataField="idHotmart" isRequired={true} />
                                <Item dataField="urlPromotionalMaterial" />
                                <Item dataField="urlAffiliationForm" />
                                <Item dataField="urlConversionPage" />
                                <Item dataField="price" />
                                <Item dataField="idproductOwner" isRequired={true} />
                                <Item dataField="baseCommission" isRequired={true} />
                                <Item dataField="urlProductorSays" />
                                <Item dataField="urlFunnel" />
                                <Item dataField="urlRequirements" />
                                <Item dataField="temperature" />
                                <Item dataField="rating" />
                                <Item dataField="tagAccount" />
                                <Item dataField="urlAffPolicy" />
                                <Item dataField="urlAffRequest" />
                                <Item dataField="orderProduct" />
                                <Item dataField="currency" isRequired={true}/>
                                <Item dataField="affiliationsActive" />
                                <Item dataField="enableProvider"/>
                                <Item dataField="affOnlyMembers" />
                                <Item dataField="active" />
                            </Item>
                            <Item itemType="group" colCount={2} colSpan={2} >

                                <Item
                                    dataField="description"
                                    editorType="dxTextArea"
                                    colSpan={2}
                                    editorOptions={{ height: 100 }}
                                />
                            </Item>
                        </Form>

                    </Editing>

                    <Column type="buttons" width={110}>
                        <Button name="edit" text="Editar" />
                    </Column>
                    <Column dataField="idProduct" caption="idProduct" dataType="number" visible={false} />
                    <Column dataField="name" caption="Nombre del producto" dataType="string" />
                    <Column dataField="domain" caption="Dirección web" dataType="string" />
                    <Column dataField="idHotmart" caption="id de hotmart" dataType="number" visible={false} />
                    <Column dataField="orderProduct" caption="Orden del producto" dataType="number" sortOrder={'asc'} />
                    <Column dataField="affiliationsActive" caption="Estado en la plataforma" dataType="boolean" filterValue={1} />
                    <Column dataField="enableProvider" caption="Estado del botón de WhatsApp" dataType="boolean" />
                    <Column dataField="urlPromotionalMaterial" caption="Url material promocional" dataType="string" visible={false} />
                    <Column dataField="urlAffiliationForm" caption="Url formulario de afiliación" dataType="string" visible={false} />
                    <Column dataField="urlConversionPage" caption="Url pagina de conversión" dataType="string" visible={false} />
                    <Column dataField="price" caption="Precio" dataType="number" editorOptions={{ format: 'decimal' }} visible={false} />
                    <Column dataField="idproductOwner" caption="productor" dataType="number" >
                        <Lookup dataSource={productOwnerDataSource} valueExpr="idproductOwner" displayExpr="name" />
                    </Column>
                    <Column dataField="baseCommission" caption="Comisión base" dataType="number" editorOptions={{ format: 'decimal' }} />
                    <Column dataField="urlProductorSays" caption="Url el productor dice" dataType="string" visible={false} />
                    <Column dataField="urlFunnel" caption="Url funnel" dataType="string" visible={false} />
                    <Column dataField="urlRequirements" caption="Url requerimientos" dataType="string" visible={false} />
                    <Column dataField="temperature" caption="Temperatura" dataType="number" visible={false} />
                    <Column dataField="rating" caption="Rating" dataType="number" editorOptions={{ format: 'decimal' }} visible={false} />
                    <Column dataField="affOnlyMembers" caption="Afiliación solo para miembros" dataType="boolean" visible={false} />
                    <Column dataField="tagAccount" caption="Tag de la cuenta" dataType="string" visible={false} />
                    <Column dataField="urlAffPolicy" caption="Url políticas" dataType="string" visible={false} />
                    <Column dataField="urlAffRequest" caption="Url requisítos" dataType="string" visible={false} />
                    <Column dataField="active" caption="Habilitar afiliaciones" dataType="boolean" visible={false} />
                    <Column dataField="currency" caption="Moneda" dataType="string" visible={false} />

                    <Column dataField="description" caption="Descripción" dataType="string" width={1200} />

                    <MasterDetail
                        enabled={true}
                        component={ProductHotLinks}
                    />

                </DataGrid>
            </div>
            {/* : <></>
            } */}
        </>
    )
}

export default ProductComizzion_Management;