import React from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup, RequiredRule, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Config } from '../../Config';

const types = [
    {name: "Fuegos", value: "Fires"},
    {name: "Trofeos", value: "Triumphs"}
]

const classifications = [
    {name: "Globales", value: "GLOBALES"},
    {name: "Para los embajadores", value: "PARA LOS EMBAJADORES"},
    {name: "Por ayudar a la comunidad", value: "POR AYUDAR A LA COMUNIDAD"},
    {name: "Por completar clases", value: "POR COMPLETAR CLASES"},
    {name: "Por desbloquear contenido", value: "POR DESBLOQUEAR CONTENIDO"},
    {name: "Por frecuencias", value: "POR FRECUENCIAS"},
    {name: "Por tipo estudiante", value: "POR TIPO ESTUDIANTE"},
]

const AwardClassMissionTab = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idAward",
            loadUrl: `${Config.url.api}/bemaster/GetClassAward/${props.props.data.data.idModuleClass}`,
            updateUrl: `${Config.url.api}/bemaster/updateClassAward`,
            insertUrl: `${Config.url.api}/bemaster/insertClassAward`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idModuleClass: props.props.data.data.idModuleClass };
            },
        }),
    };

    return (
        <>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idAward"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Escalation Info"
                        showTitle="true"
                        width={"70%"}
                        height={"auto"}
                        maxHeight={"95vh"}
                    />
                    <Form>
                        <Item itemType="group" colCount={3} colSpan={2} >
                            <Item dataField="type" />
                            <Item dataField="name" />
                            <Item dataField="description" />
                            <Item dataField="classification" />
                            <Item dataField="cant" />
                        </Item>
                    </Form>

                </Editing>

                <Column type="buttons" caption="Acciones" width={80}>
                    <Button name="edit" text="Editar" />
                </Column>
                <Column dataField="idAward" caption="idAward" dataType="number" visible={false} />
                <Column dataField="type" caption="Tipo de recompensa" dataType="string" >
                    <Lookup
                        dataSource={types}
                        valueExpr="value"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="name" caption="Nombre de la recompensa" dataType="string" />
                <Column dataField="description" caption="Descripción de la recompensa" dataType="string" />
                <Column dataField="classification" caption="Clasificación de la recompensa" dataType="string" >
                    <Lookup
                        dataSource={classifications}
                        valueExpr="value"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="cant" caption="Cantidad de recompensas" dataType="number" alignment="left" />
            </DataGrid>
        </>
    )
}

export default AwardClassMissionTab;