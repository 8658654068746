import React, { useEffect, useState } from 'react';
import NotiCard from '../auth/components/notification-card/noti-card.component';
// import NotificationData from '../notification/notification.data.component';
import Scrollbar from "react-scrollbars-custom";
import AxiosConfig from '../../axios/axiosConfig';
import { MdClose } from 'react-icons/md';

import './notification-container.styles.scss';

type props = {
    allowShowComp?: Boolean,
    showNewNot: (show: boolean) => void,
}

const NotificationContainer = ({ allowShowComp = false, showNewNot }: props) => {

    const [notifications, setNotifications] = useState([]);
    const [showed, setShowed] = useState(allowShowComp);

    const fetchData = async () => {
        await AxiosConfig.get('/Notification').then(result => {
            if (result.data.result === "success") {
                setNotifications(result.data.data);

                let not = [...result.data.data];
                const idsNoti = [...not.filter((n: any) => n.viewed === 0).map((a: any) => a.idNotification)];

                if (idsNoti.length > 0) {
                    showNewNot(true);
                }
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const deleteHandleClick = async (id: number, i: number) => {
        const noti = [...notifications];
        noti.splice(i, 1);
        setNotifications(noti);
        await AxiosConfig.delete('/Notification/' + id);;
    }

    useEffect(() => {

        let isSubscribed = true;
        let displayed = showed;

        if (!allowShowComp && displayed) {
            const idsNoti = [...notifications.filter((n: any) => n.viewed === 0).map((a: any) => a.idNotification)];

            if (idsNoti.length > 0) {

                AxiosConfig.post('/Notification/MarkViewedNotifications', {
                    listInts: idsNoti
                }).then(response => {
                    if (response.data.result === "success") {
                        const notiUpdated: any = [...notifications];
                        idsNoti.map(item => {
                            let indexNot = notiUpdated.findIndex((f: any) => f.idNotification === item);
                            notiUpdated[indexNot].viewed = 1;
                            showNewNot(false);
                        });
                        setNotifications(notiUpdated);
                    }
                });
            }
        }

        setShowed(allowShowComp);

        return () => { isSubscribed = false };
    });

    return (
        <div className="notification-container py-4"
            style={{
                zIndex: allowShowComp ? 101 : 0, opacity: allowShowComp ? '1' : '0',
                display: allowShowComp ? 'flex' : 'none'
            }}>

            <h1 className="h1Notification">NOTIFICACIONES</h1>

            <Scrollbar style={{ height: 120, marginTop: 25, marginRight: -20, position: 'inherit', width: 244 }}>
                <div className="notifications-list is-flex">
                    <div className="notification-content">
                        {notifications. length > 0 ? notifications?.sort((a: any, b: any) => b.idNotification - a.idNotification)
                            .map((nData: any, i: number) => {
                                return <NotiCard
                                    key={nData.idNotification}
                                    nData={nData}
                                    deleteHandleClick={() => deleteHandleClick(nData.idNotification, i)}
                                />
                            }) : <></>
                        }
                    </div>
                </div>
            </Scrollbar>
        </div>
    );
};

export default NotificationContainer;