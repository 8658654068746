import AxiosConfig from "../../axios/axiosConfig";
import CustomStore from 'devextreme/data/custom_store';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';


//sources
export const statusCommunitySource = {
    store: new CustomStore({
        key: "idStatusAction",
        loadMode: "raw",
        load: () => {
            return AxiosConfig.get('/Client/GetCommunityAction')
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                });
        }
    }),
    sort: 'name'
}

export const statusCommunityActionSource = {
    store: new CustomStore({
        key: "idStatusAction",
        loadMode: "raw",
        load: () => {
            return AxiosConfig.get('/Client/GetActionCommunity')
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                });
        }
    }),
    sort: 'name'
}

export const statusActionSource = {
    store: new CustomStore({
        key: "idStatusAction",
        loadMode: "raw",
        load: () => {
            return AxiosConfig.get('/Client/GetStatusAction')
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                });
        }
    }),
    sort: 'name'
}

export const userSource = {
    store: new CustomStore({
        key: "idUser",
        loadMode: "raw",
        load: () => {
            return AxiosConfig.get('/UserManagement/GetUser')
                .then(response => {
                    return response.data.data;
                }).catch(e => {
                    console.error(e);
                });
        }
    })
}
export const getClients = async () => {
    const response = await AxiosConfig.get("/Client/GetClients");

    if (response) {
        return response.data;
    }
}
export const getLogClients = async () => {
    const response = await AxiosConfig.get("/Client/GetLogClients");

    if (response) {
        return response.data;
    }
}
export const getStudents = (key, ClientsData) => {
    return new DataSource({
        store: new ArrayStore({
            data: ClientsData,
            key: 'idLeadAction'
        }),
        filter: ['idLead', '=', key]
    });
}

//add new LogLeadAction
export const editAffiliationState = async (data) => {

    const result = await AxiosConfig.put('/Client/editLogLeadAction',{
        idLeadAction: data.idLeadAction,
        idStatus: data.idStatus,
        description: data.description,
        evidence: data.evidencia,
        nameProduct: data.nameProduct,
        nameCommunity: data.nameCommunity,
        linkCommunity: data.linkCommunity,
        idStatusAction: data.idStatusAction
    });

    return result;

}

//on change fileUpdater
export const onValueChanged = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.value[0]); // convert to base64 string
};