import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Toast from 'devextreme-react/toast';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, PatternRule, Popup, RequiredRule } from 'devextreme-react/data-grid';
import MasterDetailUser from './master-detail-user-bm.component';
import { Config } from '../../Config';
import { Item } from 'devextreme-react/form';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './user-management-bm.styles.scss'

const gridDataSource = {
    store: createStore({
        key: "idUser",
        loadUrl: `${Config.url.api}/userBm/GetUserBm/0`,
        insertUrl: `${Config.url.api}/userBm/InsertUserBm`,
        updateUrl: `${Config.url.api}/userBm/UpdateUserBm`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
};

const UserManagement_BM = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.userManagementBm.id;

    const gridRef = useRef<DataGrid>(null);

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [level, setLevel] = useState<number>(0);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        message: ''
    });
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
            setLevel(response[0].role.level);
        }
        if (response[0].role.roleType === "admin") {
            setIsAdmin(true);
        }
    }, []);

    //hide toast
    const onHiding = () => {
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }

    const updatedRow = (e: any) => {
        if (e.data.result === "error") {
            const innerEx = e.data.data.InnerException.InnerException.Message;
            if (innerEx.includes("Duplicate")) {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: 'El correo electrónico ya se encuentra registrado'
                });
            } else {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: 'Ha ocurrido un error mientras actualizabas el usuario'
                });
            }

        } else {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                message: e.data.message ? e.data.message : "Actualizado con éxito"
            });
        }
    }

    const insertedRow = (e: any) => {
        if (e.data.result === "error") {
            const innerEx = e.data.data.InnerException.InnerException.Message;
            if (innerEx.includes("Duplicate")) {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: 'El correo electrónico ya se encuentra registrado'
                });
            } else {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: 'Ha ocurrido un error mientras agregabas un usuario'
                });
            }

        } else {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                message: 'Usuario creado con éxito'
            });
        }
    }
    
    return (
        <>
            {
                authorized && authorized ?
                    <div>

                        <Toast
                            visible={toastConfig.isVisible}
                            message={toastConfig.message}
                            type="info"
                            onHiding={onHiding}
                            displayTime={1200}
                        />
                        <h1 className="is-size-5 m-5 center">Gestión de usuarios BeMaster</h1>

                        <DataGrid
                            id="dataGrid"
                            ref={gridRef}
                            dataSource={gridDataSource}
                            showBorders={true}
                            keyExpr="idUser"
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            focusedRowEnabled={true}
                            showRowLines={true}
                            onRowInserted={insertedRow}
                            onRowUpdated={updatedRow}
                            height="90vh"
                            width="95vw"
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize={20} />
                            <Pager showInfo={true} />

                            <Editing mode="popup" allowUpdating={true} allowAdding={isAdmin}>
                                <Popup title="userCareer Info" showTitle={true} width="60%" />
                                <Form>
                                    <Item itemType="group" colCount={2} colSpan={2}>
                                        <Item dataField="firstName" />
                                        <Item dataField="lastName" />
                                        <Item dataField="email" />
                                        <Item dataField="status" />
                                        <Item dataField="cantFires" />
                                        <Item dataField="lastLogin" />
                                        <Item dataField="phone" />
                                        <Item dataField="commission" />
                                        <Item dataField="password" >
                                            <PatternRule
                                                message="El Password debe tener una letra mayúscula, 1 número y 8 carácteres mínimo"
                                                pattern={/(?=(?:.*[A-Z]){1})(?=(?:.*\d){1})\S{8,}/}
                                            />
                                        </Item>
                                    </Item>
                                </Form>
                            </Editing>

                            <MasterDetail
                                enabled={level > 1}
                                component={MasterDetailUser}
                            />
                            <Column type="buttons" width={"auto"}>
                                <Button name="edit" text="Editar" visible={true} />
                            </Column>
                            <Column dataField="idUser" caption="idUser" dataType="int" visible={false} />
                            <Column dataField="firstName" caption="Primer Nombre" dataType="string" allowEditing={isAdmin} >
                                <RequiredRule message="Ingresa un Nombre" />
                            </Column>
                            <Column dataField="lastName" caption="Apellido" dataType="string" allowEditing={isAdmin} >
                                <RequiredRule message="Debes ingresa un Apellido" />
                            </Column>
                            <Column dataField="email" caption="Correo" dataType="string" allowEditing={isAdmin} >
                                <RequiredRule message="Debes indicar el correo eléctronico del usuario" />
                            </Column>
                            <Column dataField="status" caption="Estado" dataType="int" >
                                <Lookup
                                    dataSource={[{ "idStatus": 2, "estado": "Inactivo" }, { "idStatus": 1, "estado": "Activo" }]}
                                    valueExpr="idStatus"
                                    displayExpr={"estado"}
                                    allowClearing={true}
                                />

                                <RequiredRule message="Selecciona por favor un estado para la plataforma" />
                            </Column>
                            <Column dataField="cantFires" caption="Cant. de Fuegos" dataType="number" allowEditing={isAdmin} />
                            <Column dataField="lastLogin" caption="Último LogIn" dataType="datetime" format="yyyy-MM-dd" allowEditing={isAdmin} />
                            <Column dataField="phone" caption="Teléfono" dataType="int" allowEditing={isAdmin} />
                            <Column dataField="commission" caption="Comisión" dataType="number" allowEditing={isAdmin} >
                                <RequiredRule message="Indica la cantidad de comisión del usuario" />
                            </Column>
                            <Column dataField="password" caption="Contraseña" dataType="string" />

                        </DataGrid>

                    </div> : <></>
            }
        </>
    )
}

export default UserManagement_BM;