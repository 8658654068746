import React, { useContext, useEffect, useRef, useState } from 'react'
import DataGrid,
{
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging
} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Config } from '../../Config';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import { BiSearchAlt2 } from 'react-icons/bi';
import { instanceNode } from '../../axios/axiosConfig';
import './highticket.styles.scss'
import './highticket.modules.css'
import notify from 'devextreme/ui/notify';




const HighTicket = () => {
    
    const searchEmailRef = useRef<HTMLInputElement>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.highTickets.id;
    
    const [authorized, setAuthorized] = useState<boolean>(false);
;
    const [searchEmail, setSearchEmail] = useState<string | null | any>("");

    const gridDataSource = {
        store: createStore({
            key: "idLink",
            loadUrl: `${Config.url.apiNode}mastershop/highTicket?email=${searchEmail}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
            }
        })
    }

  
    const fetchLeads = async () => {
        const respuesta = await instanceNode.get('mastershop/highTicket?email=' + searchEmail) 
        return respuesta;
    }

    const changeEmailSearch = (e: any) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            setSearchEmail("")
        }
    }

    const searchEmailClick = () => {
        const valor = searchEmailRef.current?.value;
        if (valor === "" || valor === undefined) {
            setSearchEmail("");
        }
        setSearchEmail(valor);
        console.log(searchEmailRef.current?.value);
    }

    useEffect(() => {
        fetchLeads();
    }, []);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    return (
        <>
            {
                authorized && authorized ?
                    <div className="main-Affiliates-management-container">
                        <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Gestion HighTickets</h1>
                                </div>
                            </div>
                        </div>

                        
 
                        <div className="search">
                            <label className="searchLabel">Introduzca el Email:</label>
                            <input type="text" name="search" placeholder="Email" autoComplete="off" ref={searchEmailRef} onChange={changeEmailSearch} />
                            <div className="searchButton" onClick={searchEmailClick} > <BiSearchAlt2 className="search-icon" /> </div>
                        </div>

                        <DataGrid
                            id="grid-container-affiliations"
                            dataSource={gridDataSource}
                            keyExpr="idLink"
                            showBorders={true}
                            showRowLines={true}
                            focusedRowEnabled={true}
                            columnAutoWidth={true}
                            height="74vh"
                            width="95vw"
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize={10} />
                            <Pager showInfo={true} />

                            <Column dataField="idLink" caption="Código de Lead" allowSearch={true} visible={true} alignment="center"/>
                            <Column dataField="message" caption="Detalle Link" alignment="center"/>
                            <Column dataField="name" caption="Nombre" alignment="center"/>
                            <Column dataField="nameLink" caption="NameLink" alignment="center"/>
                            <Column dataField="idProduct" caption="Código de producto" alignment="center"/>
                            <Column dataField="urlAffiliate" caption="URL de afiliado" alignment="center"/>
                        </DataGrid> 
                    </div> : <></>
            }
        </>
    );
}

export default HighTicket