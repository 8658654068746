import React, { ChangeEventHandler, useContext, useEffect, useRef, useState } from 'react'
import DataGrid,
{
    Column,
    Editing,
    FilterRow,
    Form,
    HeaderFilter,
    Lookup,
    MasterDetail,
    Pager,
    Paging,
    Popup,
    RequiredRule,
    Texts
} from 'devextreme-react/data-grid';
import CarreersTemplate from './carreers-template.component';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Config } from '../../Config';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './leads-management.styles.scss';
import { Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { productDataSource } from '../../shared/dataSources/data-source.data';
import { BiSearchAlt2 } from "react-icons/bi";


const Leads_management = () => {

    const searchRef = useRef<HTMLInputElement>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.leadManagement.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("all");

    const DataSource_leadsManagement = {
        store: createStore({
            key: "idLeadAction",
            insertUrl: `${Config.url.api}/Clients/AddStudent`,
            loadUrl: `${Config.url.api}/Clients/GetStudents/${searchValue}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
            }
        })
    }

    const onRowInserted = (e: any) => {
        if (e.data.result === "success") {
            notify(e.data.message);
        } else {
            notify(e.data.message, 'error');
        }
    }

    const changeSearch = (e: any) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            setSearchValue("all");
        }
    }

    const searchClick = () => {
        const valor = searchRef.current?.value;
        if (valor === "" || valor === undefined) {
            setSearchValue("all");
        } else {
            setSearchValue(valor);
        }
    }

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    return (
        <>
            {
                authorized && authorized ?
                    <div className="main-leads-management-container">
                        <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Gestión de Estudiantes</h1>
                                </div>
                            </div>
                        </div>

                        <div className="search">
                            <input type="text" name="search" id="search" placeholder="nombre en la comunidad..." autoComplete="off" ref={searchRef} onChange={changeSearch} />
                            <div className="searchButton" onClick={searchClick} > <BiSearchAlt2 className="search-icon" /> </div>
                        </div>

                        <DataGrid
                            dataSource={DataSource_leadsManagement}
                            keyExpr="IdLeadAction"
                            showBorders={true}
                            showRowLines={true}
                            focusedRowEnabled={true}
                            columnAutoWidth={true}
                            onRowInserted={onRowInserted}
                            cacheEnabled={true}
                            height="78vh"
                            width="95vw"
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize="20" />
                            <Pager showInfo={true} />

                            <Editing mode="popup" allowUpdating={false} allowAdding={true} >
                                <Texts saveRowChanges="Guardar" />
                                <Popup
                                    title="Agregar Lead"
                                    showTitle="true"
                                    width={"70%"}
                                    height={"80%"}
                                />
                                <Form>
                                    <Item itemType="group" colCount={2} colSpan={2}>
                                        <Item dataField="firstName" isRequired={true}>
                                            <RequiredRule message="Ingresa un Nombre" />
                                        </Item>
                                        <Item dataField="lastName" isRequired={true}>
                                            <RequiredRule message="Ingresa un Apellido" />
                                        </Item>
                                        <Item dataField="email" caption="Email" isRequired={true}>
                                            <RequiredRule message="Ingresa un correo electrónico válido" />
                                        </Item>
                                        <Item dataField="phone" />
                                        <Item dataField="idProduct" isRequired={true}>
                                            <RequiredRule message="Ingresa un producto" />
                                        </Item>
                                    </Item>
                                </Form>
                            </Editing>

                            <Column dataField="idLeadAction" visible={false} />
                            <Column dataField="name" caption="Nombre" dataType="string" />
                            <Column dataField="date" caption="Fecha de compra" dataType="date" format="yyyy-MM-dd" alignment="center" />
                            <Column dataField="email" caption="Correo" dataType="string" />
                            <Column dataField="phone" caption="Teléfono" dataType="number" alignment="left" />
                            <Column dataField="numCarreers" caption="Carreras" dataType="number" alignment="left" />
                            <Column dataField="nameCommunity" caption="Nombre en la comunidad" />
                            <Column dataField="firstName" caption="Nombre" visible={false} />
                            <Column dataField="lastName" caption="Apellido" visible={false} />
                            <Column dataField="idProduct" caption="Carrera" visible={false} >
                                <Lookup
                                    dataSource={productDataSource}
                                    valueExpr="idProduct"
                                    displayExpr="name"
                                />
                            </Column>
                            <MasterDetail
                                enabled={true}
                                component={CarreersTemplate}
                            />
                        </DataGrid>
                    </div> : <></>
            }
        </>
    );
}

export default Leads_management;