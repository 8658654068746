import React from 'react';
import SettingsContainer from '../settings-container/settings-container.component';

import './settings.styles.scss';

type props = {
    value: Boolean,
    switchGlobalState: Function
}

const Settings = ({value, switchGlobalState}: props) => {
    
    const handleClick = () => {
        switchGlobalState();
    }
    

    return (
        <div className="parent-settings-container is-flex">
            <div className="child-settings-container">
                <i className="is-settings-icon icon" onClick={handleClick}></i>
            </div>
            <div className="description-content">
                <div className="is-a-icon" style={{ zIndex: value ? 101 : 0, opacity: value ? '1' : '0' }}></div> 
                
                <SettingsContainer showContent={value} />
            </div>
        </div>
    );
};


export default Settings;