import AxiosConfig from '../../axios/axiosConfig';
import CustomStore from "devextreme/data/custom_store";

export const productOwnerDataSource = {
    store: new CustomStore({
        key: "idProductOwner",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get(`/valuesComizzion/getProductOwner`)
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    }),
    sort: "idProductOwner"
}