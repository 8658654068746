import React, { useContext, useEffect, useState, useRef } from 'react';
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column,
    DataGrid,
    FilterRow,
    HeaderFilter,
    Paging,
    Pager,
    Export,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";
import { Config } from "../../Config";
import Tarjeta from './tarjetasInfo.component';
import DataSource from "devextreme/data/data_source"
import axiosConfig from '../../axios/axiosConfig';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './sales-affiliates.styles.scss';
import Scrollbar from 'react-scrollbars-custom';

const scrollHeight = (window.innerHeight - 100);
const scrollWidth = (window.innerWidth - 65);

const gridDataSource = new DataSource({
    store: createStore({
        key: "idLeadAction",
        loadUrl: `${Config.url.api}/Affiliates/GetSalesAffiliates`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    })
});

const SalesAffiliates = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.salesAffiliates.id;
    const response = authContext.loginInfo.moduleRoles;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [totalInfo, setTotalInfo] = useState<any>([]);

    const gridRef = useRef<any>(null);

    response.filter((res: any) => {
        return (res.idModule === currentModule);
    });

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: () => { gridDataSource.reload() }
            }
        });
    }

    const renderSells = (e: any) => {
        const response = formatUSD.format(e.value);
        return <> {response} </>;
    }

    const renderCategory = (e: any) => {
        const value = e.billing;
        if (value < 5000) return "Sin categoría";
        if (value >= 5000 && value < 25000) return "Embajador Osado";
        if (value >= 25000 && value < 50000) return "Embajador Guardian";
        if (value >= 50000 && value < 100000) return "Embajador Heroico";
        if (value > 100000) return "Embajador Legendario"
    }

    const getTotalInfo = async () => {
        const result = await axiosConfig.get("Affiliates/GetInfoSalesAffiliates");

        if (result.data.result === "success") {
            setTotalInfo(result.data.data);
        }
    }

    useEffect(() => {
        getTotalInfo();
    }, []);

    //Modificación de número a USD
    const optionsUSD = { style: "currency", currency: "USD" };
    const formatUSD = new Intl.NumberFormat("en-US", optionsUSD);

    return (
        <div className="sales-affiliates-main-container">
            <Scrollbar style={{ height: scrollHeight, width: scrollWidth }}>
            {
                authorized && authorized ?
                    <>
                        <h1 className="is-size-5 mb-2 center">Rendimiento Afiliados</h1>

                        <div className="containerTarjetas">
                            <div className="tarjetas">
                                {
                                    Object.keys(totalInfo).map((key, i) => {
                                        return (
                                            <Tarjeta key={i} tittle={key} value={totalInfo[key]} />
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <DataGrid
                            ref={gridRef}
                            dataSource={gridDataSource}
                            showBorders={true}
                            keyExpr="idLeadAction"
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            focusedRowEnabled={true}
                            showRowLines={true}
                            repaintChangesOnly={true}
                            onToolbarPreparing={onToolbarPreparing}
                            width="94.5vw"
                        >
                            {/* <Selection mode="multiple" /> */}
                            <Export enabled={true} fileName={`Rendimiento Afiliados ${new Date().toLocaleString()}`} allowExportSelectedData={true} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} />
                            <Pager showInfo={true} />

                            <Column dataField="leadName" caption="Afiliado" dataType="string" allowEditing={false} />
                            <Column dataField="leadEmail" caption="Correo" dataType="string" allowEditing={false} />
                            <Column dataField="leadPhone" caption="Telefono" dataType="string" allowEditing={false} />
                            <Column dataField="fechaVenta" caption="fecha de Venta" dataType="date" allowEditing={false} />
                            <Column dataField="income" caption="Comisión Privilege" dataType="number" allowEditing={false} alignment="left" />
                            <Column dataField="nameProduct" caption="Producto" dataType="string" allowEditing={false} alignment="left" />
                            <Column dataField="commission" caption="Comisión" dataType="number" allowEditing={false} alignment="left" />
                            <Column dataField="type" caption="tipo" dataType="number" allowEditing={false} alignment="left" />
                            <Column dataField="billing" caption="Facturación del afiliado" dataType="number" alignment="left" cellRender={renderSells} sortIndex={0}
                                sortOrder={"desc"} allowEditing={false} />

                            <Summary>
                                <TotalItem
                                    column="income"
                                    summaryType="sum"
                                    valueFormat="currency" />
                                <TotalItem
                                    column="billing"
                                    summaryType="sum"
                                    valueFormat="currency" />
                            </Summary>
                        </DataGrid> </> : <></>
            }
            </Scrollbar>
        </div>
    );
};

export default SalesAffiliates;
