import React, { useRef } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup, RequiredRule,
    Paging, Pager, Button, Popup, Form, Texts
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/form";
import DataSource from 'devextreme/data/data_source';
import { Config } from "../../Config";
import notify from "devextreme/ui/notify";
import { Autocomplete } from 'devextreme-react/autocomplete';
import { productDataSource, productDataSourceAll } from '../../shared/dataSources/data-source.data';
import "devextreme-react/text-area";
import AxiosConfig from "../../axios/axiosConfig";

const suggestions = [
    'La Comisión fue entregada',
    'Se evidencia proceso de Autocompra',
    'Transaccion no aparece en cuenta de afiliados'
]


const gridDataSource = new DataSource({
    store: createStore({
        key: "idLeadActionSale",
        loadUrl: `${Config.url.api}/commissionRecovery/GetData`,
        updateUrl: `${Config.url.api}/commissionRecovery/Update`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    })
});

const Commission_recovery_pending = () => {

    const gridRef = useRef<DataGrid>(null);

    const onRowUpdated = (e: any) => {
        notify(e.data.message);
    }

    const onToolbarPreparing = (e: any) => {

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: () => { gridDataSource.reload() }   
            }
        });
    }

    const onCommentsChanged = (e: any, cellInfo: any) => {
        console.log('valor', e.value);
        cellInfo.setValue(e.value);
    }

    const commentsRender = (cellInfo: any) => {
        return (
            <>
                <Autocomplete
                    dataSource={suggestions}
                    onValueChanged={e => onCommentsChanged(e, cellInfo)}
                    minSearchLength={2}
                    searchTimeout={500}
                />
            </>
        )
    }

    return (
        <>


            <h1 className="is-size-5 m-5 center">Recuperación de comisiones Pendientes</h1>

            <DataGrid
                ref={gridRef}
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idEscalation"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                onRowUpdated={onRowUpdated}
                showRowLines={true}
                onToolbarPreparing={onToolbarPreparing}
                height="87vh"
                width="94vw"
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={true} >
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Commission Recovery Info"
                        showTitle="true"
                        maxWidth={"70%"}
                        height={"auto"}
                        maxHeight={"95vh"}
                    />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2} >
                            <Item
                                dataField="comments"
                                colSpan={2}
                                editorOptions={{ height: 100, maxLength: 1000 }}
                                isRequired={true} >
                                <RequiredRule message="Debes escribir un comentario sobre la comisión" />
                            </Item>
                        </Item>
                    </Form>
                </Editing>

                <Column type="buttons" caption="Acciones" width={80}>
                    <Button name="edit" text="Editar" />
                </Column>
                <Column dataField="idLeadAction" dataType="number" visible={false} />
                <Column dataField="leadName" dataType="string" caption="Nombre del lead" />
                <Column dataField="leadEmail" dataType="string" caption="Email del lead" />
                <Column dataField="idProduct" dataType="string" caption="Producto" width="120" >
                    <Lookup
                        dataSource={productDataSourceAll}
                        valueExpr="idProduct"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="leadDate" dataType="datetime" caption="Creación del lead" format="yyyy-MM-dd" />
                <Column dataField="saleDate" dataType="datetime" caption="Fecha de venta" format="yyyy-MM-dd" sortOrder={"desc"} />
                <Column dataField="affiliateCode" dataType="string" caption="Código de afiliación" />
                <Column dataField="transaction" dataType="string" caption="Código de transacción" />
                <Column dataField="comments" dataType="string" caption="Comentarios" width="700" editCellRender={commentsRender} />
            </DataGrid>

        </>
    )
}

export default Commission_recovery_pending;