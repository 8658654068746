import Axios from "axios";
import {
  fechaIni, fechaEnd, formatAHT,
  formatLoginTime, formatDate, formatUSD,
  formatPercent
} from './format.data';
import { Data, cbps_survey } from "./dataExample";
import AxiosConfig from "../../axios/axiosConfig";

let responseRating;

//ip_ipdialbox for Wolkvox API
const ip_ipdialbox = "35.243.234.27";

//Token for Wolkvox API
const token = "7b69645f6469737472697d2d3230323130373133313234363438";

//fecha inicial
const fechaInicia = fechaIni();

//fecha final
const fechaFina = fechaEnd();

//URL for ratingAPI
const urlRating = `https://${ip_ipdialbox}/ipdialbox/api_reports.php?token=${token}&report=cbps_survey&date_ini=${fechaInicia}&date_end=${fechaFina}`;

//fetchData TotalSales
export const fetchDataTotalSales = async (dateIni, dateEnd, setTotalSales, userFilter, productFilter) => {

  const result = await AxiosConfig.post(`/Dashboard/GetSellsByUser`, {
    dateIni: dateIni,
    dateEnd: dateEnd,
    user: userFilter,
    product: productFilter
  });

  if (result.data.result === "success") {
    result.data.data.length > 0 ? setTotalSales(result.data.data.length - 1) : setTotalSales(result.data.data.length);
  }
};

//fetchData Comission
export const fetchDataComission = async (dateIni, dateEnd, setComission, userFilter, productFilter) => {

  const result = await AxiosConfig.post("/Dashboard/GetUserComission", {
    dateIni: dateIni,
    dateEnd: dateEnd,
    user: userFilter,
    product: productFilter
  });

  if (result.data.result === "success") {
    setComission(formatUSD.format(result.data.data * 0.008));
  }
};

//Get all sells to the Datagrid
export const getSells = async (dateIni, dateEnd, setGridSource) => {

  let sellsTotal = {}
  const usuarios = await AxiosConfig.get("/Dashboard/GetUsers");
  // if (usuarios.data.result === "success") { setUsers(usuarios.data.data) }

  const result = await AxiosConfig.post("/Dashboard/GetSells", {
    dateIni: dateIni,
    dateEnd: dateEnd
  });
  if (result.data.result === "success") {

    //Recorremos el arreglo 
    result.data.data.forEach((venta) => {
      //Si el idUser no existe en sellsTotal entonces
      //la creamos e inicializamos el arreglo de ventas.
      if (!sellsTotal.hasOwnProperty(venta.idUser)) { sellsTotal[`${venta.idUser}`] = { ventas: {} } }

      //Agregamos los datos de total ventas.
      if (!sellsTotal[`${venta.idUser}`].ventas.hasOwnProperty("Total Ventas")) {
        sellsTotal[`${venta.idUser}`].ventas["Total Ventas"] = 0
      }

      sellsTotal[`${venta.idUser}`].ventas["Total Ventas"]++

    })
  }

  setGridSource(fetchDataTable(usuarios.data.data, sellsTotal));
}

//Info for DataGrid
export const fetchDataTable = (usuarios, ventas) => {

  const cdn = "https://cdn.bemaster.com"

  let response = []

  if (usuarios && ventas) {
    usuarios.map((user) => {
      if (ventas.hasOwnProperty(user.idUser)) {
        response.push({
          ID: user.idUser,
          Nombre: `${user.firstName} ${user.lastName}`,
          Perfil: `${cdn}${user.photo}`,
          Ventas: ventas[user.idUser].ventas["Total Ventas"]
        })
      }
    })

    return response;

  }
}

//fetchData TotalContact
export const fetchDataTotalContact = async (dateIni, dateEnd, setTotalContact, userFilter, productFilter) => {
  const result = await AxiosConfig.post("/Dashboard/GetTotalContact", {
    dateIni: dateIni,
    dateEnd: dateEnd,
    user: userFilter,
    product: productFilter
  });
  if (result.data.result === "success") {
    setTotalContact(result.data.data);
  }
};

//fetchData Recuperacion api/Dashboard/GetRecuperation
export const fetchDataRecuperacion = async (dateIni, dateEnd, SetRecuperation, userFilter, productFilter) => {
  const result = await AxiosConfig.post("/Dashboard/GetRecuperation", {
    dateIni: dateIni,
    dateEnd: dateEnd,
    user: userFilter,
    product: productFilter
  });
  if (result.data.result === "success") {
    SetRecuperation(formatPercent.format(result.data.data));
  }
};

export const getUsers = async (SetUsers) => {
  const result = await AxiosConfig.get("/Dashboard/GetUsers");
  if (result.data.result === "success") {
    const users = result.data.data.sort((a, b) => {
      if(b.firstName > a.firstName){
        return -1
      }
    })
    SetUsers(users);
  }
}

export const getProducts = async (SetProducts) => {
  const result = await AxiosConfig.get("/Values/GetProducts");
  if (result.data.result === "success") {
    SetProducts(result.data.data);
  }
}


//Function to fetch Data from Wolkvox API "AHT"
export const fetchDataAHT = (isAdmin, usuario) => {
  // if (isAdmin) {
  //   let sumaH = [],
  //     sumaM = [],
  //     sumaS = [];

  //   Data.map((dato) => {
  //     const [h, m, s] = dato.AHT.split(":");
  //     sumaH.push(parseInt(h));
  //     sumaM.push(parseInt(m));
  //     sumaS.push(parseInt(s));
  //   });

  //   let rSumaH = 0, rSumaM = 0, rSumaS = 0;

  //   sumaH.forEach((el) => { rSumaH += el; });

  //   sumaM.forEach((el) => { rSumaM += el; });

  //   sumaS.forEach((el) => { rSumaS += el; });

  //   rSumaH = Math.floor(rSumaH / sumaH.length);
  //   rSumaM = Math.floor(rSumaM / sumaM.length);
  //   rSumaS = Math.floor(rSumaS / sumaS.length);
  //   // ratingAPI();
  //   return formatAHT(rSumaH, rSumaM, rSumaS);
  // }
  // else {

  //   const uniqueData = Data.filter((res) => { return res.ID === usuario.dni; });
  //   // ratingAPI();
  //   if (uniqueData[0]) {
  //     return uniqueData[0].AHT;
  //   } else {
  //     return 0;
  //   }
  // }
  return 0;
};

//Function to fetch Data from Wolkvox API "LOGIN TIME"
export const fetchDataLoginTime = (isAdmin, usuario) => {
  // if (isAdmin) {
  //   let sumaH = 0,
  //     sumaM = 0,
  //     sumaS = 0;
  //   Data.map((dato) => {
  //     const [h, m, s] = dato["LOGIN TIME"].split(":");
  //     sumaH += parseInt(h);
  //     sumaM += parseInt(m);
  //     sumaS += parseInt(s);
  //   });

  //   return formatLoginTime(sumaH, sumaM, sumaS);
  // } else {
  //   const uniqueData = Data.filter((res) => {
  //     return res.ID === usuario.dni;
  //   });
  //   if (uniqueData[0]) {
  //     return uniqueData[0]["LOGIN TIME"];
  //   }
  //   return 0;
  // }
  return 0
};

//Function to fetch Data from Wolkvox API Encuesta "Rating"
export const fetchDataRating = async (isAdmin, usuario) => {
  const dataUser = [];
  let promedioRating = 0,
    contador = 0;

  // const calificaciones = Axios.get(urlRating).then((res) => { return res; });

  // const cbps_survey = await Axios.get(urlRating);
  // console.log(cbps_survey);
  // if (isAdmin) {
  //   cbps_survey.map((el) => {
  //     for (let i = 0; i < 3; i++) {
  //       if (Number(el[`q0${i + 1}`])) {
  //         contador++;
  //         promedioRating += parseInt(el[`q0${i + 1}`]);
  //       }
  //     }
  //   });
  //   promedioRating = parseFloat((promedioRating / contador).toFixed(1));
  // } else {
  //   cbps_survey.map((dato) => {
  //     if (dato.id_agent == usuario.idAgent) {
  //       dataUser.push(dato);
  //     }
  //   });
  //   dataUser.map((el) => {
  //     for (let i = 0; i < 3; i++) {
  //       if (Number(el[`q0${i + 1}`])) {
  //         contador++;
  //         promedioRating += parseInt(el[`q0${i + 1}`]);
  //       }
  //     }
  //   });
  //   promedioRating = parseFloat((promedioRating / contador).toFixed(1));
  // }

  return promedioRating;
};


