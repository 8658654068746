

import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Lookup, Paging, Pager
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './PaytoolTransaction.modules.css'
import { useHistory } from 'react-router-dom';
import { Button as Buton } from 'react-bootstrap';
 

const PaytoolTransactions = () => {

    const gridRef = useRef<DataGrid>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.transactionsPaytool.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [fechaInicio, setFechaInicio] = useState<string>("")
    const [fechaFinal, setFechaFinal] = useState<string>("")

    useEffect(() => {
        sessionStorage.setItem("IDTransaction", "")
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    let cambioFechaInicio = (e: any)=>{
        setFechaInicio(e.target.value)
    }

    let cambioFechaFinal = (e: any)=>{
        setFechaFinal(e.target.value)
    }

    const history = useHistory()

    let goLink = (e: any) =>{
        sessionStorage.setItem("IDTransaction", e.target.value)
        history.push('/transactionsDetailPaytool')
    }

    let Link = (data: any) =>{
        return (
            <Buton value = {data.value} variant="outline-warning" size="sm" onClick={goLink}>
            {data.value ? data.value : null}
            </Buton>
        )
    }

    const gridDataSource = {
        store: createStore({
            key: "idTransaction",
            loadUrl: `${Config.url.apiNode}paytool/transaction?fechaInicio=${fechaInicio}&fechaFinal=${fechaFinal}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
            },
        }),
    }

    return (
        <>
            {
                authorized && authorized ? <div className="escalation-mastertools-main-container">

                    <h1 className="is-size-5 m-5 center">Transacciones ingresadas desde Paytool</h1>

                    <form className="search">
                            <label className="searchLabel">Fecha inicial:</label>
                            <input type="date" name="fechaInicio" id="search" className='searchInput' placeholder="Fecha Inicial" autoComplete="off" value = {fechaInicio} onChange = {cambioFechaInicio}/>
                            <label className="searchLabel">Fecha Final:</label>
                            <input type="date" name="fechaFinal" id="search" className='searchInput' placeholder="Fecha Final" autoComplete="off" value = {fechaFinal} onChange = {cambioFechaFinal}/>
                    </form>
                    <DataGrid
                        id="grid-container-affiliations"
                        dataSource={gridDataSource}
                        keyExpr="idTransaction"
                        showBorders={true}
                        showRowLines={true}
                        focusedRowEnabled={true}
                        columnAutoWidth={true}
                        height="77vh"
                        width="90vw"
                        
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize={7} />
                        <Pager showInfo={true} />

                        <Column dataField="idTransaction" caption="Codigo de transacción" width={250} dataType="string" sortOrder={"asc"} cellRender = {Link} alignment="center"/>
                        <Column dataField="email" caption="Correo Electrónico" width={250} dataType="string" alignment="center"/>
                        <Column dataField="gateway" caption="Origen" width={150} dataType="string" alignment="center"/>
                        <Column dataField="transaction_date" caption="Fecha y hora de transacción" width={300} dataType="datetime" format="dd/MM/yy    hh:mm:ss" alignment="center"/>
                        <Column dataField="time" caption="Tiempo de transacción" dataType="string" width={200} alignment="center"/>
                        <Column dataField="statusTrans" caption="Status" dataType="string" alignment="center" width={50}/>
                        
                    </DataGrid>

                
                </div> : <></>
            }
        </>
    )
}

export default PaytoolTransactions