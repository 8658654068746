import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Lookup, Paging, Pager, Button, Editing, Texts, SearchPanel, Form, RequiredRule, Popup
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './updateSubscription.styles.scss'
import { Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';



const UpdateSubscription = () => {

    const gridRef = useRef<DataGrid>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.updateSubscription.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);


    const gridDataSource = {
        store: createStore({
            key: "idShoppingHistory",
            loadUrl: `${Config.url.apiNode}mastershop/shoppingHistory/migrationUserPaytool`,
            updateUrl:`${Config.url.apiNode}mastershop/shoppingHistory/migrationUserPaytool`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
                if (ajaxOptions.method === "PUT") {
                    ajaxOptions.timeout=60000;
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };            
            },
        }),
    }

    const onRowUpdated = (e: any) => {
        if (e.data.result) {
            console.log("EEEE", e)
            notify("Actualizado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }

    return (
        <>
            {
                authorized && authorized ? <div className="main-Affiliates-management-container">

                    <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Actualización de Suscripciones</h1>
                                </div>
                            </div>
                        </div>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idShoppingHistory"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        onRowUpdated={onRowUpdated}
                        showRowLines={true}
                        height="85vh"
                        width="90vw"
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="15" />
                        <Pager showInfo={true} />

                        <SearchPanel visible={"true"} placeholder={"Búsqueda"} searchVisibleColumnsOnly={"true"} highlightSearchText={"true"} width={"15vw"}/>

                        <Editing 
                            mode="popup" 
                            allowUpdating={true} 
                            allowAdding={true} 
                            allowDeleting={true} 
                            >
                            <Texts saveRowChanges="Guardar"/>
                            <Popup
                                title="Actualizar"
                                showTitle="true"
                                width={"50%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                            />
                            <Form colCount={1}>
                                <Item dataField="email" isRequired={true}>
                                    <RequiredRule message="El email es necesario" />
                                </Item>
                                <Item dataField="finalDate" isRequired={true}>
                                    <RequiredRule message="La fecha final es necesaria" />
                                </Item>
                                <Item dataField="amount" isRequired={true} helpText="U$D">
                                    <RequiredRule message="El monto es necesario" />
                                </Item>
                            </Form>
                        </Editing>

                         <Column type="buttons" caption="" width={"auto"}>
                             <Button text="Editar" icon="edit" name="edit"/>
                        </Column> 
                        <Column dataField="idShoppingHistory" caption="Código" dataType="string" alignment="center"/>
                        <Column dataField="email" caption="Email" dataType="string" alignment="center" allowEditing={false}/>
                        <Column dataField="idTransaction" caption="Código de transacción" dataType="string" alignment="center"/>
                        <Column dataField="purchasedPlan" caption="Plan" dataType="string" alignment="center" />
                        <Column dataField="idSuscription" caption="Suscripción N°" dataType="string" alignment="center" />
                        <Column dataField="startDate" caption="Fecha Inicio" dataType="date" alignment="center"/>
                        <Column dataField="finalDate" caption="Fecha Final" dataType="date" alignment="center"/>
                        <Column dataField="updated" caption="Actualizado" dataType="string" alignment="center">
                            <Lookup dataSource={["SI","NO"]}
                                allowClearing={true}
                                />
                        </Column>
                        <Column dataField="amount" caption="Valor de Plan" dataType="number" alignment="center" visible={false}>
                        <Lookup dataSource={["15.00","49.00","89.00","249.00","468.00","828.00","2148.00"]}
                                allowClearing={true}
                                />
                        </Column>
                    </DataGrid> 
                </div> : <></>
            }
        </>
    )
}

export default UpdateSubscription