import React, { useState } from 'react';
import { GoPrimitiveDot } from 'react-icons/go';
import NotificationContainer from '../notification-container/notification-container.component';

import './notification.styles.scss';

type props = {
    value: Boolean,
    switchGlobalState: Function
}

const Notification = ({ value, switchGlobalState }: props) => {

    const [newNotification, setNewNotification] = useState(false);

    const handleClick = () => {
        setNewNotification(false);
        switchGlobalState();
    }

    const showNewNot = (show: boolean) => {
        setNewNotification(show);
    }

    return (
        <div className="parent-container">
            <div className="child-container">
                <div className="icons-section">
                    <i className="is-notification-icon" onClick={handleClick}></i>
                    <GoPrimitiveDot style={{ display: newNotification ? 'flex' : 'none' }} />
                </div>
            </div>
            <div className="description-content">
                <div className="is-a-icon" style={{ opacity: value ? '1' : '0' }}> </div>
                <NotificationContainer allowShowComp={value} showNewNot={showNewNot} />
            </div>
        </div>
    );
};

export default Notification;