import React from 'react'
import RolTemplate from './rol-template.component';


const MasterDetailRol = (props: any) => {
    return (
        <RolTemplate props={props} />
    )
}

export default MasterDetailRol;