import React from 'react';
import './brand.styles.scss';


export const BrandLogo = ({ className = "is-brand", isHeader = true }) => {
    let brand = 'https://cdn.bemaster.com/media/images/login/Logo-BeMaster.png';
    return (
        <div className={ isHeader ? "section ml-5" : "" }>
            <figure className={ className }>
                <img src={ brand } alt="Brand" />
            </figure>
        </div>
    );
};