import React, { useContext, useState, useEffect } from 'react';
import ButtonPersonalized from '../../../../shared/components/buttons/button-personalized/button-personalized.component';
import InputBox from '../../../../shared/components/input-box/input-box.component';
import { MdClose } from 'react-icons/md';
import AxiosConfig from '../../../../axios/axiosConfig';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/auth-context';
import publicIP from 'public-ip';

import './login-credentials-section.styles.scss';

const BeMasterLoader = 'https://cdn.bemaster.com/media/images/admin/gifs/bemaster_loader.gif';

declare global {
    interface Window {
        grecaptcha: any
    }
}

const LoginCredentials = () => {

    //reCaptcha
    const SITE_KEY = "6LfrIQQaAAAAAMk-s1eb8LciigoUIYM9PPs9xILm";

    //Screens State Hooks
    const [login, setLogin] = useState(true);
    const [recovery, setRecovery] = useState(false);
    const [confirmation, setConfirmation] = useState(false);

    //Validation Hooks
    const [isCredInvalid, setIsCredInvalid] = useState(false);
    const [message, setMessage] = useState("");
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isPassEmpty, setIsPassEmpty] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [disableButton, setDisableButton] = useState<boolean>(false);
    let history = useHistory();
    const authContext = useContext(AuthContext);

    //Background variables
    const [windowBackground, setWindowBackground] = useState('#212121');

    //Clean Variables
    const cleanStates = () => {
        setIsEmailEmpty(false);
        setIsPassEmpty(false);
        setIsEmailInvalid(false);
        setIsCredInvalid(false);
        setDisableButton(false);
        setEmail("");
        setPassword("");
        setMessage("");
    }

    //Public IP
    const [ip, setIp] = useState("");
    const getClientIp = async () => await publicIP.v4({
        fallbackUrls: ["https://ifconfig.co/ip"]
    }).then(i => setIp(i));

    useEffect(() => {
        getClientIp();
    }, [ip]);

    //Google reCaptcha
    useEffect(() => {
        const loadScriptByURL = (id: string, url: string, callback: Function) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
        });
    }, []);

    //Validation Section
    const handleValidation = () => {

        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        let isEmailEmpty = email.trim() === "";
        let isPassEmpty = password.trim() === "";
        let isEmailInvalid = !expression.test(String(email).toLowerCase());

        setIsEmailEmpty(isEmailEmpty);
        setIsPassEmpty(isPassEmpty);
        setIsEmailInvalid(isEmailInvalid);

        if (!isEmailEmpty && !isEmailInvalid && !isPassEmpty) {

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then((token: any) => {
                    setDisableButton(true);
                    localStorage.clear();
                    AxiosConfig.post('/Account/Login', {
                        email: email,
                        password: password,
                        token: token,
                        ip: ip
                    }).then(response => {
                        if (response.data.result === "success") {
                            
                            let token = response.data.data.token;
                            localStorage.setItem('token', token);
                            
                            AxiosConfig.defaults.headers.common['Authorization'] = token;
                            
                            let loginInfo = authContext.loginInfo; 
                            loginInfo.authenticated = true;
                            loginInfo.moduleRoles = response.data.data.moduleRoles;
                            loginInfo.dni = response.data.data.dni;
                            loginInfo.idProfile = response.data.data.idUser;
                            loginInfo.idAgent = response.data.data.idAgent;
                            loginInfo.firstName = response.data.data.firstName
                            loginInfo.lastName = response.data.data.lastName
                            authContext.dispatch(loginInfo);
                            setDisableButton(false);
                            
                            history.push('/home');
                        }
                        else if (response.data.result === "invalidRecaptcha") {
                            setDisableButton(true);
                        }
                        else if (response.data.result === "error") {
                            setDisableButton(false);
                            let messageError = response.data.message;
                            setIsCredInvalid(true);
                            setMessage(messageError);
                        }
                    }).catch(e => {
                        setDisableButton(false);
                    });
                });
            });
        }
    }

    const handleClick = () => {
        handleValidation();
    }

    const handleOnKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            handleValidation();
        }
    }

    //Manage Screen's Display

    const handleRecoveryClick = () => {
        setLogin(!login);
        setRecovery(!recovery);
        cleanStates();
    }

    const handleConfirmation = () => {

        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        let isEmailEmpty = email.trim() === "";
        let isEmailInvalid = !expression.test(String(email).toLowerCase());

        setIsEmailEmpty(isEmailEmpty);
        setIsEmailInvalid(isEmailInvalid);

        if (!isEmailEmpty && !isEmailInvalid) {

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then((token: any) => {
                    setDisableButton(true);

                    AxiosConfig.post('/Account/RecoverPass', {
                        email: email,
                        token: token
                    }).then(response => {
                        if (response.data.result === "success") {
                            setRecovery(!recovery);
                            setConfirmation(!confirmation);
                            setWindowBackground('rgb(228 228 228)');
                        }
                        else if (response.data.result === "invalidRecaptcha") {
                            setDisableButton(true);
                        }
                        else if (response.data.result === "error") {
                            setDisableButton(false);
                            let messageError = response.data.message;
                            setIsCredInvalid(true);
                            setMessage(messageError);
                        }
                    }).catch(e => {
                        setDisableButton(false);
                    });
                });
            });
        }
    }

    const handleConfLogin = () => {
        setLogin(!login);
        setConfirmation(!confirmation);
        cleanStates();
    }

    return (
        <div className="is-main-container">
            {/* Login Section */}
            <div className="is-login-section is-mobile" style={{ display: login ? 'flex' : 'none' }}>
                <div className="is-logo-section">
                    <figure className="image">
                        <img
                            src="https://cdn.bemaster.com/media/images/login/Logo2-BeMaster-26.png"
                            alt="Logo"
                            className="image-this" />
                    </figure>
                </div>
                <div className="is-title-section mb-3">
                    <p className="has-text-white has-text-weight-bold" >I N G R E S O</p>
                </div>
                <div className="is-separator-section mb-3">
                    <span style={{ display: isCredInvalid ? 'block' : 'none' }}>
                        <p className="email-required is-size-7 has-text-weight-bold">
                            {message}
                        </p>
                    </span>
                </div>

                <div className="is-credentials-section">
                    <p className="is-credential-title has-text-white is-size-7 has-text-weight-bold">
                        Correo electrónico
                    </p>
                    <InputBox
                        value={email}
                        type="email"
                        placeHolder='ejemplo@correo.com'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value) }}
                    />

                    <span className="mb-2" style={{ display: isEmailInvalid && !isEmailEmpty ? 'block' : 'none' }}>
                        <p className="email-required is-size-7 has-text-weight-bold">
                            Correo inválido
                        </p>
                    </span>

                    <span className="mb-2" style={{ display: isEmailEmpty ? 'block' : 'none' }}>
                        <p className="email-required is-size-7 has-text-weight-bold">
                            El correo es necesario
                        </p>
                    </span>

                    <p className="is-credential-title has-text-white is-size-7 has-text-weight-bold">
                        Contraseña
                    </p>
                    <InputBox
                        value={password}
                        type='password'
                        keyPressed={(e:any) => handleOnKeyPress(e)}
                        placeHolder='••••••••'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value) }}
                    />

                    <span className="mb-2" style={{ display: isPassEmpty ? 'block' : 'none' }}>
                        <p className="email-required is-size-7 has-text-weight-bold">
                            La contraseña es necesaria
                        </p>
                    </span>

                </div>
                <div className="is-login-button-section">
                    <ButtonPersonalized
                        label='I N G R E S A R'
                        onClick={() => handleClick()}
                        disabled={disableButton}
                    />
                </div>
                <div className="is-register-section">
                    <span><a className="has-text-white" href="https://bemaster.com" target="_blank">¿Aún no eres miembro? Regístrate </a></span><br />
                    <span><a className="has-text-white" onClick={handleRecoveryClick}>No recuerdo mi contraseña</a></span>
                    <div className="has-loader" style={{ display: disableButton? 'flex' : 'none' }}>
                        <img src={BeMasterLoader} alt="BeMasterLoader" />
                    </div>
                </div>
            </div>

            {/* Recovery Section */}
            <div className="is-login-section recovery" style={{ display: recovery ? 'flex' : 'none' }}>

                <div className="exit-button-section is-flex mr-3" onClick={handleRecoveryClick}>
                    <span className="close-icon"><MdClose /></span>
                </div>

                <div className="is-title-section rec-sec mb-3">
                    <p className="has-text-white has-text-weight-bold is-size-4 mb-3"> RECUPERAR CONTRASEÑA</p>
                </div>
                <div className="is-separator-section mb-3">
                    <span style={{ display: isCredInvalid ? 'block' : 'none' }}>
                        <p className="email-required is-size-7 has-text-weight-bold">
                            {message}
                        </p>
                    </span>
                </div>

                <div className="is-credentials-section">
                    <p className="is-credential-title has-text-white is-size-7 has-text-weight-bold">
                        Correo electrónico
                    </p>
                    <InputBox
                        type="email"
                        placeHolder='ejemplo@correo.com'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value) }}
                    />

                    <span className="mb-2" style={{ display: isEmailInvalid && !isEmailEmpty ? 'block' : 'none' }}>
                        <p className="email-required is-size-7 has-text-weight-bold">
                            Correo inválido
                        </p>
                    </span>

                    <span className="mb-2" style={{ display: isEmailEmpty ? 'block' : 'none' }}>
                        <p className="email-required is-size-7 has-text-weight-bold">
                            El correo es necesario
                        </p>
                    </span>

                    <div className="is-login-button-section">
                        <ButtonPersonalized
                            label='ENVIAR AL CORREO'
                            onClick={() => handleConfirmation()}
                            disabled={disableButton}
                        />
                    </div>
                    <div className="is-register-section remind-credentials" onClick={handleRecoveryClick} >
                        <span><a className="has-text-white">¿Recuerdas tu contraseña? </a></span>
                        <span><a className="has-text-white styled ">Ingresa </a></span>
                    </div>
                </div>

            </div>

            {/* Confirmation Section */}
            <div className="is-login-section recovery confirm is-mobile"
                style={{
                    display: confirmation ? 'flex' : 'none',
                    backgroundColor: windowBackground
                }}>

                <div className="exit-button-section is-flex mr-3" onClick={handleConfLogin}>
                    <span className="close-icon"><MdClose /></span>
                </div>

                <div className="is-title-section confirm mb-3">
                    <p className="has-text-weight-bold is-size-4" >RECUPERAR CONTRASEÑA</p>
                </div>

                <div className="is-confirm-section px-5">
                    <p className="mb-5" >
                        Si tienes una cuenta existente, te enviáremos un correo con el link para recuperar tu contraseña
                    </p>
                </div>

                <div className="is-register-section remind-credentials" onClick={handleConfLogin} >
                    <span><a>¿Recuerdas tu contraseña? </a></span>
                    <span><a className="styled">Ingresa </a></span>
                </div>

            </div>

        </div>
    );
};

export default LoginCredentials;