import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './routers/AppRouter';
import 'devextreme/dist/css/dx.common.css';
import './shared/styles/dx.material.bemaster-custom-scheme.css';
import { Provider } from 'react-redux'
import { store } from './app/store'

// import 'devextreme/dist/css/dx.light.css';
import themes from 'devextreme/ui/themes';


import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

themes.initialized(() => ReactDOM.render(
  <Provider store={store}>
    <Router>
      <AppRouter />  
    </Router>
  </Provider>,
  document.getElementById('root')
));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
