import React, { useContext, useEffect, useRef, useState } from 'react';
import DataGrid, { Button, Column, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import Scrollbar from 'react-scrollbars-custom';
import { osadoDataSource, guardianDataSource, heroicoDataSource, legendarioDataSource, deliveryReward } from './sourceData';
import Toast from 'devextreme-react/toast';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './Affiliates-rewards.styles.scss';
import ContainerGrids from './container-grids.component';


const scrollHeight = (window.innerHeight - 50);
const scrollWidth = (window.innerWidth - 65);


const AffiliatesRewards = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.affiliatesRewards.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        message: ''
    });
    const renderSells = (e: any) => {
        const response = formatUSD.format(e.value);
        return <> {response} </>;
    }

    //Modificación de número a USD
    const optionsUSD = { style: "currency", currency: "USD" };
    const formatUSD = new Intl.NumberFormat("en-US", optionsUSD);

    //hide toast
    const onHiding = () => {
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }

    const addReward = async (e: any) => {

        const rowValue = e.row.data;
        console.log('rowValue', rowValue.leadEmail);

        const billing = rowValue.billing;

        if (billing >= 5000 && billing < 25000) {

            const result = await deliveryReward(rowValue.leadEmail, 1, new Date());

            if (result.data.result === "success") {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: "Se ha registrado correctamente la entrega"
                });
                e.component.refresh();
            }

        } else if (billing >= 25000 && billing < 50000) {

            const result = await deliveryReward(rowValue.leadEmail, 2, new Date());

            if (result.data.result === "success") {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: "Se ha registrado correctamente la entrega"
                });
                e.component.refresh();
            }

        } else if (billing >= 50000 && billing < 100000) {

            const result = await deliveryReward(rowValue.leadEmail, 3, new Date());

            if (result.data.result === "success") {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: "Se ha registrado correctamente la entrega"
                });
                e.component.refresh();
            }

        } else if (billing >= 100000) {

            const result = await deliveryReward(rowValue.leadEmail, 4, new Date());

            if (result.data.result === "success") {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: "Se ha registrado correctamente la entrega"
                });
                e.component.refresh();
            }
        }
    }

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);


    return (
        <>
            {
                authorized && authorized ?
                    <Scrollbar style={{ height: scrollHeight, width: scrollWidth }}>
                        <>
                            <Toast
                                visible={toastConfig.isVisible}
                                message={toastConfig.message}
                                type="info"
                                onHiding={onHiding}
                                displayTime={1200}
                            />
                            <div className="container-rewards">
                                <div className="rewards-table-container">
                                    <h1>Recompensas Afiliados</h1>
                                    <div className="reward-table">
                                        <table>
                                            <tbody>

                                                <tr className="tittleTable">
                                                    <th colSpan={3}>Tabla de Recompensas</th>
                                                </tr>
                                                <tr>
                                                    <th>Categoría</th>
                                                    <th>Rango de facturación</th>
                                                    <th>Reompensa</th>
                                                </tr>
                                                <tr>
                                                    <td>Embajador Osado</td>
                                                    <td>{formatUSD.format(5000)} - {formatUSD.format(24999)}</td>
                                                    <td>1000 fuegos para hacer compras de contenido exclusivo al interior de la plataforma BeMaster</td>
                                                </tr>
                                                <tr>
                                                    <td>Embajador Guardian</td>
                                                    <td>{formatUSD.format(25000)} - {formatUSD.format(49999)}</td>
                                                    <td>Seis meses de suscripción gratuita a MasterTools (Plan Master)</td>
                                                </tr>
                                                <tr>
                                                    <td>Embajador Heroico</td>
                                                    <td>{formatUSD.format(50000)} - {formatUSD.format(99999)}</td>
                                                    <td>Comisión preferencial del 70% durante 3 meses en todos los productos de BeMaster con afiliación activa</td>
                                                </tr>
                                                {/* <tr>
                                        <td>Embajador Legendario</td>
                                        <td>{formatUSD.format(100000)}</td>
                                    </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="container-content">
                                    <div className="containerGrid">
                                        <div className="tittleGrid">Embajador Osado</div>
                                        <ContainerGrids type="pending" addReward={addReward} renderSells={renderSells} menor={5000} mayor={24900} idReward={1} />
                                    </div>

                                    <div className="containerGrid">
                                        <div className="tittleGrid">Embajador Guardian</div>
                                        <ContainerGrids type="pending" addReward={addReward} renderSells={renderSells} menor={25000} mayor={49900} idReward={2} />
                                    </div>

                                    <div className="containerGrid">
                                        <div className="tittleGrid">Embajador Heroico</div>
                                        <ContainerGrids type="pending" addReward={addReward} renderSells={renderSells} menor={50000} mayor={99999} idReward={1} />
                                    </div>

                                    <div className="delivery-rewards">
                                        <ContainerGrids type="delivery" renderSells={renderSells} />
                                    </div>
                                </div>
                            </div>
                        </>
                    </Scrollbar> : <></>
            }
        </>
    )
}

export default AffiliatesRewards;