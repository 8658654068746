import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, Pager, Paging, Popup, RequiredRule, Texts } from 'devextreme-react/data-grid';
import FileUploader from 'devextreme-react/file-uploader';
import { Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { Config } from '../../Config';
import { productDataSource, usersDataSource } from '../../shared/dataSources/data-source.data';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';

const gridDataSource = {
    store: createStore({
        key: "idLeadPayment",
        insertUrl: `${Config.url.api}/administrative/insertAlternativePayment`,
        updateUrl: `${Config.url.api}/administrative/updateAlternativePayment`,
        loadUrl: `${Config.url.api}/administrative/GetAlternativePayment/0`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 
                Authorization: localStorage.getItem("token"),
                DataType: "application/json" 
            };
        },
    }),
}

const AlternativePayment = () => {

    const gridRef = useRef<DataGrid>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.alternative_payment.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [roleType, setRoleType] = useState<string>("");


    // getting permissions for current module

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
            response.map((permissionsAvailable: any) => {
                setRoleType(permissionsAvailable.role.roleType);
                return true;
            });
        }
    }, []);

    const onRowInserted = (e: any) => {
        if (e.data.result === "success") {
            notify(e.data.message);
        } else {
            notify(e.data.message, 'error');
        }
    }

    const onRowUpdated = (e: any) => {
        if (e.data.result === "success") {
            notify(e.data.message);
        } else {
            notify(e.data.message, 'error');
        }
    }

    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
            console.error("error subiendo archivo", e.error.responseText);
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
            console.error("error en la conexión", e.error.responseText);
        }
        notify("error subiendo archivo", 'error');
    }, []);

    const uploadedFile = useCallback((e, cellInfo) => {
        cellInfo.setValue(JSON.parse(e.request.response).data);
        notify(JSON.parse(e.request.response).message);
    }, []);

    const editCellRender = (cellInfo: any) => {
        return (
            <>
                <FileUploader
                    multiple={false} accept="image"
                    uploadMode="instantly"
                    maxFileSize={1000000}
                    uploadUrl={
                        `${Config.url.api}/administrative/UploadVoucher`
                    }
                    uploadHeaders={
                        {
                            'Authorization': localStorage.getItem('token')
                        }
                    }
                    onUploadError={onUploadError}
                    onUploaded={e => uploadedFile(e, cellInfo)} />
            </>
        )
    }

    const Link = (data: any) => {
        return data.value ? <a href={data.value} target='_blank' rel="noopener noreferrer"> Ver evidencia </a> : <> Sin evidencia </>
    }

    return (
        <>
            {
                authorized && authorized ? <div className="alternative-payment-main-container">

                    <h1 className="is-size-5 m-5 center">Pagos Alternativos</h1>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idLeadPayment"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        showRowLines={true}
                        onRowInserted={onRowInserted}
                        onRowUpdated={onRowUpdated}
                        height="87vh"
                        width="94.5vw"
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="20" />
                        <Pager showInfo={true} />

                        <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                            <Texts saveRowChanges="Guardar" />
                            <Popup
                                title="Payment Info"
                                showTitle="true"
                                width={"70%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                            />
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item dataField="name" isRequired={true} >
                                        <RequiredRule message="El nombre del cliente es necesario" />
                                    </Item>
                                    <Item dataField="email" isRequired={true}>
                                        <RequiredRule message="El correo del cliente es necesario" />
                                    </Item>
                                    <Item dataField="phone" isRequired={true}>
                                        <RequiredRule message="El teléfono del cliente es necesario" />
                                    </Item>
                                    <Item dataField="idProduct" isRequired={true}>
                                        <RequiredRule message="Debes indicar el producto del pago" />
                                    </Item>
                                    <Item dataField="amount" isRequired={true}>
                                        <RequiredRule message="Es necesario indicar el valor pagado" />
                                    </Item>
                                    <Item dataField="currency" isRequired={true}>
                                        <RequiredRule message="Debes seleccionar una moneda" />
                                    </Item>
                                    <Item dataField="amountUSD" isRequired={true}>
                                        <RequiredRule message="Indica el valor en US $" />
                                    </Item>
                                    <Item dataField="typePayment" isRequired={true}>
                                        <RequiredRule message="Indica el tipo de compra" />
                                    </Item>
                                    <Item dataField="method" isRequired={true}>
                                        <RequiredRule message="Indica el tipo de compra" />
                                    </Item>
                                </Item>
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item dataField="isAffiliate" />
                                    <Item dataField="validated" />
                                </Item>
                                <Item itemType="group" colCount={1} colSpan={2}>
                                    <Item dataField="voucher" isRequired={true}>
                                        <RequiredRule message="Adjunta el comprobante del pago" />
                                    </Item>
                                </Item>
                                <Item itemType="group" colCount={2} colSpan={2} >
                                    <Item
                                        dataField="observations"
                                        editorType="dxTextArea"
                                        colSpan={2}
                                        editorOptions={{ height: 100, maxLength: 1000 }}
                                    />
                                </Item>
                            </Form>
                        </Editing>

                        <Column type="buttons" caption="Acciones" width={80}>
                            <Button name="edit" text="Editar" />
                        </Column>
                        <Column dataField="idLeadPayment" caption="# Pago Alternativo" dataType="number" visible={false} />
                        <Column dataField="typePayment" caption="Tipo de compra" dataType="string" >
                            <Lookup
                                dataSource={["Nueva", "Renovación"]}
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="isAffiliate" caption="Compra de afiliado" dataType="boolean" />
                        <Column dataField="date" caption="Fecha del pago" dataType="datetime" format="yyyy-MM-dd" />
                        <Column dataField="name" caption="Nombre cliente" dataType="string" />
                        <Column dataField="email" caption="email cliente" dataType="string" />
                        <Column dataField="phone" caption="Teléfono Cliente" dataType="string" />
                        <Column dataField="idProduct" caption="Producto" dataType="string" width="130" >
                            <Lookup
                                dataSource={productDataSource}
                                valueExpr="idProduct"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="method" caption="Método de pago" dataType="string">
                            <Lookup
                                dataSource={['BTC', 'Bancolombia', 'Hotmart', 'LeadGods', 'Payonner', 'PayPal']}
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="voucher" caption="Comprobante de pago" dataType="string" editCellRender={editCellRender} cellRender={Link} />
                        <Column dataField="amount" caption="Valor pagado" dataType="string" />
                        <Column dataField="currency" caption="Moneda de pago" dataType="string" >
                            <Lookup
                                dataSource={['BTC', 'BOLIVIANO', 'COP', 'USD']}
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="amountUSD" caption="Cantidad en dólares" dataType="string" />
                        <Column dataField="validated" caption="Validación" dataType="boolean" allowEditing={roleType === "admin"} />
                        <Column dataField="observations" caption="Observaciones" dataType="string" width="700" />
                        <Column dataField="idUser" caption="Responsable" dataType="string" >
                            <Lookup
                                dataSource={usersDataSource}
                                valueExpr="idUser"
                                displayExpr="firstName"
                                allowClearing={true}
                            />
                        </Column>
                    </DataGrid>

                </div> : <></>
            }
        </>
    )
}

export default AlternativePayment;