import React from 'react'
import DeliveryRewards from './delivery-rewards.component';
import PendingRewards from './pending-rewards.component';

const ContainerGrids = ({ addReward, renderSells, menor, mayor, idReward, type }: any) => {
    return (
        <>
            {type === "pending" ? <>
                <PendingRewards addReward={addReward} renderSells={renderSells} menor={menor} mayor={mayor} idReward={idReward} />
            </> : <>
                <DeliveryRewards renderSells={renderSells} />
            </>
            }
        </>
    )
}

export default ContainerGrids;