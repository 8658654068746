import React, { useContext, useEffect, useState } from 'react'
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import AxiosConfig from "../../axios/axiosConfig";
import SelectBox from 'devextreme-react/select-box';
import { careersDataSource } from '../ads-management/ads-management.data';
import notify from 'devextreme/ui/notify';
import './notification-bm-management.styles.scss'
import { AuthContext } from '../auth/components/context/auth-context';
import { modules } from '../../shared/modules';

const loader = 'https://cdn.bemaster.com/media/images/admin/gifs/bemaster_loader.gif';

const Notification_bm_management = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.notification_bm.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [dataForm, setDataForm] = useState<any>({
        text: "",
        url: "",
        idCareer: null
    });

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    const showPopUp = () => {
        setPopUpVisible(true);
    }

    //hide popUp
    const hideInfo = () => {
        setPopUpVisible(false);
        setDataForm({
            text: "",
            url: "",
            idCareer: null
        });
    }

    const validateData = async () => {
        let validate = true;
        const keys = Object.keys(dataForm);
        await keys.map((key) => {
            if (!dataForm[key]) {
                notify("Debes llenar toda la información", "error");
                validate = false;
                return;
            }
        });
        return validate
    }

    const sendRequest = async () => {
        const response = await AxiosConfig.post("/Notification/insertIntoBeMaster", {
            text: dataForm.text,
            url: dataForm.url,
            idCareer: dataForm.idCareer
        });
        if (response.data.result === "success") {
            notify(response.data.message);
            hideInfo();
        } else {
            notify(response.data.message, "error");
            hideInfo();
        }
    }

    const sendNotification = async () => {
        if (await validateData()) {
            // console.log(dataForm);
            sendRequest();
        }
    }

    const solicitudButtonOptions = {
        text: 'Enviar Notificaciones',
        onClick: sendNotification
    }

    const handleChange = (e: any) => {
        const element = e.target ? e.target : e.element;
        const value = e.target ? e.target.value : e.value;
        setDataForm({ ...dataForm, [`${element.id}`]: value });
    }

    const renderPopUp = () => {
        return (
            <>
                {
                    authorized && authorized ?
                        <div className="containerForm">
                            <div className="rowForm">
                                <div className="itemForm">
                                    <span className="labelForm">URL de la notificación</span>
                                    <input type="text" value={dataForm.url} autoComplete="off" placeholder="https://..." id="url" className="inputForm" onChange={handleChange} maxLength={500} />
                                </div>
                                <div className="itemForm">
                                    <span className="labelForm">Carrera:</span>
                                    <SelectBox
                                        id="idCareer"
                                        dataSource={careersDataSource}
                                        defaultValue={0}
                                        displayExpr="name"
                                        valueExpr="idCareer"
                                        showClearButton={true}
                                        placeholder="Selecciona una carrera"
                                        searchEnabled={true}
                                        onValueChanged={handleChange}
                                        width="50%"
                                    />
                                </div>
                            </div>
                            <div className="rowForm">
                                <div className="itemForm">
                                    <span className="labelForm">Texto de la notificación</span>
                                    <textarea
                                        value={dataForm.text}
                                        autoComplete="off"
                                        id="text"
                                        className="textAreaForm"
                                        onChange={handleChange} maxLength={200} />
                                </div>
                            </div>
                        </div> :
                        <></>
                }
            </>
        )
    }


    return (
        <>
            < Popup
                visible={popUpVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title="Información de la notificación"
                container=".dx-viewport"
                width={"70vw"}
                height={"auto"}
                maxHeight={"95vh"}
                position="center"
                contentRender={renderPopUp}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="center"
                    options={solicitudButtonOptions}
                    disabled={disable}
                    visible={!disable}
                />
            </Popup>


            <div className="notification-bm-management-container">
                <div className="container-button">
                    <a href="#" onClick={showPopUp} >Ingresar Notificación</a>
                </div>
            </div>
        </>
    )
}

export default Notification_bm_management;