import React from 'react';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, FilterRow, HeaderFilter, Lookup, MasterDetail, Pager, Paging } from 'devextreme-react/data-grid';
import { Config } from '../../Config';


const InfoTab = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idUser",
            loadUrl: `${Config.url.api}/userBm/GetUserBm/${props.dataInfo.idUser}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
            },
        }),
    };

    return (
        <div className="info-tab-container">
            <div className="image-info-tab-container">
                <img src={props.dataInfo.photo ? props.dataInfo.photo : "https://cdn.bemaster.com/media/images/admin/assets/images/login/Logo2-BeMaster-26.png"} alt="ProfilePhoto" />
            </div>
            <div className="about-info-tab-container">
                <p>{props.dataInfo.about ? props.dataInfo.about : "No hay descripción aún"}</p>
            </div>
            <div className="dataGrid-info-tab-container">
                <DataGrid
                    id="dataGrid-info-tab"
                    dataSource={gridDataSource}
                    showBorders={true}
                    keyExpr="idUser"
                    allowColumnReordering={true}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    focusedRowEnabled={true}
                    showRowLines={true}
                    width="100%"
                >
                    <Column dataField="address" caption="Dirección" dataType="string" />
                    <Column dataField="country" caption="País" dataType="string" />
                    <Column dataField="state" caption="Departamento" dataType="string" />
                    <Column dataField="city" caption="Ciudad" dataType="string" />
                    <Column dataField="alias" caption="Alias" dataType="string" />
                    {/* <Column dataField="about" caption="Descripción" dataType="string" /> */}
                    <Column dataField="current_plan" caption="Plan Actual" dataType="string" />
                    <Column dataField="status_plan" caption="Estado del Plan" dataType="string" />
                    <Column dataField="subscriber_code" caption="Código de subscripción" dataType="string" />
                    <Column dataField="gender" caption="Género" dataType="string" />
                    <Column dataField="youtube" caption="youtube" dataType="string" />
                    <Column dataField="facebook" caption="facebook" dataType="string" />
                    <Column dataField="instagram" caption="instagram" dataType="string" />
                </DataGrid>
            </div>
        </div>
    )
}

export default InfoTab;