import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DataGrid, Column, Button, Popup, Editing, MasterDetail, Lookup, Texts, Form, RequiredRule } from 'devextreme-react/data-grid';
import { Config } from '../../Config';
import {
    statusCommunitySource, onValueChanged, statusCommunityActionSource
} from './leads-management.data';
import logTemplate from './logClients-template.component'
import FileUploader from 'devextreme-react/file-uploader';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Item } from 'devextreme-react/form';
import { productDataSource } from '../../shared/dataSources/data-source.data';

const loader = 'https://cdn.bemaster.com/media/images/admin/gifs/bemaster_loader.gif';

const LeadsCareerTemplate = (props: any) => {

    //references
    const fileUploaderRef = useRef<FileUploader>(null);
    const gridRef = useRef<DataGrid>(null);

    const [status, setStatus] = useState("");

    const gridDataSource = {
        store: createStore({
            key: "idLeadAction",
            loadUrl: `${Config.url.api}/Client/GetClients/${props.props.data.data.idLead}`,
            updateUrl: `${Config.url.api}/Client/editLogLeadAction`,
            insertUrl: `${Config.url.api}/Clients/AddStudentCareer`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idLead: props.props.data.data.idLead };
            },
        }),
    };

    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
            console.error("error subiendo archivo", e.error.responseText);
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
            console.error("error en la conexión", e.error.responseText);
        }
    }, []);

    const editCanceled = (e: any) => {
        setStatus("editing");
    }

    const newRow = (e: any) => {
        setStatus("adding");
    }

    const uploadedFile = useCallback((e, cellInfo) => {
        console.log("response img", e.request.response);
        cellInfo.setValue(e.request.response);
    }, []);

    const editCellRender = (cellInfo: any) => {
        // console.log("editCellRender", e.data.idLeadAction)
        return (
            <>
                <FileUploader
                    ref={fileUploaderRef}
                    multiple={false} accept="*"
                    uploadMode="instantly"
                    uploadUrl={
                        `${Config.url.api}/Client/uploadEvidence`
                    }
                    uploadHeaders={
                        {
                            'Authorization': localStorage.getItem('token'),
                            'keyRequest': cellInfo.data.idLeadAction
                        }
                    }
                    onValueChanged={onValueChanged}
                    onUploadError={onUploadError}
                    onUploaded={e => uploadedFile(e, cellInfo)} />
            </>
        )
    }

    useEffect(() => {
        console.log(status);
    }, [status]);

    return (
        <>
            <DataGrid
                ref={gridRef}
                dataSource={gridDataSource}
                showBorders={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                onEditCanceled={editCanceled}
                onInitNewRow={newRow}
            >
                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Editar Afiliación"
                        showTitle="true"
                        width={status === "editing" ? "70%" : "40%"}
                        height={status === "editing" ? "80%" : "50%"}
                    />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2} visible={status === "editing"} >
                            <Item dataField="nameCommunity" />
                            <Item dataField="linkCommunity" />
                            <Item dataField="idStatus" />
                            <Item dataField="idStatusAction" />
                            <Item dataField="transaction" />
                        </Item>
                        <Item itemType="group" colCount={2} colSpan={2} visible={status === "editing"} >
                            <Item dataField="description" editorType="dxTextArea" colSpan={2} editorOptions={{ height: 100 }} />
                        </Item>
                        <Item itemType="group" colCount={2} colSpan={2} visible={status === "editing"} >
                            <Item dataField="evidence" />
                        </Item>
                        <Item itemType="group" colCount={1} colSpan={2} visible={status === "adding"} >
                            <Item dataField="idProduct" />
                        </Item>
                    </Form>
                </Editing>
                <Column type="buttons" width={"auto"}>
                    <Button name="edit" text="Editar" visible={true} />
                </Column>
                <Column dataField="idLeadAction" caption="idLeadAction" visible={false} />
                <Column dataField="idLead" caption="idLead" visible={false} />
                <Column dataField="idStatus" caption="Estado" visible={false} >
                    <Lookup
                        dataSource={statusCommunitySource}
                        valueExpr="idStatusAction"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField="nameProduct" caption="Producto" />
                <Column dataField="date" dataType="date" format="yyyy-MM-dd" caption="Fecha de compra" alignment="center" />
                <Column dataField="transaction" caption="Código de Transacción" />
                <Column dataField="nameCommunity" caption="Nombre en la comunidad" />
                <Column dataField="linkCommunity" caption="Perfil de la comunidad" />
                <Column dataField="idStatusCommunity" caption="Estado en la comunidad" dataType="string" >
                    <Lookup
                        dataSource={statusCommunitySource}
                        valueExpr="idStatusAction"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField="idStatusAction" caption="Acción" dataType="string" visible={false} >
                    <Lookup
                        dataSource={statusCommunityActionSource}
                        valueExpr="idStatusAction"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField="idProduct" caption="Producto" dataType="number" visible={false} >
                    <Lookup
                        dataSource={productDataSource}
                        valueExpr="idProduct"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField="description" caption="Observaciones" dataType="string" visible={false} />
                <Column dataField="evidence" caption="Evidencia" editCellRender={editCellRender} visible={false} />
                <MasterDetail
                    enabled={true}
                    component={logTemplate}
                />

            </DataGrid>
        </>
    );

}
export default LeadsCareerTemplate;