import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Lookup, Paging, Pager, Button, Editing, Texts, SearchPanel, Form, RequiredRule, Popup
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './paytool-transactions-detail.styles.scss'
import './PaytoolTransaction.modules.css'
import { Popup as PopUp } from 'devextreme-react/popup';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { Button as Buton } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';



const ScheduledPayments = () => {

    const gridRef = useRef<DataGrid>(null);
    const searchEmailRef = useRef<HTMLInputElement>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.scheduledPayments.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    let [currentUser, setCurrentUser] = useState<any>({})

    const [searchEmail, setSearchEmail] = useState<string | null>("");

    const [fechaInicio, setFechaInicio] = useState<string>("")
    const [fechaFinal, setFechaFinal] = useState<string>("")

    let cambioFechaInicio = (e: any)=>{
        setSearchEmail("")
        setFechaInicio(e.target.value)
    }

    let cambioFechaFinal = (e: any)=>{
        setSearchEmail("")
        setFechaFinal(e.target.value)
    }

    const changeEmailSearch = (e: any) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            setSearchEmail("")
        }
    }

    const searchEmailClick = () => {
        const valor = searchEmailRef.current?.value;
        if (valor === "" || valor === undefined) {
            setSearchEmail("");
        } else {
            setSearchEmail(valor);
        }
    }

    const [popUpVisible, setPopUpVisible] = useState(false)
    const showPopUp = () =>{
    setPopUpVisible(true)
    }
    const hidePopUp = () =>{
    setPopUpVisible(false)
    }

    useEffect(() => {
        sessionStorage.setItem("IDTransaction", "")
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);


    const gridDataSource = {
        store: createStore({
            key: "idPayment",
            updateUrl:`${Config.url.apiNode}paytool/ScheduledPayments`,
            loadUrl: `${Config.url.apiNode}paytool/ScheduledPayments?fechaInicio=${fechaInicio}&fechaFinal=${fechaFinal}&email=${searchEmail}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
                if (ajaxOptions.method === "PUT") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };      
            },
        }),
    }

   


    let Link = (data: any) =>{
        return (
        <Buton variant="outline-warning" size="sm" onClick={()=>{
            setCurrentUser(data.value)
            showPopUp() 
        }}>
        {data.value ? "Ver" : null}
        </Buton>
        )
        }

    const history = useHistory()

    let goLink = (e: any) =>{
        sessionStorage.setItem("IDTransaction", e.target.value)
        history.push('/transactionsDetailPaytool')
    }

    const LinkToTransactions= (data: any) => {
        return data.value ?
         (
                <Buton value = {data.value} variant="outline-warning" size="sm" onClick={goLink}>
                {data.value ? data.value : null}
                </Buton>
            )
        : ""
    }

    const onRowUpdated = (e: any) => {
        if (e.data.result) {
            notify("Actualizado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }

    return (
        <>
            {
                authorized && authorized ? <div className="main-Affiliates-management-container">

                    <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Pagos Programados Paytool</h1>
                                </div>
                            </div>
                        </div>

                    <form className="search">
                        <label className="searchLabel">Búsqueda por Email:</label>
                        <input type="text" name="search" placeholder="Email" autoComplete="off" ref={searchEmailRef} onChange={changeEmailSearch} />
                        <div className="searchButton" onClick={searchEmailClick} > <BiSearchAlt2 className="search-icon" /> </div>
                        <label className="searchLabel">Fecha inicial:</label>
                        <input type="date" name="fechaInicio" id="search" className='searchInput' placeholder="Fecha Inicial" autoComplete="off" value = {fechaInicio} onChange = {cambioFechaInicio}/>
                        <label className="searchLabel">Fecha Final:</label>
                        <input type="date" name="fechaFinal" id="search" className='searchInput' placeholder="Fecha Final" autoComplete="off" value = {fechaFinal} onChange = {cambioFechaFinal}/>
                    </form>

                    <PopUp
                    visible={popUpVisible}
                    onHiding={hidePopUp}
                    showTitle={true}
                    title="INFORMACION ADICIONAL DEL USUARIO"
                    width={600}
                    height={650}
                    >

                    <div style={{borderBottom: "0.5px solid white", marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Nombre: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.name}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Email: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.email}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Estado: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.state}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Dirección: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.adress}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Número de calle: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.street_number}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Código Postal: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.zipcode}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Teléfono: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.phone_number}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>País: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.country}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Tipo de Pago: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.payment_type}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Tarjeta: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.payment_type_code}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Últimos 4 número de la tarjeta de crédito: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.creditcard?.last_four}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Fecha de expiración de la tarjeta de Crédito: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.creditcard?.exp_date}</span>
                    </div>
                    <div style={{borderBottom: "0.5px solid white",  marginTop: "4px"}}>
                        <span style={{color:'goldenrod', fontSize: "1.2rem"}}>Documento: </span>
                        <span style={{fontSize: "1.2rem"}}>{currentUser?.document}</span>
                    </div>
                    </PopUp>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idPayment"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        onRowUpdated={onRowUpdated}
                        showRowLines={true}
                        height="75vh"
                        width="90vw"
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="15" />
                        <Pager showInfo={true} />

                        <Editing mode="popup" allowUpdating={true} allowAdding={false} >
                            <Texts saveRowChanges="Guardar" />
                            <Popup
                                title="Editar Pago Programado"
                                showTitle="true"
                                width={"40%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                            />
                            <Form >
                                <Item dataField="email" isRequired={true} disabled={true} >
                                    <RequiredRule message="El email del cliente es necesario" />
                                </Item>
                                <Item dataField="date" isRequired={true} >
                                    <RequiredRule message="La fecha de cobro es necesaria" />
                                </Item>
                                <Item dataField="state" isRequired={true} >
                                    <RequiredRule message="El estado es necesario" />
                                </Item>
                            </Form>
                        </Editing>

                        <Column type="buttons" caption="Acciones" width={80}>
                            <Button name="edit" text="Editar" />
                        </Column>
                        <Column dataField="idPayment" caption="Código de Pago" dataType="string" alignment="center"/>
                        <Column dataField="email" allowEditing={false} caption="Correo Electrónico" dataType="string" alignment="center"/>
                        <Column dataField="date" caption="Fecha de Cobro" dataType="date" format="dd/MM/yy  hh:mm:ss" alignment="center"/>
                        <Column dataField="typePayment" caption="Tipo de pago" dataType="string" alignment="center"/>
                        <Column dataField="state" caption="Estado" dataType="string" alignment="center">
                            <Lookup
                            dataSource={["CANCELED", "EXECUTED", "PENDING"]}
                            allowClearing={true}
                            />
                        </Column>
                        <Column dataField="gateway" caption="Procesador" dataType="string" alignment="center"/>
                        <Column dataField="idSuscription" caption="Código de Suscripción" dataType="string" alignment="center"/>
                        <Column dataField="idTransaction" caption="Código de Transacción" dataType="string" alignment="center" cellRender={LinkToTransactions}/>
                        <Column dataField="extraInfo" caption="Información Adicional" dataType="string" alignment="center" cellRender={Link} />
                        <Column dataField="createdAt" caption="Fecha de Creación" dataType="date" format="dd/MM/yy  hh:mm:ss" alignment="center"/>
                        <Column dataField="updatedAt" caption="Fecha de Modificación" dataType="date" format="dd/MM/yy  hh:mm:ss"  alignment="center"/>

                        
                    </DataGrid>

                
                </div> : <></>
            }
        </>
    )
}

export default ScheduledPayments