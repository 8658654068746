export const SideBarData = [
    {
        title: 'INICIO',
        path: '/home',
        icon: 'https://cdn.bemaster.com/media/icons/admin/home.svg',
        cName: '',
        key: 0
    },
    {
        title: 'INDICADORES',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/bar-chart.svg',
        cName: '',
        key: 1
    },
    {
        title: 'CLIENTES',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/headset-outline.svg',
        cName: '',
        key: 2
    },
    {
        title: 'AFILIADOS',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/network.svg',
        cName: '',
        key: 3
    },
    {
        title: 'CONTENIDO',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/content-request.svg',
        cName: '',
        key: 4
    },
    {
        title: 'GESTION HUMANA',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/human-resources.svg',
        cName: '',
        key: 5
    },
    {
        title: 'BEMASTER',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/bemaster.svg',
        cName: '',
        key: 6
    },
    {
        title: 'MASTERTOOLS',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/MasterTools.svg',
        cName: '',
        key: 7
    },
    {
        title: 'COMIZZION',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/comizzion.svg',
        cName: '',
        key: 8
    },
    {
        title: 'PAYTOOL',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/10_PayTool.svg',
        cName: '',
        key: 9
    },
    {
        title: 'EMAILTOOL',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/11_EmailTool.svg',
        cName: '',
        key: 10
    },
    {
        title: 'COPYTOOL',
        path: '',
        icon: 'https://cdn.bemaster.com/media/icons/admin/11_EmailTool.svg',
        cName: '',
        key: 11
    }
]
