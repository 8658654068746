import { AuthContext } from "../auth/components/context/auth-context";
import React, { useContext, useEffect, useState } from 'react';
import { createStore } from "devextreme-aspnet-data-nojquery";
import Scrollbar from 'react-scrollbars-custom';
import "devextreme/data/odata/store";
import {
  Column, DataGrid, FilterRow, HeaderFilter, Editing,
  Lookup, RequiredRule, Paging, Pager, Button, Popup, Form,
} from "devextreme-react/data-grid";
import { Item } from 'devextreme-react/form';
import DataSource from "devextreme/data/data_source"
import { Config } from "../../Config";
import { statusDataSource, usersDataSource } from "../../shared/dataSources/data-source.data";
import './affiliation-request.styles.scss';
import notify from "devextreme/ui/notify";

const scrollHeight = (window.innerHeight - 100);
const scrollWidth = (window.innerWidth - 65);

const gridDataSource = new DataSource({
  store: createStore({
    key: "idLeadAction",
    loadUrl: `${Config.url.api}/Affiliation/GetAffiliations`,
    updateUrl: `${Config.url.api}/Affiliation/UpdateAffiliation`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.xhrFields = { withCredentials: false };
      ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
    }
  })
});

const AffiliationRequest = () => {

  const authContext = useContext(AuthContext);
  const response = authContext.loginInfo.moduleRoles;

  //Current Module
  let currentModule = 2;

  const onRowUpdated = (e: any) => {
    if (e.data.result === "success") {
      notify(e.data.message);
    } else {
      notify(e.data.message, e.data.result);
    }
  }

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => { gridDataSource.reload() }
      }
    });
  }

  return (
    <div className="affiliations-manager-main-container">
      <Scrollbar style={{ height: scrollHeight, width: scrollWidth }}>
        <h1 className="is-size-5 mb-2">Solicitudes de Afiliaciones</h1>

        <DataGrid
          dataSource={gridDataSource}
          showBorders={true}
          keyExpr="idLeadAction"
          allowColumnReordering={true}
          columnAutoWidth={true}
          focusedRowEnabled={true}
          showRowLines={true}
          onRowUpdated={onRowUpdated}
          onToolbarPreparing={onToolbarPreparing}
        >
          {/* <MasterDetail enabled={true} component={MasterDetailGrid} /> */}
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Paging enabled={true} />
          <Pager showInfo={true} />

          <Editing
            mode="popup"
            allowUpdating={true}>
            <Popup title="Affiliate Info" showTitle={true} width={700} height={525} />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="nameProduct" />
                <Item dataField="name" />
                <Item dataField="affiliationEmail" />
                <Item dataField="email" />
                <Item dataField="commission" />
              </Item>

              <Item itemType="group" caption="Información Adicional" colCount={2} colSpan={2}>
                <Item dataField="affiliateCode" />
                <Item dataField="idStatus" isRequired={true}>
                  <RequiredRule message="La tipificación es necesaria" />
                </Item>
              </Item>
            </Form>
          </Editing>

          <Column type="buttons" width={110}>
            <Button name="edit" text="Editar" />
          </Column>
          <Column dataField="idLeadAction" caption="idLeadAction" dataType="number" allowEditing={false} visible={false} />
          <Column alignment="center" dataField="nameProduct" caption="Producto" dataType="string" allowEditing={false} />
          <Column dataField="date" caption="Fecha Solicitud" dataType="date" allowEditing={false} />
          <Column dataField="name" caption="Nombre" dataType="string" allowEditing={false} width="200" />
          <Column dataField="affiliationPhone" caption="Teléfono" dataType="string" allowEditing={false} />
          <Column dataField="affiliationEmail" caption="Email Hotmart" dataType="string" allowEditing={false} />
          <Column dataField="email" caption="Email Usuario" dataType="string" allowEditing={false} />
          <Column alignment="center" dataField="commission" caption="Comisión" dataType="string" allowEditing={false} />
          <Column dataField="affiliateCode" caption="Código Afiliado" dataType="string" />
          <Column dataField="idStatus" caption="Estado" width={150} filterValue={1}>
            <Lookup dataSource={statusDataSource("Affiliation")} valueExpr="idStatusAction" displayExpr="name" />
          </Column>
          <Column dataField="idUser" caption="Usuario que modifica" width={150} allowEditing={false}>
            <Lookup dataSource={usersDataSource} valueExpr="idUser" displayExpr="firstName" />
          </Column>
        </DataGrid>


      </Scrollbar>
    </div>
  );
};

export default AffiliationRequest;