import React from 'react'
import Commission_recovery_pending from './comission-recovery-pending.component'
import Commission_recovery_sent from './comission-recovery-sent.component'

const ComissionContainer = () => {
    return (
        <>
            <Commission_recovery_pending />
            <Commission_recovery_sent />
        </>
    )
}

export default ComissionContainer;