import CustomStore from 'devextreme/data/custom_store';
import AxiosConfig from "../../axios/axiosConfig";

export const fetchTypeContracts = {
    store: new CustomStore({
        key: "idTypeContract",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get("/UserManagement/GetTypeContract")
                .then((response) => {
                    return response.data.data;
                })
                .catch((e) => {
                    console.error(e);
                });
        },
    }),
    sort: "name"
}

export const fetchRoles = {
    store: new CustomStore({
        key: "idRole",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get("/UserManagement/GetRoles")
                .then((response) => {
                    return response.data.data;
                })
                .catch((e) => {
                    console.error(e);
                });
        },
    }),
    sort: "role"
}

export const fetchEmployment = {
    store: new CustomStore({
        key: "idEmployment",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get("/UserManagement/GetEmployment")
                .then((response) => {
                    return response.data.data;
                })
                .catch((e) => {
                    console.error(e);
                });
        },
    }),
    sort: "name"
}
