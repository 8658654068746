import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Config } from '../../Config';
import Scrollbar from 'react-scrollbars-custom';
import DataGrid, {
    Button,
    Column,
    Editing,
    FilterRow,
    Form,
    HeaderFilter,
    Lookup,
    MasterDetail,
    Pager,
    Paging,
    RequiredRule,
    Texts
} from 'devextreme-react/data-grid';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import { Item } from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';
import notify from 'devextreme/ui/notify';
import ContentTemplate from './powerclassTemplate.component';
import { careersDataSource } from '../ads-management/ads-management.data';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import MasterDetail_content from './master-detail-content.component';
import './powerclass-management-bm.styles.scss'


const scrollHeight = window.innerHeight - 20;
const scrollWidth = window.innerWidth - 65;

const gridDataSource = {
    store: createStore({
        key: "idContentCategory",
        loadUrl: `${Config.url.api}/ContentBm/GetContentCategory`,
        insertUrl: `${Config.url.api}/ContentBm/InsertContentCategory`,
        updateUrl: `${Config.url.api}/ContentBm/UpdateContentCategory`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
};

const PowerclassManagement = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.powerclassManagementBm.id;

    const gridRef = useRef<DataGrid>(null);

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [vUploader, setVUploader] = useState<boolean>(false);
    const [uploaderType, setUploaderType] = useState<string>("");
    const [disable, setDisable] = useState<boolean>(false);
    const [uploadChanges, setUploadChanges] = useState<any>({
        idContentCategory: 0,
        type: ''
    });
    const [visibleSelectFile, setVisibleSelectFile] = useState<boolean>(false);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
            console.error("error subiendo archivo", e.error.responseText);
            notify("error subiendo archivo", e.error.responseText);
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
            console.error("error en la conexión", e.error.responseText);
            notify('error en la conexión', e.error.responseText);
        }
    }, []);

    const uploadedFile = (e: any) => {
        let xhttp = e.request;
        notify('uploaded successfully');
    }
    const selectionChanged = (e: any) => {
        setUploadChanges({ ...uploadChanges, type: e.selectedItem })
        setVisibleSelectFile(true);
    }

    const uploadClick = (e: any) => {
        setUploadChanges({
            ...uploadChanges,
            idContentCategory: e.row.data.idContentCategory
        });
        setVUploader(true);
    }

    const hidePopUp = () => {
        setUploadChanges({
            ...uploadChanges,
            idContentCategory: 0,
            type: ''
        });
        setVUploader(!vUploader);
    }

    //Card de inicio Escritorio = 1080*1583
    //Card de inicio Móvil = 2030*1080
    //banner Escritorio = 1000*200
    //banner Móvil = 400*200
    const onValueChanged = (e: any) => {
        var _URL = window.URL || window.webkitURL;
        var file, img;
        if ((file = e.value[0])) {
            img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            img.onload = (image: any) => {
                if (uploadChanges.type === "Card" && (image.path[0].width !== 1080 || image.path[0].height !== 1583)) {
                    console.log(e);
                    e.value = [];
                    notify('¡Cuidado! Las dimensiones de la imagen no son las correctas', 'error');
                }
                if (uploadChanges.type === "Card Mobile" && (image.path[0].width !== 2030 || image.path[0].height !== 1080)) {
                    notify('¡Cuidado! Las dimensiones de la imagen no son las correctas', 'error');
                }
                if (uploadChanges.type === "Banner" && (image.path[0].width !== 1000 || image.path[0].height !== 200)) {
                    notify('¡Cuidado! Las dimensiones de la imagen no son las correctas', 'error');
                }
                if (uploadChanges.type === "Banner Mobile" && (image.path[0].width !== 400 || image.path[0].height !== 200)) {
                    notify('¡Cuidado! Las dimensiones de la imagen no son las correctas', 'error');
                }
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        }
    }

    const renderPopUp = () => {
        return <div className="uploaders">

            <div className="uploader-container">
                <h5>Card</h5>
                <FileUploader
                    visible={true}
                    accept="image/png"
                    multiple={false}
                    uploadMode="useButtons"
                    uploadUrl={
                        `${Config.url.api}/ContentBm/uploadContentCategoryImage`
                    }
                    invalidFileExtensionMessage="Only PNG files is accepted"
                    invalidMaxFileSizeMessage="La imagen debe pesar máximo 1 MB"
                    labelText="Select your PNG file"
                    maxFileSize={1000000}
                    uploadHeaders={
                        {
                            'Authorization': localStorage.getItem('token'),
                            'keyRequest': uploadChanges.idContentCategory,
                            'type': 'Card'
                        }
                    }
                    onUploadError={onUploadError}
                    onUploaded={uploadedFile}
                    onValueChanged={onValueChanged}
                />
            </div>

            <div className="uploader-container">
                <h5>Card Mobile</h5>
                <FileUploader
                    visible={true}
                    accept="image/png"
                    multiple={false}
                    uploadMode="useButtons"
                    uploadUrl={
                        `${Config.url.api}/ContentBm/uploadContentCategoryImage`
                    }
                    invalidFileExtensionMessage="Only PNG files is accepted"
                    invalidMaxFileSizeMessage="La imagen debe pesar máximo 1 MB"
                    labelText="Select your PNG file"
                    maxFileSize={1000000}
                    uploadHeaders={
                        {
                            'Authorization': localStorage.getItem('token'),
                            'keyRequest': uploadChanges.idContentCategory,
                            'type': 'Card'
                        }
                    }
                    onUploadError={onUploadError}
                    onUploaded={uploadedFile}
                    onValueChanged={onValueChanged}
                />
            </div>

            <div className="uploader-container">
                <h5>Banner</h5>
                <FileUploader
                    visible={true}
                    accept="image/png"
                    multiple={false}
                    uploadMode="useButtons"
                    uploadUrl={
                        `${Config.url.api}/ContentBm/uploadContentCategoryImage`
                    }
                    invalidFileExtensionMessage="Only PNG files is accepted"
                    invalidMaxFileSizeMessage="La imagen debe pesar máximo 1 MB"
                    labelText="Select your PNG file"
                    maxFileSize={1000000}
                    uploadHeaders={
                        {
                            'Authorization': localStorage.getItem('token'),
                            'keyRequest': uploadChanges.idContentCategory,
                            'type': 'Banner'
                        }
                    }
                    onUploadError={onUploadError}
                    onUploaded={uploadedFile}
                    onValueChanged={onValueChanged}
                />
            </div>

            <div className="uploader-container">
                <h5>Banner Mobile</h5>
                <FileUploader
                    visible={true}
                    accept="image/png"
                    multiple={false}
                    uploadMode="useButtons"
                    uploadUrl={
                        `${Config.url.api}/ContentBm/uploadContentCategoryImage`
                    }
                    invalidFileExtensionMessage="Only PNG files is accepted"
                    invalidMaxFileSizeMessage="La imagen debe pesar máximo 1 MB"
                    labelText="Select your PNG file"
                    maxFileSize={1000000}
                    uploadHeaders={
                        {
                            'Authorization': localStorage.getItem('token'),
                            'keyRequest': uploadChanges.idContentCategory,
                            'type': 'Banner Mobile'
                        }
                    }
                    onUploadError={onUploadError}
                    onUploaded={uploadedFile}
                    onValueChanged={onValueChanged}
                />
            </div>
        </div>
    }

    return (
        <>
            {
                authorized && authorized ?
                    <div>
                        < Popup
                            visible={vUploader}
                            onHiding={hidePopUp}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showCloseButton={true}
                            showTitle={true}
                            title="Edit Content"
                            container=".dx-viewport"
                            width={765}
                            height={600}
                            position="center"
                            contentRender={renderPopUp}
                        >
                            <ToolbarItem
                                widget="dxButton"
                                toolbar="bottom"
                                location="center"
                                options={{ text: 'Print', onClick: () => { console.log(uploadChanges) } }}
                                disabled={disable}
                                visible={!disable}
                            />
                        </Popup>

                        <h1 className="is-size-5 m-5 center">Gestión de Powerclass y Mentortalks</h1>

                        <div className="ads-management-container-grid">

                            <DataGrid
                                ref={gridRef}
                                dataSource={gridDataSource}
                                showBorders={true}
                                keyExpr="idAd"
                                allowColumnReordering={true}
                                columnAutoWidth={true}
                                focusedRowEnabled={true}
                                showRowLines={true}
                                height="90vh"
                                width="95vw"
                            >
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                <Paging enabled={true} pageSize="20" />
                                <Pager showInfo={true} />

                                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                                    <Texts saveRowChanges="Guardar" />
                                    <Popup
                                        title="PowerClass & MentorTalks"
                                        // showTitle="true"
                                        width={700}
                                        height={550}
                                    />
                                    <Form>
                                        <Item itemType="group" colCount={2} colSpan={2}>
                                            <Item dataField="typeContent" isRequired={true} >
                                                <RequiredRule message="debes indicar que tipo de contenido vas a gestionar" />
                                            </Item>
                                            <Item dataField="order" isRequired={true} >
                                                <RequiredRule message="debes indicar el nivel del orden" />
                                            </Item>
                                            <Item dataField="value" isRequired={true} >
                                                <RequiredRule message="Debes indicar un valor mayor ó igual a 0" />
                                            </Item>
                                            <Item dataField="master" isRequired={true} >
                                                <RequiredRule message="Agrega el nombre del Master" />
                                            </Item>
                                            <Item dataField="expiryDate" isRequired={true} >
                                                <RequiredRule message="Debes indicar una fecha de Expiración" />
                                            </Item>
                                            <Item dataField="idCareer" isRequired={true} >
                                                <RequiredRule message="Selecciona la carrera" />
                                            </Item>
                                            <Item dataField="classification" >
                                                <RequiredRule message="Escribe una clasificación" />
                                            </Item>
                                        </Item>
                                        <Item
                                            itemType="group"
                                            colCount={2}
                                            colSpan={2}
                                        >
                                            <Item dataField="tags" isRequired={true} colSpan={2} >
                                                <RequiredRule message="escribe las etiquetas necesarias separadas por coma" />
                                            </Item>
                                        </Item>
                                        <Item
                                            itemType="group"
                                            colCount={2}
                                            colSpan={2}
                                            caption="Description"
                                        >
                                            <Item dataField="description" editorType="dxTextArea" colSpan={2}
                                                editorOptions={{ height: 100 }} isRequired={true}>
                                                <RequiredRule message="Debes agregar una descripción" />
                                            </Item>
                                        </Item>
                                    </Form>
                                </Editing>

                                <Column type="buttons" caption="Options" >
                                    <Button name="edit" text="Editar" />
                                    <Button icon="upload" text="subir contenido" onClick={uploadClick} />
                                </Column>
                                <Column dataField="idContentCategory" caption="idContentCategory" dataType="int" visible={false} sortIndex={0} sortOrder="desc" />
                                <Column dataField="typeContent" caption="Tipo de Contenido" dataType="string" >
                                    <Lookup
                                        dataSource={["Powerclass", "Mentortalk"]}
                                        allowClearing={true}
                                    />
                                </Column>
                                <Column dataField="order" caption="Orden" dataType="int" />
                                <Column dataField="value" caption="Valor" dataType="int" />
                                <Column dataField="description" width="300" caption="Descripción" dataType="string" />
                                <Column dataField="master" caption="Master" dataType="string" width="300" />
                                <Column dataField="typeBanner" caption="Tipo de Banner" dataType="string" />
                                <Column dataField="idCareer" caption="Producto" dataType="string" >
                                    <Lookup
                                        dataSource={careersDataSource}
                                        valueExpr="idCareer"
                                        displayExpr="name"
                                        allowClearing={true}
                                    />
                                </Column>
                                <Column dataField="tags" caption="Etiquetas" dataType="string" width="500" />
                                <Column dataField="classification" caption="Clasificación" dataType="string" width="200" />
                                <Column dataField="totalClasses" caption="Total de Clases" dataType="string" />
                                <Column dataField="totalDuration" caption="Duración Total" dataType="string" />
                                <Column dataField="urlTrailer" caption="Url del tráiler" dataType="string" />
                                <MasterDetail
                                    enabled={true}
                                    component={MasterDetail_content}
                                />
                            </DataGrid>
                        </div>
                    </div> : <></>
            }
        </>
    )
}

export default PowerclassManagement;