import React, { useState } from 'react'
import './userInformationComponent.styles.scss'
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { instanceNode } from '../../axios/axiosConfig';
import notify from 'devextreme/ui/notify';


const UserInformationComponent = ({url_image, name, email, cellPhone, idUser}: any) =>{

    const [visiblePopUp, setVisiblePopUp] = useState(false) 
    const closeButton = {
        text:"Cerrar",
        onClick: ()=>setVisiblePopUp(false)
    }
    const sendButton = {
        text:"Enviar",
        onClick: async()=>{
            const request = await instanceNode.post(`mastertools/changePassword/${email}`, {password:password})
            if(request.data.data.message === "Password Changed!"){
                setVisiblePopUp(false)
                setPassword("")
                setRepeatPassword("")
                notify("Password modificado con éxito", 'success');
            }
            else{
                setPassword("")
                setRepeatPassword("")
                notify("Algó salió mal", 'error');
            }
        },
    }

    let [password, setPassword] = useState("")
    let [repeatPassword, setRepeatPassword] = useState("")

    return(
        <div className='userInfo_body'>
            <Popup
            visible={visiblePopUp}
            title={"Modificar contraseña de "+name}
            width={400}
            height={300}
            showCloseButton={false}
            container="container"
            onHiding={()=>{
                setPassword("")
                setRepeatPassword("")
            }}
            >
                <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="center"
                options={closeButton}
          />
                <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="center"
                options={sendButton}
                disabled= {password === "" || repeatPassword === "" || password !== repeatPassword}
          />
            <div id="form">
                <p>Nueva contraseña</p>
                <input type="password" placeholder='Contraseña' value={password} onChange={(e:any)=>setPassword(e.target.value)}/>
                <p>Repetir contraseña</p>
                <input type="password" placeholder='Repetir contraseña' value={repeatPassword} onChange={(e:any)=>setRepeatPassword(e.target.value)}/>
                {password === "" || repeatPassword === "" || password !== repeatPassword && <p className='error'>Las contraseñas deben coincidir </p>}
            </div>
            </Popup>
            <p className="userInfo_title">Información del Usuario</p>
            <p >Nombre: {name}</p>
            <p>Email: {email}</p>
            <p>Teléfono: {cellPhone}</p>
            <p>ID Usuario MasterTools: {idUser}</p> 
            <Button icon="preferences" type="default" onClick={()=>setVisiblePopUp(true)}/>
            <p style={{fontSize:"0.7rem"}}>Cambiar contraseña</p> 
         </div>
        
    )
}

export default UserInformationComponent