import React, { useContext, useEffect, useRef, useState } from 'react'
import Scrollbar from 'react-scrollbars-custom';
import Toast from 'devextreme-react/toast';
import DataGrid,
{
    Column,
    FilterRow,
    HeaderFilter,
    MasterDetail,
    Pager,
    Paging
} from 'devextreme-react/data-grid';
import affiliationsTemplate from './affiliations-template.component';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Config } from '../../Config';
import { getAffiliates } from './affiliates-management.data';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './affiliates-management.styles.scss';
import { BiSearchAlt2 } from 'react-icons/bi';


const Affiliates_management = () => {
    
    const searchRef = useRef<HTMLInputElement>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.affiliatesManagement.id;
    
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        message: ''
    });
    const [searchValue, setSearchValue] = useState<string>("all");
    
    const gridDataSource = {
        store: createStore({
            key: "idLeadAction",
            loadUrl: `${Config.url.api}/Affiliation/GetAffiliates/${searchValue}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
            }
        })
    }

    //hide toast
    const onHiding = () => {
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }

    const fetchAffiliates = async () => {
        const respuesta = await getAffiliates();
        return respuesta;
    }

    const changeSearch = (e: any) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            setSearchValue("all");
        }
    }

    const searchClick = () => {
        const valor = searchRef.current?.value;
        if (valor === "" || valor === undefined) {
            setSearchValue("all");
        } else {
            setSearchValue(valor);
        }
        console.log(searchRef.current?.value);
    }

    useEffect(() => {
        fetchAffiliates();
    }, []);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    return (
        <>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type="info"
                onHiding={onHiding}
                displayTime={1200}
            />
            {
                authorized && authorized ?
                    <div className="main-Affiliates-management-container">
                        <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Gestión de Afiliados</h1>
                                </div>
                            </div>
                        </div>

                        <div className="search">
                            <input type="text" name="search" id="search" placeholder="código de afiliación..." autoComplete="off" ref={searchRef} onChange={changeSearch} />
                            <div className="searchButton" onClick={searchClick} > <BiSearchAlt2 className="search-icon" /> </div>
                        </div>

                        <DataGrid
                            id="grid-container-affiliations"
                            dataSource={gridDataSource}
                            keyExpr="idLeadAction"
                            showBorders={true}
                            showRowLines={true}
                            focusedRowEnabled={true}
                            columnAutoWidth={true}
                            height="77vh"
                            width="95vw"
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize={10} />
                            <Pager showInfo={true} />

                            <Column dataField="idLeadAction" allowSearch={true} visible={false} />
                            <Column dataField="name" caption="Nombre" />
                            <Column dataField="affiliationEmail" caption="Correo" />
                            <Column dataField="affiliationPhone" caption="Teléfono" />
                            {/* <Column caption="Afiliaciones" /> */}
                            <Column dataField="affiliateCode" caption="código de afiliado" />
                            <MasterDetail
                                enabled={true}
                                component={affiliationsTemplate}
                            />
                        </DataGrid>
                    </div> : <></>
            }
        </>
    );
}

export default Affiliates_management;