import React, { useContext, useEffect, useRef, useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import SelectBox from 'devextreme-react/select-box';
import { formatDate, userData } from './user-certifications.data';
import { AuthContext } from '../auth/components/context/auth-context';
import ReactToPrint from 'react-to-print';
import './user-certifications.styles.scss';


const logo = 'https://cdn.bemaster.com/media/images/admin/assets/images/privilegeTeam/logo.png';
const firma = 'https://cdn.bemaster.com/media/images/admin/assets/images/privilegeTeam/Firma Mateo.png';

const certificatesData = [
    {
        id: 1,
        name: "Certificado Laboral"
    }
]

const fecha = formatDate(new Date());

const UserCertification = () => {

    //authContext
    const authContext = useContext(AuthContext);

    //User from authContext
    const usuario = authContext.loginInfo;

    const certificateRef = useRef<HTMLDivElement>(null);

    const [certificateType, setCertificateType] = useState<string>("");
    const [userInfo, setUserInfo] = useState<any>();

    const fetchData = async () => {
        await userData(usuario.idProfile, setUserInfo);
    }

    const onChangedCertificate = (e: any) => {
        setCertificateType(e.value);
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <Scrollbar>
            <div className="main-user-certification-container">
                <div className="banner">
                    <div className="banner-overlay">
                        <div className="banner-img">
                            <h1>Certificados <span className="be">Be</span><span className="masterText">Master</span></h1>
                        </div>
                    </div>
                </div>

                <div className="certificateType">
                    <h5>Tipo de Certificado </h5>
                    <SelectBox
                        id="certificateFilter"
                        dataSource={certificatesData}
                        defaultValue={0}
                        valueExpr="name"
                        displayExpr="name"
                        showClearButton={true}
                        placeholder="Selecciona un Certificado"
                        searchEnabled={true}
                        onValueChanged={onChangedCertificate}
                    />
                </div>
            </div>

            {certificateType && userInfo &&
                certificateType === "Certificado Laboral" ? (
                <>
                    <div className="downloadButtonContainer">
                        <ReactToPrint
                            trigger={() => <button className="downloadButton">
                                <i className="fas fa-file-download iconButton"></i>
                            </button>}
                            content={() => certificateRef.current}
                        />
                    </div>
                    <div className="certificateContainer" >
                        <div className="certificateContain" ref={certificateRef}>
                            <div className="letterContainer" style={{fontFamily: "Roboto sans-serif", color: "#000", width: "75%", margin: "3% auto 5%" }}>
                                <div className="header">
                                    <img src={logo} alt="Logo Privilege Team" />
                                </div> 
                                <br /><br />
                                <p style={{ fontWeight: "bold", fontSize: "16px" }}>Medellín, {fecha}</p>
                                <br /><br />
                                <h4 style={{ fontWeight: "bold", textTransform: "uppercase", textAlign: "center" }}>Certificado Laboral</h4>
                                <br />
                                <h5 style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>A quien pueda interesar:</h5>
                                <br /><br />
                                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                                    Yo, Mateo Duque Castro, con CC: 1.152.435.492, en calidad de representante legal
                                    de la sociedad Privilege Team SAS, con NIT 901.320.851-2, certifico que
                                    <b> {userInfo && userInfo.firstName.toUpperCase()} {userInfo.lastName.toUpperCase()}</b>, identificado con <b>CC. {userInfo.dni || `#CC`} </b>
                                    se encuentra trabajando con nuestra empresa mediante contrato laboral {userInfo.TypeContract}, desempeñando el cargo de {userInfo.Employment}.
                                </p>
                                <br />
                                <p style={{ fontSize: "18px" }}>Inicio del contrato: {userInfo.sinceDateContract}</p>
                                <br />
                                <p style={{ fontSize: "18px" }}>Salario básico mensual: {userInfo.salary}</p>
                                <br /><br />
                                <p style={{ fontSize: "18px" }}>Cordialmente,</p>
                                <br />
                                <img style={{ width: "170px" }} src={firma} alt="Firma Mateo Duque" />
                                <hr style={{ color: "#000", width: "180px", border: "solid #000 1px", marginBottom: "0" }} />
                                <p style={{ fontSize: "18px", fontWeight: "bold" }}>Mateo Duque Castro</p>
                                <p style={{ fontSize: "18px", marginTop: "-7px" }}>Privilege Team SAS</p>
                                <p style={{ fontSize: "18px" }}>Representante Legal</p>
                                <br />
                                <hr style={{ border: "solid #550000d1 2px", marginBottom: "0" }} />
                                <p style={{ fontSize: "14px", textAlign: "center" }}>
                                    PRIVILEGE TEAM SAS. NIT.901.320.851-2 - Calle 33a N° 71-10 - TEL: (57) 3104958990 - Medellín / Colombia
                                </p>
                                <p style={{ fontSize: "14px", textAlign: "center" }}>
                                    Email: admin@privilegeteam.com
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (<></>)}
        </Scrollbar>
    )
};

export default UserCertification;