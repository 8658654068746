import React, { useEffect, useState } from "react";
import PieChart, {
  Legend,
  Export,
  Series,
  Label,
  Font,
  Connector,
  Tooltip,
} from "devextreme-react/pie-chart";
import {sourceData} from './convertions.data'



const Convertions = (props: any) => {

  //initial States
  const [dataSource, setDataSource] = useState<any>();

  //customizeText on labels
  const customizeTextFunction = (arg: any) => {
    return `${arg.valueText} (${arg.percentText})`;
  };

  
  useEffect(() => {
    sourceData(props.dateIni, props.dateEnd, setDataSource, props.userFilter, props.productFilter);
  }, [props]);

  //customizeText on ToolTip
  const templateTip = (e: any) => {
    return `${e.argument}: ${e.value}`;
  }

  return (
    <PieChart
      id="pie"
      palette="Ocean"
      dataSource={dataSource}
      title="% Tipificaciones"
      rtlEnabled={true}
      segmentsDirection="clockwise"
    >
      <Legend
        columnCount={0}
        columnItemSpacing={0}
        horizontalAlignment="center"
        markerSize={5}
        rowItemSpacing={0}
        verticalAlignment="bottom"
      />
      <Export enabled={true} />
      <Series argumentField="Type" valueField="Count">
        <Label
          visible={true}
          position="outside"
          customizeText={customizeTextFunction}
          textOverflow="hide"
          wordWrap="breakWord"
        >
          <Font size={14} />
          <Connector visible={true} width={2.5} />
        </Label>
      </Series>
      <Tooltip enabled={true} contentTemplate={templateTip} />
    </PieChart>
  );
};

export default Convertions;
