import React from 'react'
import './funnelsComponent.styles.scss'

const FunnelsComponent = ({projectId, subdomain, websiteId}: any) =>{
    return(
        <div className='funnels_body'>
            <p className="funnels_title">FUNNELS</p>
            <p>Project ID: {projectId}</p>
            <p>Subdomain: {subdomain}</p>
            <p>WebSite ID: {websiteId}</p>
         </div>
        
    )
}

export default FunnelsComponent