import React, { useEffect, useState } from 'react'
import { getLogAffiliates, userSource, statusActionSource } from './affiliates-management.data'
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Lookup } from 'devextreme-react/data-grid';


const LogTemplate = (props: any) => {

    const [logAffiliation, setLogAffiliation] = useState([]);

    const getLogAffiliations = (key: any) => {
        return new DataSource({
            store: new ArrayStore({
                data: logAffiliation,
                key: 'idLogLeadAction'
            }),
            filter: ['idLeadAction', '=', key]
        });
    }

    const evidenceRender = (data: any) => {
        let valor = data.value.replace(/"/g, '');
        if (data.value) {
            console.log(valor)
            return <a href={valor} target="_blank" rel="noopener noreferrer" > link evidencia </a>;
        } else {
            return <>sin evidencia</>
        }
    }

    const dataSource = getLogAffiliations(props.data.key);

    const FetchLogAffiliates = async () => {
        const result = await getLogAffiliates();
        if (result) {
            setLogAffiliation(result);
        }
    }

    useEffect(() => {
        FetchLogAffiliates();
    }, []);

    return (
        <>
            <DataGrid
                dataSource={dataSource}
                showBorders={true}
                columnAutoWidth={true}
            >
                <Column dataField="idLogLeadAction" visible={false}/>
                <Column dataField="idLeadAction" visible={false}/>
                <Column dataField="date" dataType="date" caption="Fecha de Afiliación"/>
                <Column dataField="observation" caption="Observaciones"/>
                <Column dataField="idStatus" caption="Estado">
                    <Lookup
                        dataSource={statusActionSource}
                        valueExpr="idStatusAction"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField="LastEdit" dataType="date" caption="Fecha de último cambio"/>
                <Column dataField="evidence" caption="Evidencia"cellRender={evidenceRender} />
                <Column dataField="idUser" dataType="string" caption="Agente">
                    <Lookup
                        dataSource={userSource}
                        valueExpr="idUser"
                        displayExpr="firstName"
                    />
                </Column>
            </DataGrid>
        </>
    )
}

export default LogTemplate;