import React, { useState, useEffect } from 'react';
import Settings from '../../shared/components/settings/settings.component';
import AxiosConfig from '../../axios/axiosConfig';
import Notification from '../notification/notification.component';

import './navbar.styles.scss';

type props = {
    path?: string,
    comVisible?: boolean,
    backgroundColor?: boolean,
    breadcrumb?: string,
    visible: boolean,
    collapse: boolean,
    sideBarCollapse: () => void
}

const Navbar = ({ path = "/home", comVisible = true, backgroundColor = false, breadcrumb = "", visible = false, collapse, sideBarCollapse }: props) => {

    const [showNotification, setShowNotification] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        let isSubscribed = true

        if (path === '/shopping') {
            const fetchData = async () => {
                await AxiosConfig.get('/Account/GetUserBalance').then(result => {
                    if (isSubscribed) {
                        if (result.data.result === "success") {
                            setBalance(result.data.data.balance);
                        }
                    }
                });
            };
            fetchData();
        }

        return () => { isSubscribed = false }
    });


    const handleClickNotification = () => {
        setShowNotification(!showNotification);
        setShowSettings(false);
    }

    const handleClickSettings = () => {
        setShowSettings(!showSettings);
        setShowNotification(false);
    }

    const [hideArrow, setHideArrow] = useState(false);

    const handleSideBarCollapse = () => {
        setHideArrow(!hideArrow);
        sideBarCollapse();
    }

    return (
        <div className={`is-nav-section ${path === "/shopping" ? 'shopping-nav-margins' : ''}`} style={{ display: visible ? 'flex' : 'none', backgroundColor: backgroundColor ? '#403a31' : '' }}>
            <div className="is-arrow-icon" style={{
                transform: 'rotate(0deg)',
                display: collapse ? 'flex' : 'none'
            }} onClick={handleSideBarCollapse}> </div>
            <div className={`is-brand-section mr-4 ${path === '/shopping' ? 'is-shopping-brand-section' : ''}`}>
                <figure>
                    <img src="https://cdn.bemaster.com/media/images/login/Logo-BeMaster.png" alt="Brand" />
                </figure>
            </div>
            <div className="navbar-content is-hidden-mobile">
                <a className="has-text-white dark" href="#" style={{ letterSpacing: 5 }}> {breadcrumb.toUpperCase().replace('/', '/   ')} </a>
            </div>
            <div className="navbar-end is-flex">
                <div className="notification-parent mx-2">
                    <Notification value={showNotification} switchGlobalState={handleClickNotification} />
                </div>
                <div className="settings-parent mx-2">
                    <Settings value={showSettings} switchGlobalState={handleClickSettings} />
                </div>
            </div>
        </div>
    );
};

export default Navbar;