import React, { useState } from 'react'



const Item_arrow = (props: any) => {

    const [clicked, setClicked] = useState<boolean>(false);

    const handleClick = (e: any) => {
        console.log(e.target.id);
        setClicked(!clicked);
    }

    return (
        <div id={props.id} className='is-sidebar-item-arrow-icon' onClick={handleClick} style={{
            transform: !props.transform ? "rotate(0deg)" : "rotate(90deg)",
            display: props.show ? "flex" : "none"
        }} ></div>
    )
}

export default Item_arrow;