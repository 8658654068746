import React, { useEffect, useState } from 'react'

const Tarjeta = (props: any) => {

    const [cardInfo, setCardInfo] = useState<any>({
        tittle: "",
        value: 0
    });


    //Modificación de número a USD
    const optionsUSD = { style: "currency", currency: "USD" };
    const formatUSD = new Intl.NumberFormat("en-US", optionsUSD);

    useEffect(() => {
        const tittle = props.tittle;
        const value = props.value;

        switch (tittle) {
            case "totalSales":
                setCardInfo({ ...cardInfo, tittle: "Total de Ventas", value: value })
                break;
            case "totalBilling":
                setCardInfo({ ...cardInfo, tittle: "Facturación Total", value: formatUSD.format(value) })
                break;
            case "totalLeads":
                setCardInfo({ ...cardInfo, tittle: "# de Leads Alcanzados", value: value })
                break;
            default:
                break;
        }
    }, [])

    return (
        <>
            <div className="tarjetaInfo">
                <div className="tarjetaTittle">
                    {cardInfo.tittle}
                </div>
                <div className="tarjetaValue">
                    {cardInfo.value}
                </div>
            </div>
        </>
    )
}

export default Tarjeta;