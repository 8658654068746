import React from 'react'
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import CareerModuleTemplate from './career-module-template.component';


const MasterDetailCareer = (props: any) => {
    return(
        <CareerModuleTemplate props={props} />
    )
}

export default MasterDetailCareer;