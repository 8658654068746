import React from 'react';
import {BsPlay} from 'react-icons/bs';

import './button-personalized.styles.scss';

type props = {
    icon?: string,
    label: string,
    onClick?: React.MouseEventHandler,
    disabled?: boolean,
    hasIcon?: boolean,
    fontSize?: number,
    iconSize?: number, 
    width?: number,
    height?: number,
    color?: boolean,
    hasBorder?: boolean,
    hasShadow?: boolean,
}


const ButtonPersonalized = ({ label, onClick, disabled = false, hasIcon = false, fontSize, iconSize, height, width=250, color = true,
    hasBorder = false, hasShadow = true }: props) => {
    return (
        <div className="is-custom-button">
            <button
                className="button is-primary mt-1 is-fullwidth is-size-7 hover"
                onClick={onClick}
                disabled={disabled}
                style={{width: width, backgroundColor: !color ? 'transparent' : '',
                    border: hasBorder? 'solid 2px white' : '', height: height,
                    boxShadow: !hasShadow? 'none !important' : ''}} >

                <div className="icon-section" style = {{display: hasIcon? 'flex' : 'none', fontSize: iconSize }}> 
                    <BsPlay />
                </div>
                <div className="label-section" style={{fontSize: fontSize}}>
                    {label}
                </div>    
            </button>
        </div>
    );
};

export default ButtonPersonalized;