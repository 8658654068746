import React, { useContext, useEffect, useState } from 'react'
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import InfoTab from './info-tab-user-bm.component';
import UserCareerTab from './user-career-tab-user-bm.component';
import UserAwards from './user-awards-tab-user-bm.component';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';

const MasterDetailUser = (props: any) => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.userManagementBm.id;

    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0].role.roleType === "admin" || response[0].role.roleType === "leader") {
            setIsAdmin(true);
        }
    }, []);

    const renderInfoTab = () => {
        return <InfoTab dataInfo={props.data.data} isAdmin={isAdmin} />;
    }

    const renderUserCareerTab = () => {
        return <UserCareerTab data={props.data.data} isAdmin={isAdmin} />;
    }

    const renderUserAwardsTab = () => {
        return <UserAwards data={props.data.data} isAdmin={isAdmin} />
    }

    return (
        <TabPanel>
            <Item title="Info" render={renderInfoTab} />
            <Item title=" UserCareer" render={renderUserCareerTab} />
            <Item title=" UserAward" render={renderUserAwardsTab} />
        </TabPanel>
    )
}

export default MasterDetailUser;