import React, { useEffect, useState } from 'react'
import { getLogClients, userSource, statusCommunitySource, statusActionSource } from './leads-management.data'
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Lookup } from 'devextreme-react/data-grid';


const LogTemplate = (props: any) => {

    const [logClients, setLogClients] = useState([]);

    const getLogClient = (key: any) => {
        return new DataSource({
            store: new ArrayStore({
                data: logClients,
                key: 'idLogLeadAction'
            }),
            filter: ['idLeadAction', '=', key]
        });
    }

    const evidenceRender = (data: any) => {
        if (data.value) {
            return <a href={data.value} target="_blank" rel="noopener noreferrer" > link evidencia </a>;
        } else {
            return <>sin evidencia</>
        }
    }

    const dataSource = getLogClient(props.data.key);

    const FetchLogClients = async () => {
        const result = await getLogClients();
        if (result) {
            setLogClients(result);
        }
    }

    useEffect(() => {
        FetchLogClients();
    }, []);

    return (
        <>
            <DataGrid
                dataSource={dataSource}
                showBorders={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
            >
                <Column dataField="idLogLeadAction" visible={false} />
                <Column dataField="idLeadAction" visible={false} />
                <Column dataField="date" dataType="date" caption="Fecha" />
                <Column dataField="observation" caption="Observaciones" />
                <Column dataField="idStatus" caption="Estado" >
                    <Lookup
                        dataSource={statusCommunitySource}
                        valueExpr="idStatusAction"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField="LastEdit" dataType="date" caption="Fecha de último cambio" />
                <Column dataField="evidence" caption="Evidencia" cellRender={evidenceRender} />
                <Column dataField="idStatusAction" dataType="string" caption="Acción de comunidad" >
                    <Lookup
                        dataSource={statusActionSource}
                        valueExpr="idStatusAction"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField="idUser" dataType="string" caption="Agente" >
                    <Lookup
                        dataSource={userSource}
                        valueExpr="idUser"
                        displayExpr="firstName"
                    />
                </Column>
            </DataGrid>
        </>
    )
}

export default LogTemplate;