import React from 'react'
import ModuleClassTemplate from './module-class-template.component';


const MasterDetailModuleClass = (props: any) => {
    return(
        <ModuleClassTemplate props={props} />
    )
}

export default MasterDetailModuleClass;