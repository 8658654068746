import React from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup, RequiredRule, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Config } from '../../Config';
import MasterDetailAwardMission from './master-detail-awards-missions.component';

const accessTypeSource = [
    { value: "estudiantes", nameList: "Estudiantes" },
    { value: "masters", nameList: "Masters" },
    { value: "estudiantes|masters", nameList: "Estudiantes y Masters" }
]


const ModuleClassTemplate = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idModuleClass",
            loadUrl: `${Config.url.api}/bemaster/GetModuleClass/${props.props.data.data.idCareerModule}`,
            updateUrl: `${Config.url.api}/bemaster/updateModuleClass`,
            insertUrl: `${Config.url.api}/bemaster/insertModuleClass`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idCareerModule: props.props.data.data.idCareerModule };
            },
        }),
    };

    return (
        <>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idCareerModule"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Escalation Info"
                        showTitle="true"
                        width={"70%"}
                        height={"auto"}
                        maxHeight={"95vh"}
                    />
                    <Form>
                        <Item itemType="group" colCount={3} colSpan={2} >
                            <Item dataField="order" />
                            <Item dataField="class" />
                            <Item dataField="accessType" />
                        </Item>
                    </Form>

                </Editing>

                <Column type="buttons" caption="Acciones" width={80}>
                    <Button name="edit" text="Editar" />
                </Column>
                <Column dataField="idModuleClass" caption="idModuleClass" dataType="number" visible={false} />
                <Column dataField="idCareerModule" caption="idCareerModule" dataType="number" visible={false} />
                <Column dataField="order" caption="Orden" dataType="number" sortOrder={"asc"} alignment="left" />
                <Column dataField="class" caption="Nombre de la clase" dataType="string" />
                <Column dataField="accessType" caption="Tipo de acceso" dataType="string" >
                    <Lookup
                        dataSource={accessTypeSource}
                        valueExpr="value"
                        displayExpr="nameList"
                        allowClearing={true}
                    />
                </Column>


                <MasterDetail
                    enabled={true}
                    component={MasterDetailAwardMission}
                />
            </DataGrid>
        </>
    )
}

export default ModuleClassTemplate;