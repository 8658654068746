import AxiosConfig from '../../axios/axiosConfig';
import CustomStore from "devextreme/data/custom_store";


export const productDataSource = {
    store: new CustomStore({
        key: "idProduct",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get('/Values/GetProducts/enabled')
                .then(response => {
                    // console.log("PRODUCTOS", response.data)
                    return response.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    }),
    sort: "name"
}

export const productDataSourceAll = {
    store: new CustomStore({
        key: "idProduct",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get('/Values/GetProducts/all')
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    }),
    sort: "name"
}

export const categoriesDataSource = (type) => {
    return {
        store: new CustomStore({
            key: "idCategory",
            loadMode: "raw",
            cacheRawData: true,
            load: () => {
                return AxiosConfig.get(`/Values/GetCategories/${type}`)
                    .then(response => {
                        return response.data;
                    }).catch(e => {
                        console.error(e);
                    })
            }
        }),
        sort: "name"
    }
}

export const caseDataSource = (el) => {
    return {
        store: new CustomStore({
            key: "idCase",
            loadMode: "raw",
            cacheRawData: true,
            load: () => {
                return AxiosConfig.get(`/Values/GetCases`)
                    .then(response => {
                        return response.data;
                    }).catch(err => {
                        console.error(err);
                    })
            }
        }),
        sort: "name",
        filter: el.data ? ['idCategory', '=', el.data.idCategory] : null
    }
}

export const usersDataSource = {
    store: new CustomStore({
        key: "idUser",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get(`/Values/GetUsersadm`)
                .then(response => {
                    return response.data;
                }).catch(err => {
                    console.error(err);
                })
        }
    }),
    sort: "firstName"
}

export const statusDataSource = (type) => {
    return {
        store: new CustomStore({
            key: "idStatusAction",
            loadMode: "raw",
            cacheRawData: true,
            load: () => {
                return AxiosConfig.get(`/Values/GetStatusAction/${type}`)
                    .then(response => {
                        return response.data;
                    }).catch(e => {
                        console.error(e);
                    })
            }
        }),
        sort: "name"
    }
}

export const productAffDataSource = {
    store: new CustomStore({
        key: "idProduct",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get('/comizzion/GetProducts')
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    }),
    sort: "name"
}

export const modulesDataSource = {
    store: new CustomStore({
        key: "idModule",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get('/Values/GetModule')
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    }),
    sort: "idModule"
}

export const teamsDataSource = {
    store: new CustomStore({
        key: "idTeam",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get('/Values/GetTeam')
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    }),
    sort: "idTeam"
}

export const leadDataSource = {
    store: new CustomStore({
        key: "idLead",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get(`/Values/GetLead/0`)
                .then(response => {
                    return response.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    }),
    sort: "idLead"
}