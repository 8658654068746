import AxiosConfig from "../../axios/axiosConfig";
import CustomStore from 'devextreme/data/custom_store';

export const solicitudeTypeSource = {
    store: new CustomStore({
        key: "idSolicitudeType",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get("/UserRequest/GetSolicitudeType")
                .then((response) => {
                    return response.data.data;
                })
                .catch((e) => {
                    console.error(e);
                });
        },
    }),
    sort: "name"
}

export const categorySource = {
    store: new CustomStore({
        key: "idCategory",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get("/Values/GetCategories/recursosHumanos")
                .then((response) => {
                    return response.data;
                })
                .catch((e) => {
                    console.error(e);
                });
        },
    }),
    sort: "name"
}

export const usersSource = {
    store: new CustomStore({
        key: "idUser",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get("/UserManagement/GetUser")
                .then((response) => {
                    return response.data.data;
                })
                .catch((e) => {
                    console.error(e);
                });
        },
    }),
    sort: "idUser"
}

export const statusSource = {
    store: new CustomStore({
        key: "idCSolicitudeStatus",
        loadMode: "raw",
        cacheRawData: true,
        load: () => {
            return AxiosConfig.get("/UserRequest/GetSolicitudeStatus")
                .then((response) => {
                    return response.data.data;
                })
                .catch((e) => {
                    console.error(e);
                });
        },
    }),
    sort: "idCSolicitudeStatus"
}

export const onValueChanged = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.value[0]); // convert to base64 string
};