import React, { useContext, useEffect, useState, useRef } from "react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import "devextreme/data/odata/store";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup, RequiredRule,
    Paging, Pager, Button, Popup, Form, Texts
} from "devextreme-react/data-grid";
import { Item, Tab } from "devextreme-react/form";
import "devextreme-react/text-area";
import Axios from "axios";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import AxiosConfig from "../../axios/axiosConfig";
import notify from "devextreme/ui/notify";
import { AuthContext } from '../auth/components/context/auth-context';
import './delayed-to-call.styles.scss'
import { productDataSource, statusDataSource } from "../../shared/dataSources/data-source.data";

const gridDataSource = {
    store: createStore({
        key: "idLeadAction",
        insertUrl: `${Config.url.api}/CallCenter/InsertLeadsToCall`,
        updateUrl: `${Config.url.api}/CallCenter/UpdateLeadsToCall`,
        loadUrl: `${Config.url.api}/CallCenter/GetLeadsToCall/delayed`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
};

const DelayedToCall = () => {

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.delayedToCall.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [onCall, setOnCall] = useState<boolean>(false);
    const [leadOnCall, setLeadOnCall] = useState<number>(0);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);


    const gridRef = useRef<any>(null);
    const formRef = useRef<any>(null);

    //templates WhatsApp
    let phoneWp = "";
    let msgWp = `¡Ey! Nos alegra tenerte por aquí, esperamos que estés pasando un excelente día te saludamos del equipo de soporte de BeMaster. 

	Queremos que sepas qué nos puedes escribir en el momento que lo desees.
	
	¡Estamos aquí!`;

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
        if (response[0].role.roleType === "admin" || response[0].role.roleType === "leader") {
            setIsAdmin(true);
        }
    }, []);

    //End Call
    const endCall = (
        idLeadAction: number,
        idStatus: number,
        idProduct: number,
        comments: string
    ) => {
        if (onCall && idLeadAction === leadOnCall) {
            setOnCall(false);
            setLeadOnCall(0);
            Axios.get(
                `http://localhost:80/apiagentbox?action=chur&cod=${idStatus}&cod2=${idProduct}&comm=${comments}`
            )
                .then((response) => {
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    };

    //Call action
    const call = (e: any) => {
        const item = { ...e.row.data };
        if (!onCall) {
            setOnCall(true);
            setLeadOnCall(item.idLeadAction);

            Axios.get(
                `http://localhost:80/apiagentbox?action=dial&phone=9005${item.phone}&id_customer=${item.idLead}`
            )
                .then((response) => {
                })
                .catch((e) => {
                    console.error(e);
                });

            gridRef.current?.instance.editRow(e.row.rowIndex);
        } else {
            endCall(item.idLeadAction, item.idStatus, item.idProduct, item.comments);
        }
        e.event.preventDefault();
    };

    //Send WhatsApp Message
    const sendMessage = async (e: any) => {
        if (msgWp && phoneWp) {
            
            const result = await AxiosConfig.post('CallCenter/SendMessage', {
                mensaje: msgWp,
                to: phoneWp
            });

            if (result.data) {
                notify(result.data.message);
            }

        }
    };

    //Change email when not admin
    const calculateCellValue = (e: any) => {
        if (isAdmin) {
            return e.email;
        } else {
            const nuevoValor = newEmail(e.email);
            return nuevoValor;
        }
    };

    //Email Mask
    const newEmail = (initial: string) => {
        if (initial) {
            const posDominio = initial.indexOf("@");
            let retorno = "";
            for (let i = 0; i < initial.length; i++) {
                if (i > posDominio) {
                    retorno = retorno + "X";
                    continue;
                }
                retorno = retorno + initial[i];
            }
            return retorno;
        }
    };

    //Editor preparing
    const onEditorPreparing = (e: any) => {
        if (
            e.parentType === "dataRow" &&
            !e.value &&
            (e.dataField === "firstName" ||
                e.dataField === "lastName" ||
                e.dataField === "idProduct")
        ) {
            e.editorOptions.readOnly = false;
        } else if (
            e.parentType === "dataRow" &&
            e.value &&
            (e.dataField === "firstName" ||
                e.dataField === "lastName" ||
                e.dataField === "idProduct")
        ) {
            e.editorOptions.readOnly = true;
        }
        if (
            e.parentType === "dataRow" &&
            e.dataField === "phone" &&
            !isAdmin &&
            e.value
        ) {
            const res = e.value.replace(/\d{4}$/, "XXXX");
            e.editorOptions.value = res;
        }
    };

    //Row updated
    const onRowUpdating = (e: any) => {
        let { idStatus, idProduct, comments } = e.oldData;
        idStatus = e.newData.idStatus ? e.newData.idStatus : e.oldData.idStatus;
        comments = e.newData.comments ? e.newData.comments : e.oldData.comments;

        endCall(e.key, idStatus, idProduct, comments);
    };

    const nose = async ()=>{
		let respuesta: any = await AxiosConfig.get( `${Config.url.api}/CallCenter/GetLeadsToCall/delayed`)
		console.log("respuesta delayed", respuesta.data)
		return respuesta
	}

	nose()


    return (
        <>
            {
                authorized && authorized ?
                    <div className="leads-delayed-main-container">
                        <h1 className="is-size-5 m-5 center">Delayed to Call</h1>
                        <DataGrid
                            ref={gridRef}
                            dataSource={gridDataSource}
                            showBorders={true}
                            keyExpr="idLeadAction"
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            focusedRowEnabled={true}
                            onEditorPreparing={onEditorPreparing}
                            showRowLines={true}
                            onRowUpdating={onRowUpdating}
                            height="88vh"
                            width="95vw"
                        >
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} pageSize="20" />
                            <Pager showInfo={true} />

                            <Editing mode="popup" allowUpdating={true} >
                                <Texts saveRowChanges={onCall ? "Guardar Y Colgar" : "Guardar"} />
                                <Popup
                                    title="Refund Info"
                                    showTitle="true"
                                    width={700}
                                    height={525}
                                />
                                <Form ref={formRef}>
                                    <Item itemType="tabbed" colSpan={2} colCount={2}>
                                        <Tab title="Refund Info" icon="user">
                                            <Item itemType="group" colCount={2} colSpan={2}>
                                                <Item dataField="firstName" isRequired={true}>
                                                    <RequiredRule message="El nombre es necesario" />
                                                </Item>
                                                <Item dataField="lastName" />
                                                <Item dataField="idProduct" isRequired={true}>
                                                    <RequiredRule message="El producto es necesario" />
                                                </Item>
                                                <Item dataField="type" />
                                                <Item
                                                    dataField="email"
                                                    isRequired={true}
                                                >
                                                    <RequiredRule message="El email es necesario" />
                                                </Item>
                                                <Item dataField="phone" isRequired={true}>
                                                    <RequiredRule message="El teléfono es necesario" />
                                                </Item>
                                            </Item>

                                            <Item
                                                itemType="group"
                                                caption="Información Adicional"
                                                colCount={2}
                                                colSpan={2}
                                            >
                                                <Item dataField="idStatus" isRequired={true}>
                                                    <RequiredRule message="La tipificación es necesaria" />
                                                </Item>
                                                <Item dataField="nextCall" />
                                            </Item>
                                            <Item
                                                itemType="group"
                                                colCount={2}
                                                colSpan={2}
                                            >
                                                <Item
                                                    dataField="comments"
                                                    editorType="dxTextArea"
                                                    colSpan={2}
                                                    editorOptions={{ height: 100 }}
                                                />
                                            </Item>
                                        </Tab>
                                        <Tab title="Community" icon="fab fa-facebook" colCount={2}>
                                            <Item dataField="nameCommunity" />
                                            <Item dataField="linkCommunity" />
                                        </Tab>
                                        <Tab title="WhatsApp" icon="fab fa-whatsapp" colCount={2}>
                                            <Item
                                                dataField="phone"
                                                caption="WhatsApp Phone"
                                                editorOptions={{
                                                    onChange: (e: any) => {
                                                        phoneWp = e.event.target.value;
                                                    },
                                                }}
                                            />
                                            <Item itemType="hidden"></Item>
                                            <Item
                                                itemType="button"
                                                horizontalAlignment="left"
                                                buttonOptions={{
                                                    useSubmitBehavior: false,
                                                    text: "Enviar Mensaje",
                                                    onClick: sendMessage,
                                                }}
                                            />
                                        </Tab>
                                    </Item>
                                </Form>
                            </Editing>

                            <Column type="buttons" width={110}>
                                <Button name="edit" text="Editar" />
                                <Button icon="tel" text="Llamar" onClick={call} />
                            </Column>
                            <Column dataField="idLead" caption="idLead" dataType="int" visible={false} />
                            <Column alignment="center" dataField="date" caption="Fecha" dataType="datetime"
                                allowEditing={false} format="yyyy-MM-dd" sortIndex={0} sortOrder={"desc"} />
                            <Column dataField="type" caption="Tipo" dataType="string" allowEditing={false} />
                            <Column alignment="center" dataField="idProduct" caption="Producto" allowEditing={false} >
                                <Lookup
                                    dataSource={productDataSource}
                                    valueExpr="idProduct"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="firstName" caption="Nombre" dataType="string" allowEditing={true} width="200" />
                            <Column dataField="lastName" caption="Apellidos" dataType="string" allowEditing={true} width="200" />
                            <Column dataField="email" caption="Email" dataType="string" allowEditing={true} calculateCellValue={calculateCellValue} />
                            <Column dataField="country" caption="País" dataType="string" allowEditing={false} />
                            <Column dataField="phone" visible={isAdmin} caption="Phone" dataType="string" allowEditing={true} />
                            <Column dataField="transaction" caption="Transacción" dataType="string" allowEditing={false} visible={false} />
                            <Column alignment="center" dataField="idStatus" caption="Tipificación" >
                                <Lookup
                                    dataSource={statusDataSource('Calls')}
                                    valueExpr="idStatusAction"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="comments" caption="Comentarios" dataType="string" allowEditing={true} width="500" />
                            <Column alignment="center" dataField="nextCall" caption="Próxima Llamada" dataType="datetime" allowEditing={true} />
                            <Column alignment="center" dataField="lastEdit" caption="Última Llamada" dataType="datetime" allowEditing={false} />
                            <Column dataField="nameCommunity" caption="Nombre en la comunidad" allowEditing={false} />
                            <Column dataField="linkCommunity" caption="Perfil de la comunidad" allowEditing={false} />
                        </DataGrid>
                    </div> : <></>
            }
        </>
    )
}

export default DelayedToCall;