import React from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, Pager, Paging, Popup, RequiredRule, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Config } from '../../Config';
import { renderURL } from '../../shared/renders/renders';

const types = [
    { name: "Comment", value: "Comment" },
    { name: "Quiz", value: "Quiz" },
    { name: "Video", value: "Video" },
    { name: "Workbook", value: "Workbook" },
]

const videoPlayers = [
    { name: "Custom", value: "Custom" },
    { name: "No aplica", value: "" }
]

const targets = [
    { name: "Nueva pestaña", value: "_blank" },
    { name: "No aplica", value: "" }
]

const ClassMissionTab = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idClassMission",
            loadUrl: `${Config.url.api}/bemaster/GetClassMission/${props.props.data.data.idModuleClass}`,
            updateUrl: `${Config.url.api}/bemaster/updateClassMission`,
            insertUrl: `${Config.url.api}/bemaster/insertClassMission`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idModuleClass: props.props.data.data.idModuleClass };
            },
        }),
    };

    return (
        <>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idClassMission"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Class Mission Info"
                        showTitle="true"
                        width={"70%"}
                        height={"auto"}
                        maxHeight={"95vh"}
                    />
                    <Form>
                        <Item itemType="group" colCount={3} colSpan={2} >
                            <Item dataField="order" isRequired={true} />
                            <Item dataField="type" isRequired={true} />
                            <Item dataField="action" isRequired={true} />
                            <Item dataField="title" isRequired={true} />
                            <Item dataField="description" isRequired={true} />
                            <Item dataField="url" />
                            <Item dataField="minScore" />
                            <Item dataField="maxAttemps" />
                            <Item dataField="videoPlayer" />
                            <Item dataField="textButton" />
                            <Item dataField="targetUrl" />
                            <Item dataField="urlThumbnail" />
                        </Item>
                    </Form>

                </Editing>

                <Column type="buttons" caption="Acciones" width={80}>
                    <Button name="edit" text="Editar" />
                </Column>
                <Column dataField="idClassMission" caption="idClassMission" dataType="number" visible={false} />
                <Column dataField="idModuleClass" caption="idModuleClass" dataType="number" visible={false} />
                <Column dataField="order" caption="Orden" dataType="number" />
                <Column dataField="type" caption="Tipo de Misión" dataType="string" >
                    <Lookup
                        dataSource={types}
                        valueExpr="value"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="action" caption="Llamado a la acción" dataType="string" />
                <Column dataField="title" caption="Título" dataType="string" />
                <Column dataField="description" caption="Descripción" dataType="string" width="700" />
                <Column dataField="url" caption="url" dataType="string" cellRender={renderURL} />
                <Column dataField="minScore" caption="Score mínimo" dataType="number" />
                <Column dataField="maxAttemps" caption="Intentos Máximos" dataType="number" />
                <Column dataField="videoPlayer" caption="Tipo de reproductor" dataType="string" >
                    <Lookup
                        dataSource={videoPlayers}
                        valueExpr="value"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="textButton" caption="Texto del botón" dataType="string" />
                <Column dataField="targetUrl" caption="Target Url" dataType="string" >
                    <Lookup
                        dataSource={targets}
                        valueExpr="value"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="urlThumbnail" caption="Url de miniatura" dataType="string" cellRender={renderURL} />
            </DataGrid>
        </>
    )
}

export default ClassMissionTab;