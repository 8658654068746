import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DataGrid, Column, Button, Editing, MasterDetail, Lookup } from 'devextreme-react/data-grid';
import { Config } from '../../Config';
import { getAffiliations, getAffiliates, statusActionSource, editAffiliationState, onValueChanged } from './affiliates-management.data';
import logTemplate from './log-template.component'
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
import Toast from 'devextreme-react/toast';
import FileUploader from 'devextreme-react/file-uploader';

const loader = 'https://cdn.bemaster.com/media/images/admin/gifs/bemaster_loader.gif'

const AffiliationsTemplate = (props: any) => {


    const gridRef = useRef<any>(null);
    const fileUploaderRef = useRef<any>(null);

    const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [affiliatesData, setAffiliatesData] = useState([]);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        message: ''
    });
    const [datosForm, setDatosForms] = useState({
        idLeadAction: 0,
        idStatus: 0,
        description: '',
        evidencia: '',
        nameProduct: ''
    });

    const dataSource = getAffiliations(props.data.data.affiliationEmail, affiliatesData);

    const Fetchaffiliates = async () => {
        const result = await getAffiliates();
        if (result) {
            setAffiliatesData(result)
        }
    }

    const editAffiliation = async () => {
        setDisable(!disable);
        if (datosForm.description !== "" && datosForm.idStatus > 0 && datosForm.idLeadAction > 0) {
            const result = await editAffiliationState(datosForm);
            if (result.data.result === "success") {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: "Se ha actualizado correctamente el registro"
                });
                props.data.component.refresh();
                setDisable(false);
                setPopUpVisible(!popUpVisible);
            }
        } else {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                message: "Debes completar la información del formulario"
            });
            setDisable(false);
        }
    }

    const onClickEdit = (e: any) => {

        setDatosForms({
            ...datosForm,
            idLeadAction: e.row.data.idLeadAction,
            nameProduct: e.row.data.nameProduct
        });
        setPopUpVisible(!popUpVisible);
    }

    //hide toast
    const onHiding = () => {
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }

    const hidePopUp = () => {
        setDatosForms({
            ...datosForm,
            description: '',
            idStatus: 0,
            idLeadAction: 0
        });
        setPopUpVisible(false);
    }

    const handleChange = (e: any) => {
        const element = e.target ? e.target : e.element;
        const value = e.target ? e.target.value : e.value;
        setDatosForms({ ...datosForm, [`${element.id}`]: value });
    }

    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
            console.error("error subiendo archivo", e.error.responseText);
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
            console.error("error en la conexión", e.error.responseText);
        }
    }, []);

    const uploadedFile = (e: any) => {
        let xhttp = e.request;
        setDatosForms({
            ...datosForm,
            evidencia: xhttp.response
        });
    }

    useEffect(() => {
        Fetchaffiliates();
    }, []);

    const renderPopUp = () => {
        return (
            <>
                <Toast
                    visible={toastConfig.isVisible}
                    message={toastConfig.message}
                    type="info"
                    onHiding={onHiding}
                    displayTime={1200}
                />
                <div className="containerAddRequest">
                    <div className="addRowForm">

                        <div className="containerForm">
                            <div className="rowForm">
                                <div className="itemForm">
                                    <span className="labelForm">Evidencia</span>
                                    <FileUploader ref={fileUploaderRef}
                                        multiple={false} accept="*"
                                        uploadMode="instantly"
                                        uploadUrl={
                                            `${Config.url.api}/Affiliation/uploadEvidence`
                                        }
                                        uploadHeaders={
                                            {
                                                'Authorization': localStorage.getItem('token'),
                                                'keyRequest': datosForm.idLeadAction
                                            }
                                        }
                                        onValueChanged={onValueChanged}
                                        onUploadError={onUploadError}
                                        onUploaded={uploadedFile}
                                    />
                                </div>
                                <div className="itemForm">
                                    <span className="labelForm">Estado</span>
                                    <SelectBox
                                        dataSource={statusActionSource}
                                        defaultValue={0}
                                        displayExpr="name"
                                        valueExpr="idStatusAction"
                                        value={datosForm.idStatus}
                                        placeholder="Selecciona un estado"
                                        searchEnabled={true}
                                        onValueChanged={handleChange}
                                        width="300px"
                                        id="idStatus"
                                    />
                                </div>
                            </div>

                            <div className="rowForm"></div>
                            <div className="rowForm">
                                <div className="itemForm">
                                    <span className="labelForm">Descripción</span>
                                    <textarea
                                        value={datosForm.description}
                                        autoComplete="off"
                                        id="description"
                                        className="textAreaForm"
                                        onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="charging" style={{ display: disable ? "flex" : "none" }}>
                    <img src={loader} alt="is Charging" />
                </div>
            </>
        )
    }

    return (
        <>
            < Popup
                visible={popUpVisible}
                onHiding={hidePopUp}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Edit Affiliation"
                container=".dx-viewport"
                width={765}
                height={500}
                position="center"
                contentRender={renderPopUp}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="center"
                    options={{ text: 'Save', onClick: editAffiliation }}
                    disabled={disable}
                    visible={!disable}
                />
                {/* <div className="charging" style={{ display: disable ? "flex" : "none" }}>
                    <img src={loader} alt="is Charging" />
                </div> */}
            </Popup>
            <DataGrid
                ref={gridRef}
                dataSource={dataSource}
                showBorders={true}
                columnAutoWidth={true}
            >
                <Column type="buttons" width={"auto"}>
                    <Button name="edit" text="Editar" visible={true} onClick={onClickEdit} />
                </Column>
                <Column dataField="nameProduct" caption="Producto" />
                <Column dataField="affiliateCode" caption="Código de afiliación" />
                <Column dataField="commission" caption="Comisión" />
                <Column dataField="idStatus" caption="Estado" dataType="string">
                    <Lookup
                        dataSource={statusActionSource}
                        valueExpr="idStatusAction"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField="date" dataType="date" caption="Fecha de afiliación" />

                <MasterDetail
                    enabled={true}
                    component={logTemplate}
                />

            </DataGrid>
        </>
    );

}

export default AffiliationsTemplate;