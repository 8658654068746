import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, Pager, Paging, Popup, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import React from 'react';
import { Config } from '../../Config';
import { modulesDataSource } from '../../shared/dataSources/data-source.data';

const RolTemplate = (props: any) => {

    const dataRole = props.props.data.data;

    const gridDataSource = {
        store: createStore({
            key: "idModuleRole",
            loadUrl: `${Config.url.api}/rol/GetModuleRole/${dataRole.idRole}`,
            insertUrl: `${Config.url.api}/rol/createModuleRole/${dataRole.idRole}`,
            updateUrl: `${Config.url.api}/rol/updateModuleRole`,
            deleteUrl: `${Config.url.api}/rol/deleteModuleRole`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idCareerModule: props.props.data.data.idCareerModule };
            },
        }),
    };

    const deleteRow = (e: any) => {
        const component = e.component;
        const row = e.row;
        component.deleteRow(row.rowIndex);
    }

    const handleMessage = (e: any) => {
        notify(e.data.message, e.data.result);
    }

    return (
        <>
            <h1 className="is-size-5 m-1 left">Módulos por rol</h1>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idModuleRole"
                allowColumnReordering={true}
                onRowInserted={handleMessage}
                onRowUpdated={handleMessage}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={true} allowAdding={true} >
                    <Texts saveRowChanges="Guardar" />
                    <Popup
                        title="Escalation Info"
                        showTitle="true"
                        width={"70%"}
                        height={"auto"}
                        maxHeight={"95vh"}
                    />
                    <Form>
                        <Item itemType="group" colCount={3} colSpan={2} >
                            <Item dataField="idModule" />
                        </Item>
                    </Form>

                </Editing>

                <Column type="buttons" caption="Acciones" width={80}>
                    <Button name="edit" text="Editar" />
                    <Button icon='trash' text="Eliminar" onClick={deleteRow} />
                </Column>
                <Column dataField="idModuleRole" caption="idModuleRole" dataType="number" visible={false} />
                <Column dataField="idModule" caption="Nombre del Módulo" dataType="string" sortIndex={0} sortOrder='asc' >
                    <Lookup
                        dataSource={modulesDataSource}
                        valueExpr='idModule'
                        displayExpr='textMenu'
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="idRole" caption="idRole" dataType="number" visible={false} />
            </DataGrid>
        </>
    )
}

export default RolTemplate;