import { createSlice } from '@reduxjs/toolkit'
import {instanceNode} from '../../axios/axiosConfig'

interface userState {
    userInfo: any,
    userTools: any,
    loading: boolean
  }

  const initialState: userState = {
    userInfo: [],
    userTools: [],
    loading: false
  }

  export const counterSlice = createSlice({
    name: 'userMasterTools',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      setUser: (state, action: any) => {
        state.userInfo = action.payload
      },
      setUserTools: (state, action:any) => {
        state.userTools = action.payload
      },
      setLoading: (state) =>{
        state.loading = !state.loading
      }
    },
  })
  
  export const { setUser, setUserTools, setLoading } = counterSlice.actions

  export const getUser = (email: string) => {
      return async function (dispatch:any) {
        const user = await instanceNode.get('/mastertools/users?email='+email)
        dispatch(setUser(user.data.data))
        dispatch(setLoading())
      }
  }

  export const getUserTools = (email: string) => {
      return async function (dispatch:any) {
        const userTools = await instanceNode.get('/mastertools/usersTools/'+email)
        dispatch(setUserTools(userTools.data.data))
      }
  }
  
  export default counterSlice.reducer