import React from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Column, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { Config } from '../../Config';

const gridDataSource = {
    store: createStore({
        key: "idLeadAction",
        loadUrl: `${Config.url.api}/Affiliates/GetDeliveryRewards`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    }),
    reshapeOnPush: true
};

const DeliveryRewards = ({ renderSells }: any) => {

    return (
        <>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idLeadAction"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
                repaintChangesOnly={true}
                height="350px"
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} />
                <Pager showInfo={true} />

                <Column dataField="idLead" caption="idLead" dataType="string" allowEditing={false} visible={false} />
                <Column dataField="leadName" caption="Afiliado" dataType="string" allowEditing={false} />
                <Column dataField="idLeadAction" dataType="int" allowEditing={false} visible={false} />
                <Column dataField="affiliateCode" caption="Código" dataType="string" allowEditing={false} />
                <Column dataField="nameProduct" caption="Producto" dataType="string" allowEditing={false} />
                <Column dataField="leadEmail" caption="Email" dataType="string" allowEditing={false} />
                <Column dataField="billing" caption="Facturación total" dataType="int" cellRender={renderSells} allowEditing={false} sortOrder={"desc"} />
            </DataGrid>
        </>
    )
}

export default DeliveryRewards;