import React, { useState } from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup } from 'devextreme-react/data-grid';
import { careersDataSource } from '../ads-management/ads-management.data';
import { Item } from 'devextreme-react/form';
import { awardsDataSource } from './user-management-bm.data';
import { Config } from '../../Config';

const UserAwards = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idUserAward",
            loadUrl: `${Config.url.api}/userBm/GetUserAwardBm/${props.data.idUser}`,
            updateUrl: `${Config.url.api}/userBm/UpdateUserAwardBm`,
            insertUrl: `${Config.url.api}/userBm/InsertUserAwardBm/${props.data.idUser}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
            },
        }),
    };

    const iconRender = (data: any) => {
        const source = `https://cdn.bemaster.com/media/icons/triumph/active/triumph-${data.data.idAward}.svg`
        return (
            <img src={source} alt="LogoTriumph" className="logo-triumph-tab-awards" />
        )
    }

    return (
        <>
            <DataGrid
                id="dataGrid-user-awards-tab"
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idUserAward"
                allowColumnReordering={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                focusedRowEnabled={true}
                showRowLines={true}
                width="100%"
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize={10} />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={props.isAdmin} allowAdding={props.isAdmin}>
                    <Popup title="userAwards Info" showTitle={true} width="60%" />
                    <Form>
                        <Item itemType="group">
                            <Item dataField="idAward" />
                        </Item>
                    </Form>
                </Editing>

                <Column type="buttons" width={"auto"}>
                    <Button name="edit" text="Editar" visible={true} />
                </Column>
                <Column dataField="idAward" caption="idAward" dataType="int" visible={false} >
                    <Lookup
                        dataSource={awardsDataSource}
                        valueExpr="idAward"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column cellRender={iconRender} caption="Logro" />
                <Column dataField="name" caption="Recompensa" dataType="string" />
                <Column dataField="description" caption="Descripción" dataType="string" />
                <Column dataField="idCareer" caption="Carrera" dataType="string" >
                    <Lookup
                        dataSource={careersDataSource}
                        valueExpr="idCareer"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="dateReceived" caption="Fecha de Entrega" dataType="datetime" format="yyyy-MM-dd" />
            </DataGrid>
        </>
    )
}

export default UserAwards;