import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Editing, Lookup, RequiredRule,
    Paging, Pager, Button, Popup, Form, Texts
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/form";
import "devextreme-react/text-area";
import { Config } from "../../../Config";
import { modules } from '../../../shared/modules';
import notify from "devextreme/ui/notify";
import { AuthContext } from '../../auth/components/context/auth-context';
import { caseDataSource, categoriesDataSource, productDataSource, usersDataSource } from '../../../shared/dataSources/data-source.data';
import FileUploader from 'devextreme-react/file-uploader';
import { products_bemaster, states } from '../escalation.data'



const EscalationManagement_bemaster = () => {

    const gridRef = useRef<DataGrid>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.escalation_bemaster.id;

    const [authorized, setAuthorized] = useState<boolean>(false);
    const [level, setLevel] = useState<number>(0);
    const [statusForm, setStatusForm] = useState("");

    const gridDataSource = {
        store: createStore({
            key: "idEscalation",
            insertUrl: `${Config.url.api}/escalation/AddTicket/0/BeMaster`,
            updateUrl: `${Config.url.api}/escalation/UpdateTicket/BeMaster`,
            loadUrl: `${Config.url.api}/escalation/GetTickets/0`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem('token') };
            },
        }),
    }

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
            setLevel(response[0].role.level);
        }
    }, []);

    const setCategoryValue = (rowData: any, value: any) => {
        rowData.idCategory = value;
        if (value === 24) {
            // console.log(gridRef.current?.instance);
        }
    }

    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
            console.error("error subiendo archivo", e.error.responseText);
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
            console.error("error en la conexión", e.error.responseText);
        }
    }, []);

    const uploadedFile = useCallback((e, cellInfo) => {
        console.log("response img", JSON.parse(e.request.response).data);
        cellInfo.setValue(JSON.parse(e.request.response).data);
        notify(JSON.parse(e.request.response).message);
    }, []);

    const onRowInserted = (e: any) => {
        if (e.data.result === "success") {
            notify(e.data.message);
        } else {
            notify(e.data.message, 'error');
        }
    }

    const editCellRender = (cellInfo: any) => {
        return (
            <>
                <FileUploader
                    multiple={false} accept="image/*"
                    uploadMode="instantly"
                    uploadUrl={
                        `${Config.url.api}/escalation/UploadEvidence/BeMaster`
                    }
                    uploadHeaders={
                        {
                            'Authorization': localStorage.getItem('token'),
                        }
                    }
                    onUploadError={onUploadError}
                    onUploaded={e => uploadedFile(e, cellInfo)} />
            </>
        )
    }

    const onRowPrepared = (e: any) => {
        if (e.data && e.data.description?.length > 80) {
            e.rowElement.classList.add("largeHeight");
        }
    }

    const checked = (e: any) => {
        return e.row.data.state !== "solucionado"
    }

    const editCanceled = (e: any) => {
        setStatusForm("");
    }

    const Link = (data: any) => {
        return data.value ? <a href={data.value} target='_blank' rel="noopener noreferrer"> Ver evidencia </a> : <> Sin evidencia </>
    }

    const finalize = (e: any) => {
        setStatusForm("checking");
        gridRef.current?.instance.editRow(e.row.rowIndex);
    }

    const onEditorPreparing = (e: any) => {
        if (e.parentType !== "dataRow") {
            return;
        }
        if (e.dataField === "email_transaction") {
            e.editorOptions.disabled = e.row.data.idCategory !== 24;
        }
    }

    return (
        <>
            {
                authorized && authorized ? <div className="escalation-comizzion-main-container">

                    <h1 className="is-size-5 m-5 center">Tickets de Soporte por Escalamiento</h1>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idEscalation"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        onEditCanceled={editCanceled}
                        onRowInserted={onRowInserted}
                        onRowPrepared={onRowPrepared}
                        onEditorPreparing={onEditorPreparing}
                        showRowLines={true}
                        height="87vh"
                        width="94.5vw"
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="20" />
                        <Pager showInfo={true} />

                        <Editing mode="popup" allowUpdating={true} allowAdding={true}>
                            <Texts saveRowChanges="Guardar" />
                            <Popup
                                title="Escalation Info"
                                showTitle="true"
                                width={"70%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                            />
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2} visible={statusForm !== "checking"} >
                                    <Item dataField="leadName" isRequired={true} >
                                        <RequiredRule message="El nombre del cliente es necesario" />
                                    </Item>
                                    <Item dataField="leadEmail" isRequired={true}>
                                        <RequiredRule message="El correo del cliente es necesario" />
                                    </Item>
                                    <Item dataField="idProduct" isRequired={true} >
                                        <RequiredRule message="Debes seleccionar el producto del Ticket" />
                                    </Item>
                                    <Item dataField="idCategory" isRequired={true}>
                                        <RequiredRule message="Debes indicar la Categoría del Ticket" />
                                    </Item>
                                    <Item dataField="idCase" />
                                    <Item dataField="state" isRequired={level > 1} >
                                        <RequiredRule message="Selecciona un estado de Categoría para el Ticket" />
                                    </Item>
                                    <Item dataField="email_transaction" />
                                    <Item />
                                    <Item dataField="evidence" colSpan={2} />

                                </Item>
                                <Item itemType="group" colCount={2} colSpan={2} visible={statusForm !== "checking"}>
                                    <Item
                                        dataField="description"
                                        editorType="dxTextArea"
                                        colSpan={2}
                                        editorOptions={{ height: 100, maxLength: 1000 }}
                                    />
                                </Item>
                                <Item itemType="group" colCount={1} colSpan={2} visible={statusForm === "checking"}>
                                    <Item dataField="state" isRequired={level > 1} >
                                        <RequiredRule message="Selecciona un estado de Categoría para el Ticket" />
                                    </Item>
                                    <Item dataField="imageSolved" colSpan={2} />
                                    <Item
                                        dataField="observation"
                                        editorType="dxTextArea"
                                        colSpan={2}
                                        editorOptions={{ height: 100, maxLength: 1000 }}
                                    />
                                </Item>
                            </Form>
                        </Editing>

                        <Column type="buttons" caption="Acciones" width={80}>
                            <Button name="edit" text="Editar" />
                            <Button icon="check" text="Finalizar" onClick={finalize} visible={checked} />
                        </Column>
                        <Column dataField="idEscalation" caption="Número de ticket" dataType="number" sortOrder={"desc"} />
                        <Column dataField="level" caption="Nivel" dataType="string" filterValue={level > 1 ? level : undefined} />
                        <Column dataField="initDate" caption="Fecha de Creación" dataType="datetime" format="yy-MM-dd hh:mm:ss" />
                        <Column dataField="state" caption="Estado" dataType="string">
                            <Lookup
                                dataSource={states}
                                valueExpr="value"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="leadName" caption="Nombre Cliente" dataType="string" />
                        <Column dataField="leadEmail" caption="Email Cliente" dataType="string" />
                        <Column dataField="email_transaction" caption="Transacción / email de compra" dataType="string" />
                        <Column dataField="idProduct" caption="Producto" dataType="string">
                            <Lookup
                                dataSource={products_bemaster}
                                valueExpr="idProduct"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="idCategory" caption="Categoría" dataType="string" setCellValue={setCategoryValue} >
                            <Lookup
                                dataSource={categoriesDataSource("escalationBeMaster")}
                                valueExpr="idCategory"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="idCase" caption="Caso de Categoría" dataType="string" >
                            <Lookup
                                dataSource={caseDataSource}
                                valueExpr="idCase"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="evidence" caption="Evidencia" dataType="string" editCellRender={editCellRender} cellRender={Link} />
                        <Column dataField="user_escalate" caption="Usuario que escala" dataType="string" >
                            <Lookup
                                dataSource={usersDataSource}
                                valueExpr="idUser"
                                displayExpr={"firstName"}
                            />
                        </Column>
                        <Column dataField="user_solution" caption="Usuario que soluciona" dataType="string" >
                            <Lookup
                                dataSource={usersDataSource}
                                valueExpr="idUser"
                                displayExpr={"firstName"}
                            />
                        </Column>
                        <Column dataField="description" width="700" caption="Descripción" dataType="string" />
                        <Column dataField="observation" width="700" caption="Observaciones" dataType="string" />
                        <Column dataField="imageSolved" caption="Imagen soporte" dataType="string" editCellRender={editCellRender} />
                    </DataGrid>
                </div> : <></>
            }
        </>
    )
}

export default EscalationManagement_bemaster;