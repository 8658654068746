import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createStore } from "devextreme-aspnet-data-nojquery";
import Scrollbar from 'react-scrollbars-custom';
import {
    DataGrid, Column, FilterRow, HeaderFilter, Editing,
    Lookup, Paging, Pager, Button, RequiredRule, Popup, Form, Export
} from "devextreme-react/data-grid";
import { solicitudeTypeSource, categorySource, usersSource, statusSource, onValueChanged } from './user-Request.data';
import { Item } from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';
import { Config } from '../../Config';
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './user-Request.styles.scss';

const gridDataSource = {
    store: createStore({
        key: "idSolicitude",
        insertUrl: `${Config.url.api}/UserRequest/addUserRequest`,
        updateUrl: `${Config.url.api}/UserRequest/updateUserRequest`,
        loadUrl: `${Config.url.api}/UserRequest/GetUserRequest`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    })
}

const UserRequest = () => {


    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.userRequest.id;

    const fileUploaderRef = useRef(null);

    const [authorized, setAuthorized] = useState<boolean>(false);

    const user = authContext.loginInfo;
    let roleType = "";

    // getting permissions for current module
    user.moduleRoles
        .filter((res: any) => {
            return res.idModule === currentModule;
        })
        .map((permissionsAvailable: any) => {
            roleType = permissionsAvailable.role.roleType;
            return true;
        });

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    const cellRender = (data: any) => {
        if (data.value) {
            return <a href={data.value} target="_blank" rel="noopener noreferrer" > link evidencia </a>;
        } else {
            return <>sin evidencia</>
        }
    }

    const uploadedFile = useCallback((e, cellInfo) => {
        cellInfo.setValue(e.request.response);
    }, []);

    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
            console.error("error subiendo archivo", e.error.responseText);
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
            console.error("error en la conexión", e.error.responseText);
        }
        console.log("error completo", e);
    }, []);

    const editCellRender = (cellInfo: any) => {
        return (
            <>
                <FileUploader ref={fileUploaderRef} multiple={false} accept="*" uploadMode="instantly"
                    uploadUrl={`${Config.url.api}/UserRequest/uploadEvidence`}
                    uploadHeaders={{ 'Authorization': localStorage.getItem('token'), 'keyRequest': cellInfo.data.idSolicitude }} onValueChanged={onValueChanged}
                    onUploadError={onUploadError} onUploaded={e => uploadedFile(e, cellInfo)} />
            </>
        );
    }


    const userRender = (e: any) => {
        return e && `${e.firstName} ${e.lastName}`;
    }

    return (
        <>
            {
                authorized && authorized ?

                    <div className="main-user-request-container">

                        <div className="banner">
                            <div className="banner-overlay">
                                <div className="banner-img">
                                    <h1>Solicitudes del Usuario</h1>
                                </div>
                            </div>
                        </div>

                        <DataGrid
                            id="dataGrid_userRequest"
                            dataSource={gridDataSource}
                            showBorders={true}
                            keyExpr="idSolicitude"
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            focusedRowEnabled={true}
                            showRowLines={true}
                            height="74vh"
                            width="95vw"
                        >
                            {/* additional functions for datagrid */}
                            <Export enabled={true} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <Paging enabled={true} defaultPageSize={20} />
                            <Pager showInfo={true} />

                            {/* Editing options Section*/}
                            <Editing
                                mode="popup"
                                allowAdding={true}
                                allowUpdating={true}>
                                <Popup title="Request Info" showTitle={true} width={700} />
                                <Form>
                                    <Item itemType="group" colCount={2} colSpan={2}>
                                        <Item dataField="idSolicitudeType" />
                                        <Item dataField="idCategory" />
                                        <Item dataField="initialDate" />
                                        <Item dataField="endingDate" />
                                        <Item dataField="reason" editorType="dxTextArea" colSpan={2}
                                            editorOptions={{ height: 70 }} />
                                    </Item>
                                    <Item itemType="group" caption="Evidencia" colCount={2} colSpan={2}>
                                        <Item dataField="idSolicitudeStatus" />
                                        <Item dataField="approval_hr" />
                                        <Item dataField="evidence" />
                                    </Item>
                                </Form>
                            </Editing>

                            {/* Columns section */}
                            <Column type="buttons" width={"auto"}>
                                <Button name="edit" text="Editar" visible={true} />
                            </Column>
                            <Column dataField="idSolicitude" caption="idRequest" dataType="int" visible={false} sortOrder="desc" />
                            <Column dataField="idUser" caption="Usuario" dataType="string" width="200"
                                visible={roleType === "admin" || roleType === "leader"} allowEditing={false} >
                                <Lookup
                                    dataSource={usersSource}
                                    valueExpr="idUser"
                                    displayExpr={userRender}
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="idSolicitudeType" caption="Tipo de Solicitud" dataType="string" visible={true} >
                                <Lookup
                                    dataSource={solicitudeTypeSource}
                                    valueExpr="idSolicitudeType"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                                <RequiredRule message="El tipo de solicitud es necesario" />
                            </Column>
                            <Column dataField="idCategory" caption="Categoría" dataType="string" allowEditing={true} >
                                <Lookup
                                    dataSource={categorySource}
                                    valueExpr="idCategory"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                                <RequiredRule message="la categoría es necesaria" />
                            </Column>
                            <Column alignment="center" dataField="initialDate" caption="Fecha de inicio"
                                dataType="datetime" allowEditing={true}
                                format="yyyy/MM/dd hh:mm:ss" >
                                <RequiredRule message="El dia de inicio es requerido" />
                            </Column>
                            <Column alignment="center" dataField="endingDate" caption="Fecha de finalización"
                                dataType="datetime" allowEditing={true}
                                format="yyyy/MM/dd hh:mm:ss" >
                                <RequiredRule message="El dia de finalización es requerido" />
                            </Column>
                            <Column dataField="reason" caption="Motivo" dataType="string" allowEditing={true} width="500" >
                                <RequiredRule message="El motivo debe ser redactado" />
                            </Column>
                            <Column dataField="idSolicitudeStatus" caption="Aprobación Líder" dataType="string"
                                allowEditing={roleType === "admin" || roleType === "leader"} filterValue={3} >
                                <Lookup
                                    dataSource={statusSource}
                                    valueExpr="idSolicitudeStatus"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="approval_hr" caption="Aprobación Recursos Humanos"
                                dataType="string" allowEditing={roleType === "admin"}  >
                                <Lookup
                                    dataSource={statusSource}
                                    valueExpr="idSolicitudeStatus"
                                    displayExpr="name"
                                    allowClearing={true}
                                />
                            </Column>
                            <Column dataField="evidence" allowSorting={false} cellRender={cellRender} editCellRender={editCellRender} width="100" />
                        </DataGrid>
                    </div> : <></>
            }
        </>
    )
};

export default UserRequest;