import React from 'react';
import LoginCredentials from '../login-credentials/login-credentials-section.component';
import { BrandLogo } from '../../../../shared/components/brand/brand.component';
import './login.styles.scss';

const Login = () => {

    return ( 
        <div className="login-main-container">
            <div className="is-back-layer hero is-fullheight center"> </div>
            <div className="is-front-layer hero is-fullheight login-component">
                <div className="is-brand-section is-hidden-mobile"> 
                    <BrandLogo />
                </div>
                
                <LoginCredentials />
            </div>
        </div>
    );
};

export default Login;