import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Lookup, Paging, Pager, Button, Editing, Texts, SearchPanel, Form, RequiredRule, Popup
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './templates.styles.scss'
import { Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { instanceNode } from '../../axios/axiosConfig';



const Templates = () => {

    const gridRef = useRef<DataGrid>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.templateCopyTool.id;
    const [categories, setCategories]=useState<any>([])
    const [copyCategories, setCopyCategories]=useState<any>([])

    const [authorized, setAuthorized] = useState<boolean>(false);

    const fetchCategories = async () =>{
        const request = await instanceNode.get('copytool/templates/categories') 
        if(request.data.data){
            setCategories(request.data.data)
        }
        return request.data.data
    }
    const fetchCopyCategories = async () =>{
        const request = await instanceNode.get('copytool/templates/copyCategories') 
        if(request.data.data){
            setCopyCategories(request.data.data)
        }
        return request.data.data
    }


    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    useEffect(()=>{
        fetchCategories();
        fetchCopyCategories();
    }, [])

    const gridDataSource = {
        store: createStore({
            key: "idCopyTemplate",
            loadUrl: `${Config.url.apiNode}copytool/templates`,
            // deleteUrl: `${Config.url.apiNode}copytool/questions`,
            // insertUrl: `${Config.url.apiNode}copytool/questions`,
            // updateUrl:`${Config.url.apiNode}copytool/questions`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
                if (ajaxOptions.method === "PUT") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };      
                if (ajaxOptions.method === "DELETE") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };      
                if (ajaxOptions.method === "POST") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };      
            },
        }),
    }

    const onRowUpdated = (e: any) => {
        if (e.data.result) {
            notify("Actualizado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }
    const onRowInserted = (e: any) => {
        if (e.data.result) {
            notify("Pregunta creada con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }

    const textos = {
        confirmDeleteTitle:"Eliminar",
        confirmDeleteMessage:"¿Quiere elminar la pregunta?",
        addRow: "Agregar Question a CopyTool"
    }

    return (
        <>
            {
                authorized && authorized ? <div className="main-Affiliates-management-container">

                    <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Templates Copytool</h1>
                                </div>
                            </div>
                        </div>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idCopyTemplate"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        onRowUpdated={onRowUpdated}
                        onRowInserted={onRowInserted}
                        showRowLines={true}
                        height="85vh"
                        width="90vw"
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="15" />
                        <Pager showInfo={true} />

                        <SearchPanel visible={"true"} placeholder={"Búsqueda"} searchVisibleColumnsOnly={"true"} highlightSearchText={"true"} width={"15vw"}/>

                        {/* <Editing 
                            mode="popup" 
                            allowUpdating={true} 
                            allowAdding={true} 
                            allowDeleting={true} 
                            texts={textos}  >
                            <Texts saveRowChanges="Guardar"/>
                            <Popup
                                title="Editar Pregunta"
                                showTitle="true"
                                width={"50%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                            />
                            <Form colCount={1}>
                                <Item dataField="categoryQuestion" isRequired={true}>
                                    <RequiredRule message="La categoria es necesaria" />
                                </Item>
                                <Item dataField="question" isRequired={true} >
                                    <RequiredRule message="La pregunta es necesaria" />
                                </Item>
                                <Item dataField="placeHolder" isRequired={true} >
                                    <RequiredRule message="El Placeholder es necesario" />
                                </Item>
                                <Item dataField="example" isRequired={false} />
                                <Item dataField="dataType" isRequired={true} >
                                    <RequiredRule message="El tipo de dato es necesario" />
                                </Item>
                                <Item dataField="order" isRequired={true} >
                                    <RequiredRule message="El estado es necesario" />
                                </Item>
                                <Item dataField="idRelatedQuestion" isRequired={false} />
                                <Item dataField="copyCategory" isRequired={true} >
                                    <RequiredRule message="La Categoría copy es necesaria" />
                                </Item>
                            </Form>
                        </Editing> */}

                        <Column type="buttons" caption="Acciones" width={"auto"}>
                            <Button text="Editar" icon="edit" name="edit"/>
                            <Button text="Eliminar" icon="trash" name="delete" />
                        </Column> 
                        <Column dataField="idCopyTemplate" caption="Código" dataType="string" alignment="center"/>
                        <Column dataField="copyTemplate" caption="Template" dataType="string" alignment="center" width={600}/>
                        <Column dataField="categoryTemplate" caption="Categoría" dataType="string" alignment="center" defaultFilterValue={"\"Esto es lo que sé sobre ti\" Háblale directamente a tu Avatar..."}>
                        <Lookup 
                                dataSource={categories}
                                allowClearing={true}
                            />
                        </Column>                        
                        <Column dataField="idCopyCategory" caption="Codigo de CopyCategory" dataType="string" alignment="center" visible={false}/>
                        <Column dataField="copyCategory" caption="Categoría Copy" dataType="string" alignment="center" visible={false}>
                            <Lookup 
                                dataSource={copyCategories}
                                allowClearing={true}
                            />
                        </Column>
                    </DataGrid> 

                
                </div> : <></>
            }
        </>
    )
}

export default Templates