import React, { useContext, useEffect, useRef, useState } from "react";
import MenuSidebarItem from "./menu-sidebar-item";
import { AuthContext } from '../auth/components/context/auth-context';
import { SideBarData } from "./sidebar.data";

import "./sidebar.styles.scss";

interface Props {
    handlecollapse: Function;
    collapse: boolean;
}

const Sidebar = ({ handlecollapse, collapse }: Props) => {

    let modules: any[] = [];

    const authContext = useContext(AuthContext);
    const response = authContext.loginInfo.moduleRoles;
 
    const [modulos, setModulos] = useState<any>([]);

    const roleValidation = () => {
        response.map((module: any) => {
            modulos.push(module.module.menu);
        });
        modules = modulos.filter((item: any, index: any) => {
            return modulos.indexOf(item) === index;
        })
        setModulos(modules);
    }
    
    const openSidebar = (collapse: boolean) => {
        handlecollapse(collapse);
    }
    
    useEffect(() => {
        roleValidation();
    }, []);


    return (
        <div className="sidebar-container" style={{
            width: !collapse ? "300px" : window.innerWidth < 576 ? "0px" : "64px",
            height: collapse ? "50px" : "100%",
            zIndex: 106,
            marginLeft: collapse && window.innerWidth < 576 ? "-20px" : "0",
            background: collapse ? "transparent" : "#161616"
        }}>
            <div className="sidebar-content" style={{ width: !collapse ? "300px" : "64px" }}>
                <div className="is-header-section" onClick={() => openSidebar(!collapse)}>
                    <div className={'is-sidebar-brand-icon brown-icon'} style={{ width: !collapse ? "" : "0px" }}></div>
                    <div className='is-sidebar-arrow-icon' style={{
                        transform: !collapse ? "rotate(-180deg)" : "rotate(0deg)",
                        display: "flex",
                        marginLeft: collapse ? "0px" : ""
                    }}></div>
                </div>
                <MenuSidebarItem key={"inicio"} collapse={collapse} icon={SideBarData[0].icon} title={SideBarData[0].title} />
                {
                    SideBarData.map((item, key) => {
                        for (let i = 0; i < modulos.length; i++) {
                            if (item.title === modulos[i]) {
                                return (
                                    <>
                                        <MenuSidebarItem key={key} collapse={collapse} icon={item.icon} title={item.title} />
                                    </>
                                )
                            }
                        }
                    })
                }
            </div>
        </div >
    )
}

export default Sidebar;