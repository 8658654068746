export const modules = {
    affiliationRequest: {
        id: 2
    },
    certificationRequest: {
        id: 3
    },
    leadsToCall: {
        id: 4
    },
    callManagement: {
        id: 5
    },
    dashboard: {
        id: 6
    },
    salesAffiliates: {
        id: 7
    },
    contentRequest: {
        id: 8
    },
    viralizationRequest: {
        id: 9
    },
    userManagement: {
        id: 10
    },
    userRequest: {
        id: 11
    },
    certifications: {
        id: 12
    },
    affiliatesManagement: {
        id: 13
    },
    affiliatesRewards: {
        id: 14
    },
    leadManagement: {
        id: 15
    },
    refundsToCall: {
        id: 16
    },
    adManagementBm: {
        id: 17
    },
    powerclassManagementBm: {
        id: 18
    },
    userManagementBm: {
        id: 19
    },
    delayedToCall: {
        id: 20
    },
    escalation_mastertools: {
        id: 21
    },
    escalation_comizzion: {
        id: 22
    },
    certificate_dns: {
        id: 23
    },
    escalation_bemaster: {
        id: 24
    },
    commissionRecovery: {
        id: 25
    },
    notification_bm: {
        id: 26
    },
    classManagement_bm: {
        id: 27
    },
    userManagement_comizzion: {
        id: 28
    },
    userManagement_mastertools: {
        id: 29
    },
    alternative_payment: {
        id: 30
    },
    moduleRolManagement:{
        id: 31
    },
    productComizzion:{
        id: 32
    },
    confirmedRefund: {
        id: 33
    },
    cancelationMastertools: {
        id: 34
    },
    transactionsHotmart: {
        id: 35
    },
    transactionsPaytool: {
        id: 36
    },
    transactionsDetailPaytool: {
        id: 37
    },
    scheduledPayments: {
        id: 38
    },
    scheduledPayments: {
        id: 38
    },
    accountsEmailTool: {
        id: 39
    },
    subscriptions: {
        id: 40
    },
    userInfrormationMastertools: {
        id: 41
    } ,
    questionCopyTool: {
        id: 42
    } ,
    triggerCopyTool: {
        id: 43
    } ,
    templateCopyTool: {
        id: 44
    } ,
    highTickets: {
        id: 45
    },
    updateSubscription: {
        id: 46
    }
}