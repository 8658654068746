import { AuthContext } from '../components/auth/components/context/auth-context';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import Login from '../components/auth/components/login/login.component';
import MainLayout from '../components/layout/main-layout.component';
import React, { useContext } from 'react';

type props = {
  path: string;
  Comp: React.ComponentType<RouteComponentProps>,
  hasLayout: boolean,
  NavbarComponent: any,
  backgroundClass: string,
  navbarColor?: boolean,
  comVisible?: boolean,
  breadcrumb?: string,
  minProfile?: number,
}

const AppAuth = ({ path, Comp, hasLayout, NavbarComponent, backgroundClass, navbarColor = false, comVisible, breadcrumb = "", minProfile = 1 }: props) => {

  const authContext = useContext(AuthContext)
  //Here we should call our authorization method, instead of that "true" value
  // const isLoggedIn = localStorage.getItem('token') != null ? true : false;

  const isLoggedIn = authContext.loginInfo.authenticated;
  const idProfile = authContext.loginInfo.idProfile <= 0 ? 1 : authContext.loginInfo.idProfile;

  if (isLoggedIn || path === "/" || path === "/login" || path === "/begin" || path === "/affiliationRequest") {
    if (idProfile >= minProfile) {
      if (hasLayout) {
        // { console.log(idProfile, '|', minProfile, '|', hasLayout) }
        return (
          <Route exact render={props => (<MainLayout children={<Comp {...props} />} path={path}
            navbarComponent={<NavbarComponent path={path} backgroundColor={navbarColor} comVisible={comVisible} breadcrumb={breadcrumb} />}
            backgroundClass={backgroundClass}
            contentMargin={path === '/classroom' || path === '/dashboard' ? false : true} />)} />
        )

      } else {
        return (
          <Route exact path={path} component={Comp} />
        )
      }
    }
    else {
      return (
        <Redirect to="/dashboard" />
      )
    }
  }
  else {
    return (
      <Redirect to="/" />
    )
  }
  
};

export default AppAuth;