import React from 'react'
import './funnelsComponent.styles.scss'

const ShorterComponent = ({ id, username }: any) =>{
    return(
        <div className='funnels_body'>
            <p className="funnels_title">SHORTER</p>
            <p>ID: {id}</p>
            <p>Username: {username}</p>
         </div>
        
    )
}

export default ShorterComponent