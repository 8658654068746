import React, { useContext, useEffect, useRef, useState } from 'react'
import "devextreme-react/text-area";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './userInformationMT.styles.scss'
import { BiSearchAlt2 } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { getUser, getUserTools, setLoading } from '../../features/Mastertools/mastertoolsSlice';
import UserInformationComponent from './UserInformation.component';
import FunnelsComponent from './Funnels.component';
import ShorterComponent from './Shorter.component';
import EmailComponent from './Email.component';



const UserInformationMT = () => {

    const searchEmailRef = useRef<HTMLInputElement>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.userManagement_mastertools.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    const history = useHistory()

    const goUserManagmentMT = (e: any)=>{
        e.preventDefault();
        history.push('/userManagement_mastertools')
    }

    const user = useAppSelector((state) => state.userMasterTools.userInfo)
    const tools = useAppSelector((state) => state.userMasterTools.userTools)
    const loading = useAppSelector((state) => state.userMasterTools.loading)
    const dispatch = useAppDispatch()

    // console.log("Usuario", user)
    // console.log("Tools", tools)

    const [searchEmail, setSearchEmail] = useState<string | null>("");
  
    const changeEmailSearch = (e: any) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            setSearchEmail("")
        }
    }

    const searchEmailClick = (e:any) => {
        e.preventDefault()
        const valor = searchEmailRef.current?.value;
        // console.log(valor)
        if (valor === "" || valor === undefined) {
            return
        } else {
            setSearchEmail(valor);
            dispatch(setLoading())
            dispatch(getUser(valor))
            dispatch(getUserTools(valor))
        }
    }

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
            dispatch(setLoading())
            // dispatch(getUser("careers@bemaster.com"))
            // dispatch(getUserTools("careers@bemaster.com"))
        } 
    }, [dispatch]);


    return (
        <>
            {
                authorized && authorized ? <div className="user-panel">

                    <div className="tittle">
                        <h1>Panel de Usuario MasterTools</h1>
                    </div>

                 
                   
                            
                    <div className="nav">
                        <Button className = "buttonVolver" variant="outline-warning" size="sm" onClick={goUserManagmentMT}>Ir a Gestion de Usuarios MT</Button>
                        <form className='search' onSubmit={searchEmailClick}>
                            <label className="searchLabel">Búsqueda por Email:</label>
                            <input type="text" name="search" placeholder="Email" autoComplete="off" ref={searchEmailRef} onChange={changeEmailSearch} />
                            <div className="searchButton" onClick={searchEmailClick} > <BiSearchAlt2/> </div>
                        </form>
                    </div>
                    {loading ? <div className='loading'>Espere un instante..</div> :
                    user.length>0 ? 
                    <div className='body'>
                        <div className='userInfo'>
                            <UserInformationComponent className="userInfo" url_imgage={user[0].url_image} name={user[0].completeName} email= {user[0].email} cellPhone={user[0].cellphone} idUser={user[0].idUser} />
                        </div>
                        <div className='userTools'>
                            {tools.length>0 && tools.filter((element:any)=> element.tool_name ==="Funnels").length>0 && 
                            <FunnelsComponent projectId={tools.filter((element:any)=> element.tool_name==="Funnels")[0].dataConfig.projectId} subdomain={tools.filter((element:any)=> element.tool_name==="Funnels")[0].dataConfig.subdomain} websiteId= {tools.filter((element:any)=> element.tool_name==="Funnels")[0].dataConfig.websiteId}/>                        }
                            {tools.length>0 && tools.filter((element:any)=> element.tool_name ==="Shorter").length>0 && 
                            <ShorterComponent id={tools.filter((element:any)=> element.tool_name==="Shorter")[0].dataConfig.id} username = {tools.filter((element:any)=> element.tool_name==="Shorter")[0].dataConfig.username}/>}
                            {tools.length>0 && tools.filter((element:any)=> element.tool_name ==="Active").length>0 && 
                            <EmailComponent account={tools.filter((element:any)=> element.tool_name==="Active")[0].dataConfig.account} password={tools.filter((element:any)=> element.tool_name==="Active")[0].dataConfig.password} userName= {tools.filter((element:any)=> element.tool_name==="Active")[0].dataConfig.username}/>                            }
                        </div>
                    </div> : 
                  
                    <div className='noData'>No data</div>}


                </div> : <></>
            }
        </>
    )
}

export default UserInformationMT