export const Config = {
    url: {
        api: process.env.REACT_APP_URL_API,
        apiNode: process.env.REACT_APP_URL_API_NODE,
        images: {
            banner: 'https://cdn.bemaster.com/media/images/home/banners/banner',
            parrilla: 'https://cdn.bemaster.com/media/images/parrilla/',
            training: 'https://cdn.bemaster.com/media/images/training-affiliates/t'
        },
        icons: {
            home: 'https://cdn.bemaster.com/media/icons/home/'
        },
        videos: {
            aff_home: 'https://cdn.bemaster.com/media/videos/aff-home/'
        },
        externalLinks: {
            telegram: 'https://t.me/embajadoresbemaster'
        },
        customColors: {
            red: '#bd1b29',
            redHover: '#6d161d',
            black: '#161616',
            brown: '#c19656',
            grayLighter: '#f2f2f2',
            affWhite: '#ffffff'
        }
    }
}