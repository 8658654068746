import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Lookup, Paging, Pager, Button, Editing, Texts, SearchPanel, Form, RequiredRule, Popup
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import './triggers.styles.scss'
import { Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { instanceNode } from '../../axios/axiosConfig';



const Triggers = () => {

    const gridRef = useRef<DataGrid>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.triggerCopyTool.id

    const [authorized, setAuthorized] = useState<boolean>(false);


    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    const gridDataSource = {
        store: createStore({
            key: "idTrigger",
            loadUrl: `${Config.url.apiNode}copytool/triggers`,
            deleteUrl: `${Config.url.apiNode}copytool/triggers`,
            insertUrl: `${Config.url.apiNode}copytool/triggers`,
            updateUrl:`${Config.url.apiNode}copytool/triggers`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
                if (ajaxOptions.method === "PUT") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };      
                if (ajaxOptions.method === "DELETE") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };      
                if (ajaxOptions.method === "POST") {
                    ajaxOptions.headers["Content-Type"] = "application/json"
                    ajaxOptions.data = JSON.stringify(ajaxOptions.data)
                };      
            },
        }),
    }

    const onRowUpdated = (e: any) => {
        if (e.data.result) {
            notify("Actualizado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }
    const onRowInserted = (e: any) => {
        if (e.data.result) {
            notify("Trigger creado con éxito", 'success');
        } else {
            notify("Error", 'error');
        }
    }

    const textos = {
        confirmDeleteTitle:"Eliminar",
        confirmDeleteMessage:"¿Quiere elminar el Trigger?",
        addRow: "Agregar Trigger a CopyTool"
    }

    return (
        <>
            {
                authorized && authorized ? <div className="main-Affiliates-management-container">

                    <div className="banner">
                            <div className="banner-img">
                                <div className="banner-overlay">
                                    <h1>Triggers Copytool</h1>
                                </div>
                            </div>
                        </div>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idTrigger"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        onRowUpdated={onRowUpdated}
                        onRowInserted={onRowInserted}
                        showRowLines={true}
                        height="85vh"
                        width="50vw"
                    >
                        <FilterRow visible={false} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="10000" />
                        <Pager showInfo={true} />

                        <SearchPanel visible={"true"} placeholder={"Búsqueda"} searchVisibleColumnsOnly={"true"} highlightSearchText={"true"} width={"15vw"}/>

                        <Editing 
                            mode="popup" 
                            allowUpdating={true} 
                            allowAdding={true} 
                            allowDeleting={true} 
                            texts={textos}  >
                            <Texts saveRowChanges="Guardar"/>
                            <Popup
                                title="Crear/Editar Trigger"
                                showTitle="true"
                                width={"40%"}
                                height={"auto"}
                                maxHeight={"95vh"}
                            />
                            <Form colCount={1}>
                                <Item dataField="trigger" isRequired={true} >
                                    <RequiredRule message="El trigger es necesario" />
                                </Item>
                            </Form>
                        </Editing>

                        <Column type="buttons" caption="Acciones" width={"auto"}>
                            <Button text="Editar" icon="edit" name="edit"/>
                            <Button text="Eliminar" icon="trash" name="delete" />
                        </Column> 
                        <Column dataField="idTrigger" width={150} caption="Código" dataType="string" alignment="center"/>
                        <Column dataField="trigger" caption="Trigger" dataType="string" alignment="center"/>
                    </DataGrid> 
                </div> : <></>
            }
        </>
    )
}

export default Triggers