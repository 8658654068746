import React from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup, RequiredRule, Texts } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Config } from '../../Config';
import { productAffDataSource } from '../../shared/dataSources/data-source.data';

const statusVerified = [
    { "flag": 0, "status": "No verficado" },
    { "flag": 1, "status": "Verficado" }
]

const UserAffiliation_comizzion = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idUser",
            loadUrl: `${Config.url.api}/comizzion/getAffiliations/${props.props.data.data.idUser}`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idLead: props.props.data.data.idLead };
            },
        }),
    };

    return (
        <>
            <h1 className="is-size-6 m-1 center">Afiliaciones del usuario</h1>
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idUser"
                allowColumnReordering={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                showRowLines={true}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize="20" />
                <Pager showInfo={true} />


                <Column dataField="idProduct" caption="Producto" dataType="number" >
                    <Lookup
                        dataSource={productAffDataSource}
                        valueExpr="idProduct"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="affiliateCode" caption="Código de afiliado" dataType="string" />
                <Column dataField="status" caption="Estado de afiliación" dataType="string" />
                <Column dataField="platform" caption="Plataforma" dataType="string" />
                <Column dataField="trackingCode" caption="Tracking Code" dataType="string" />
                <Column dataField="domain" caption="Dominio" dataType="string" />
                <Column dataField="domainVerified" caption="Verificación del dominio" dataType="number" >
                    <Lookup
                        dataSource={statusVerified}
                        valueExpr="flag"
                        displayExpr="status"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="certVerified" caption="Verificación del certificado SSL" dataType="number" >
                    <Lookup
                        dataSource={statusVerified}
                        valueExpr="flag"
                        displayExpr="status"
                        allowClearing={true}
                    />
                </Column>


            </DataGrid>
        </>
    )
}

export default UserAffiliation_comizzion;