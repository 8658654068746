import AxiosConfig from "../../axios/axiosConfig";

//Call API Data
export const sourceData = async (dateIni, dateEnd, setDataSource, userFilter, productFilter) => {
    
    const result = await AxiosConfig.post("/Dashboard/GetStatusAction", {
        dateIni: dateIni,
        dateEnd: dateEnd,
        user: userFilter,
        product: productFilter
    });

    let obj = {};
    let response = [];

    if (result.data.result === "success") {

        result.data.data.forEach((action) => {

            if (!obj.hasOwnProperty(action.idStatus)) {

                obj[`${action.idStatus}`] = { Type: action.nameType, Count: 0 }
            }

            obj[`${action.idStatus}`].Count++
        });

        Object.keys(obj).map((key) => {
            response.push(obj[key]);
            return true;
        })
    }

    response.sort((a, b) => {
        return a.Count - b.Count
    })

    setDataSource(response);
}