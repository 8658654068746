import React, { useEffect } from 'react'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Button, Column, Editing, FilterRow, Form, HeaderFilter, Lookup, MasterDetail, Pager, Paging, Popup, RequiredRule } from 'devextreme-react/data-grid';
import AxiosConfig from '../../axios/axiosConfig';
import { careersDataSource } from '../ads-management/ads-management.data';
import { classMissionDataSource } from './user-management-bm.data';
import UserCareerProgress from './user-career-progress.component';
import { Item } from 'devextreme-react/form';
import { formatPercent } from '../dashboard/format.data';
import { Config } from '../../Config';

const UserCareerTab = (props: any) => {

    const gridDataSource = {
        store: createStore({
            key: "idUserCareer",
            loadUrl: `${Config.url.api}/userBm/GetUserCareerBm/${props.data.idUser}`,
            updateUrl: `${Config.url.api}/userBm/UpdateUserCareerBm`,
            insertUrl: `${Config.url.api}/userBm/InsertUserCareerBm`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: false };
                ajaxOptions.headers = { Authorization: localStorage.getItem("token"), idUser: props.data.idUser };
            },
        }),
    };

    const renderCertificado = (data: any) => {
        if (data.value) {
            return <a href={`https://cdn.bemaster.com/${data.value}`} target="_blank" rel="noopener noreferrer" > Link Certificado </a>;
        } else {
            return <>sin certificado</>
        }
    }

    const renderPercent = (data: any) => {
        return <> {formatPercent.format(data.value)} </>
    }

    return (
        <>
            <DataGrid
                id="dataGrid-user-career-tab"
                dataSource={gridDataSource}
                showBorders={true}
                keyExpr="idUserCareer"
                allowColumnReordering={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                focusedRowEnabled={true}
                showRowLines={true}
                width="100%"
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging enabled={true} pageSize={10} />
                <Pager showInfo={true} />

                <Editing mode="popup" allowUpdating={props.isAdmin} allowAdding={props.isAdmin}>
                    <Popup title="userCareer Info" showTitle={true} width="60%" />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="idCareer" isRequired="true" >
                                <RequiredRule message="La carrera es requerida" />
                            </Item>
                            <Item dataField="isEnable" isRequired="true" >
                                <RequiredRule message="El estado de la carrera es requerido" />
                            </Item>
                            <Item dataField="canToHaveMtoolsTrial" />
                            <Item dataField="idCurrentClassMission" />
                            <Item dataField="startDate" />
                            <Item dataField="quizStreak" />
                            <Item dataField="classStreak" />
                            <Item dataField="lastClass" />
                            <Item dataField="statusCert" />
                            <Item dataField="certExp" />
                            <Item dataField="urlCert" />
                        </Item>
                    </Form>
                </Editing>

                <MasterDetail
                    enabled={true}
                    component={UserCareerProgress}
                />

                <Column type="buttons" width={"auto"}>
                    <Button name="edit" text="Editar" visible={true} />
                </Column>
                <Column dataField="idUserCareer" caption="idUserCareer" dataType="string" visible={false} />
                <Column dataField="idCareer" caption="Carrera" dataType="string" >
                    <Lookup
                        dataSource={careersDataSource}
                        valueExpr="idCareer"
                        displayExpr="name"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="idCurrentClassMission" caption="Clase actual" dataType="string" >
                    <Lookup
                        dataSource={classMissionDataSource}
                        valueExpr="idClassMission"
                        displayExpr="title"
                        allowClearing={true}
                    />
                </Column>
                <Column dataField="startDate" caption="Fecha de Inicio" dataType="datetime" format="yyyy-MM-dd" />
                <Column dataField="quizStreak" caption="Racha de Quizzs" dataType="number" />
                {/* <Column dataField="percentCompleted" caption="Porcentaje Completado" dataType="number" cellRender={renderPercent} /> */}
                <Column dataField="classStreak" caption="Racha de Clases" dataType="number" />
                <Column dataField="lastClass" caption="Fecha de Última Clase" dataType="datetime" format="yyyy-MM-dd" />
                <Column dataField="statusCert" caption="Estado de Certificado" dataType="string" />
                <Column dataField="certExp" caption="Fecha de Certificado" dataType="datetime" format="yyyy-MM-dd" />
                <Column dataField="urlCert" caption="Certificado" dataType="string" cellRender={renderCertificado} />
                <Column dataField="isEnable" caption="Estado" visible={false} >
                    <Lookup dataSource={[{ "value": 1, "name": "Activo" }, { "value": 0, "name": "Inactivo" }]} valueExpr="value" displayExpr="name" />
                </Column>
                <Column dataField="canToHaveMtoolsTrial" caption="Puede tener MasterTools Trial" visible={false} >
                    <Lookup dataSource={[{ "value": 1, "name": "Si" }, { "value": 0, "name": "No" }]} valueExpr="value" displayExpr="name" />
                </Column>
            </DataGrid>
        </>
    )
}

export default UserCareerTab;