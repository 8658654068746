import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { Config } from '../../Config';
import AxiosConfig from '../../axios/axiosConfig';

export const osadoDataSource = new DataSource({
    store: createStore({
        key: "idLeadAction",
        loadUrl: `${Config.url.api}/Affiliates/GetOsadoAffiliates`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    }),
    reshapeOnPush: true
});

export const guardianDataSource = new DataSource({
    store: createStore({
        key: "idLeadAction",
        loadUrl: `${Config.url.api}/Affiliates/GetGuardianAffiliates`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    }),
    reshapeOnPush: true
});

export const heroicoDataSource = new DataSource({
    store: createStore({
        key: "idLeadAction",
        loadUrl: `${Config.url.api}/Affiliates/GetHeroicoAffiliates`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    }),
    reshapeOnPush: true
});

export const legendarioDataSource = new DataSource({
    store: createStore({
        key: "idLeadAction",
        loadUrl: `${Config.url.api}/Affiliates/GetLegendarioAffiliates`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { 'Authorization': localStorage.getItem('token') };
        }
    }),
    reshapeOnPush: true
});

export const deliveryReward = async (leadEmail, idReward, date) => {
    const result = await AxiosConfig.post('Affiliates/DeliveryReward',{
        leadEmail: leadEmail,
        idReward: idReward,
        date: date
    });

    return result;
}