import React, { useContext, useEffect, useRef, useState } from 'react'
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    Column, DataGrid, FilterRow, HeaderFilter, Lookup, Paging, Pager
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Config } from "../../Config";
import { modules } from '../../shared/modules';
import { AuthContext } from '../auth/components/context/auth-context';
import { productDataSource, usersDataSource } from '../../shared/dataSources/data-source.data';


const gridDataSource = {
    store: createStore({
        key: "idLeadAction",
        loadUrl: `${Config.url.api}/transactions/GetTransactions`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: localStorage.getItem("token") };
        },
    }),
}

const TransactionsHotmart = () => {

    const gridRef = useRef<DataGrid>(null);

    const authContext = useContext(AuthContext);
    const userModules = authContext.loginInfo.moduleRoles;
    const currentModule = modules.transactionsHotmart.id;

    const [authorized, setAuthorized] = useState<boolean>(false);

    useEffect(() => {
        const response = userModules.filter((res: any) => {
            return res.idModule === currentModule;
        });
        if (response[0]) {
            setAuthorized(true);
        }
    }, []);

    return (
        <>
            {
                authorized && authorized ? <div className="escalation-mastertools-main-container">

                    <h1 className="is-size-5 m-5 center">Transacciones ingresadas desde Hotmart</h1>

                    <DataGrid
                        ref={gridRef}
                        dataSource={gridDataSource}
                        showBorders={true}
                        keyExpr="idLeadAction"
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        showRowLines={true}
                        height="91.5vh"
                        width="95vw"
                    >
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={true} pageSize="20" />
                        <Pager showInfo={true} />

                        <Column dataField="idLeadAction" caption="idLeadAction" dataType="number" sortOrder={"asc"} visible={false} />
                        <Column dataField="name" caption="Nombres" width={160} dataType="string" />
                        <Column dataField="lastName" caption="Apellidos" dataType="string" />
                        <Column dataField="leadEmail" caption="Correo Electrónico" dataType="string" />
                        <Column dataField="date" caption="Fecha de ingreso" dataType="datetime" format="yy-MM-dd hh:mm:ss" />
                        <Column dataField="type" caption="Tipo de transacción" dataType="string" />
                        <Column dataField="idProduct" caption="Producto" dataType="number" >
                            <Lookup
                                dataSource={productDataSource}
                                valueExpr="idProduct"
                                displayExpr="name"
                                allowClearing={true}
                            />
                        </Column>
                        <Column dataField="transaction" caption="Código de transacción" dataType="string" />
                        <Column dataField="affiliateCode" caption="Código de afiliado" dataType="string" />
                        <Column dataField="lastEdit" caption="Última modificación" dataType="datetime" format="yy-MM-dd hh:mm:ss" />
                        <Column dataField="lastUserEdit" caption="Usuario que modifica" dataType="number" >
                            <Lookup
                                dataSource={usersDataSource}
                                valueExpr="idUser"
                                displayExpr={(e: any) => {return `${e.firstName} ${e.lastName}`}}
                            />
                        </Column>
                        <Column dataField="comments" caption="Comentarios" width={700} dataType="string" />


                    </DataGrid>

                </div> : <></>
            }
        </>
    )
}

export default TransactionsHotmart;